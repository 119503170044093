import React from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ButtonInvisible, ButtonSecondary } from "components/Buttons/Buttons";

// Hooks
import { useAuthModalLogout } from "./hooks/useAuthModalLogout";
import { Text } from "components/Text/Text";

// Localization
import { useTranslation } from "localization/localization";

const AuthModalLogout = ({
    setShouldShowLogOutModal,
    shouldShowLogOutModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { closeLogOutModal, handleLogout, isLoading } = useAuthModalLogout({
        setShouldShowLogOutModal,
    });

    /**
     * End Hooks
     **/

    return (
        <Modal
            aria-labelledby="contained-modal-title"
            show={shouldShowLogOutModal}
            onHide={closeLogOutModal}
        >
            <div className="logout-modal-body-container modal__body">
                <Text isBold type="h3">
                    {t("logout", { ns: "common" })}
                </Text>
                <Text>{t("navbar.logout_subtitle", { ns: "components" })}</Text>
            </div>
            <div className="logout-modal-button-container button-container">
                <ButtonSecondary
                    cypressTestId="logout-modal-confirm-button"
                    isLoading={isLoading}
                    onClick={handleLogout}
                >
                    <Text>
                        {t("navbar.logout_confirm", { ns: "components" })}
                    </Text>
                </ButtonSecondary>
                <ButtonInvisible onClick={closeLogOutModal}>
                    <Text>{t("cancel", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        </Modal>
    );
};

export const AuthModalLogoutWithRouter = withRouter(AuthModalLogout);
