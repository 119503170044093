// Platforms
import { Branch } from "./branch";
import { GTM } from "./gtm";
import { firebase } from "./firebase";
import { Iterable } from "./iterable/iterable";

// Utils
import { emptyFunction } from "utils/objectUtils";

export class Analytics {
    static initOnAuth(
        { onIterableIdentifySuccess, user } = {
            onIterableIdentifySuccess: emptyFunction,
            user: null,
        }
    ) {
        Branch.identify();
        Iterable.init({ onIterableIdentifySuccess });
        firebase.setAnalyticsUser(user);
    }

    static initOnBoot() {
        Branch.init();
    }

    static logout() {
        Branch.logout();
        Iterable.logout();
    }

    static trackEvent(platform, params) {
        if (platform === "gtm") {
            GTM.trackEvent(params);
        } else if (platform === "firebase") {
            if (params.data) {
                firebase.trackEvent(params.event, params.data);
            } else {
                firebase.trackEvent(params.event);
            }
        } else if (platform === "iterable") {
            const { data, event } = params;
            Iterable.trackEvent(event, data);
        }
    }

    static trackEventEcommerce(platform, event, params) {
        if (platform === "firebase") {
            firebase.trackEventEcommerce(event, params);
        }
    }

    static trackPurchase(platform, params) {
        if (platform === "iterable") {
            Iterable.trackPurchaseEvent(params);
        }
    }
}
