import { useContext, useEffect } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useSignUpPhone = ({
    handleLoadingToggle,
    setCurrentSection,
    setUserInfo,
    userInfo,
}) => {
    const { phoneNumber, verificationType } = userInfo;
    const { isValid } = phoneNumber;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useEffect
     */

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            verificationType: "sms",
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    async function generateCode() {
        try {
            const payload = { phoneNumber, via: verificationType };
            const { json } =
                await SandboxxRestAPI.generatePhoneVerificationCode(payload);
            onGenerateCodeSuccess(json);
        } catch (err) {
            onGenerateCodeFailure(err);
        }
    }

    function handleSkip() {
        setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
    }

    function handleSubmit(e) {
        e && e.preventDefault();
        handleLoadingToggle("phone", true);
        if (isValid) {
            generateCode();
        } else {
            handleLoadingToggle("phone", false);
            showFailedValidationNotification();
        }
    }

    function onGenerateCodeFailure(err) {
        handleLoadingToggle("phone", false);
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onGenerateCodeSuccess(res) {
        handleLoadingToggle("phone");
        if (res.success) {
            AnalyticsLogger.logVerificationEnterPhone();
            setCurrentSection(NavigationEnum.SIGN_UP_CONFIRM_PHONE);
        } else {
            showNotification({
                text: t("sign_up_phone.generate_code_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        }
    }

    function showFailedValidationNotification() {
        showNotification({
            text: t("sign_up_phone.phone_number_invalid_error", {
                ns: "onboarding",
            }),
            type: "warning",
        });
    }

    return { handleSkip, handleSubmit };
};
