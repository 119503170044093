// Enums
import { NavigationEnum } from "../constants/constants";

export function generateSignInStartingPoint({ goToNameEntry, goToOnboarding }) {
    if (goToNameEntry) {
        return NavigationEnum.SIGN_UP_NAME;
    }
    if (goToOnboarding) {
        return NavigationEnum.SIGN_UP_PERSONA_ROOT;
    }
    return NavigationEnum.SIGN_IN_MAIN;
}
