import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

// Context
import { CartContext } from "context/cart/cart";
import { NotificationBarContext } from "context/notificationBar";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const useMemoryBookCart = () => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const {
        cart,
        getCartSession,
        isUpdatingCart,
        setIsUpdatingCart,
        shippingOptions,
        showCart,
        startCartSession,
        updateCartBookQuantity,
    } = useContext(CartContext);

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [ineligibleErrorMessage, setIneligibleErrorMessage] = useState(null);
    const [isIneligibleForMemoryBook, setIsIneligibleForMemoryBook] =
        useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        /**
         * If fetching a cart session returns a 404 error, a new cart session
         * will automatically start.
         */
        getCartSession({
            onFailure: onGetCartSessionFailure,
            onSuccess: onGetCartSessionSuccess,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleGoHome() {
        history.push("/letters");
    }

    function onGetCartSessionFailure(err) {
        const errorMessage = getErrorMessage(err);
        showNotification({ text: errorMessage, type: "warning" });
    }

    function onGetCartSessionSuccess(res, response) {
        if (response?.status === 204) {
            setIsUpdatingCart(true);
            startCartSession({ onFailure: onStartCartSessionFailure });
        }
    }

    function onStartCartSessionFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIneligibleErrorMessage(errorMessage);
        setIsIneligibleForMemoryBook(true);
    }

    /**
     * End Hooks
     */

    return {
        cart,
        handleGoHome,
        ineligibleErrorMessage,
        isIneligibleForMemoryBook,
        isUpdatingCart,
        shippingOptions,
        showCart,
        updateCartBookQuantity,
    };
};
