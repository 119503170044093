import { useContext, useEffect, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { AuthContext } from "context/auth/auth";

// Utils
import { generateSetShippingOptionPayload } from "../utils/apiUtils";
import { emptyFunction } from "utils/objectUtils";

export const useCartShippingOptions = ({
    cart,
    setCart,
    setIsUpdatingCart,
}) => {
    /**
     * Context
     */

    const { isLoggingOut } = useContext(AuthContext);

    /**
     * useState
     */

    const [shippingOptions, setShippingOptions] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        if (isLoggingOut) {
            resetCartShippingOptionsContext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggingOut]);

    useEffect(() => {
        /**
         * Fetch shipping options whenever the cart is updated
         */
        getShippingOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart?.updated_at]);

    /**
     * End Hooks
     */

    async function getShippingOptions() {
        try {
            setIsUpdatingCart(() => true);
            const { json } = await SandboxxRestAPI.getShippingOptions({ cart });
            onGetShippingOptionsSuccess(json);
        } catch (err) {
            onGetShippingOptionsFailure(err);
        }
    }

    function resetCartShippingOptionsContext() {
        setIsUpdatingCart(() => false);
        setShippingOptions(null);
    }

    async function submitShippingOption({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        shippingOption,
    } = {}) {
        try {
            setIsUpdatingCart(() => true);
            const payload = generateSetShippingOptionPayload({
                cart,
                shippingOption,
            });
            const { json } = await SandboxxRestAPI.submitShippingOption({
                cart,
                ...payload,
            });
            onSubmitShippingOptionSuccess(json, onSuccess, shippingOption);
        } catch (err) {
            onSubmitShippingOptionFailure(err, onFailure);
        }
    }

    function onGetShippingOptionsFailure(err) {
        setIsUpdatingCart(() => false);
    }

    function onGetShippingOptionsSuccess({ shipping_options }) {
        setShippingOptions(() => shipping_options);
        setIsUpdatingCart(() => false);
    }

    function onSubmitShippingOptionFailure(err, onFailure) {
        setIsUpdatingCart(() => false);
        onFailure(err);
    }

    function onSubmitShippingOptionSuccess(res, onSuccess, shippingOption) {
        setCart(res.cart);
        setIsUpdatingCart(() => false);
        onSuccess(res, shippingOption);
    }

    return {
        resetCartShippingOptionsContext,
        submitShippingOption,
        shippingOptions,
    };
};
