// Interfaces
import { SessionManager } from "interfaces/auth/SessionManager";

// Utils
import { convertArrayBufferToBase64 } from "./photoUtils";

async function deleteFetch(url, headers, body) {
    return fetch(url, {
        body,
        headers,
        method: "DELETE",
    })
        .then(async (response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
                return Promise.reject("Redirected to Sign In");
            } else {
                return response.json().then((json) => {
                    if (response.ok) {
                        return Promise.resolve({ json, response });
                    } else {
                        return Promise.reject({ json, response });
                    }
                });
            }
        })
        .catch((error) => {
            return Promise.reject({ error });
        });
}

async function get(url, headers) {
    return fetch(url, { headers })
        .then(async (response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
                return Promise.reject("Redirected to Sign In");
            } else {
                const contentLength = response.headers.get("content-length");
                const contentType = response.headers.get("content-type");

                if (
                    contentLength === "0" ||
                    !contentType?.includes("application/json")
                ) {
                    return Promise.resolve({ json: null, response });
                }

                return response.json().then((json) => {
                    if (response.ok) {
                        return Promise.resolve({ json, response });
                    } else {
                        return Promise.reject({ json, response });
                    }
                });
            }
        })
        .catch(function (error) {
            return Promise.reject({ error });
        });
}

async function getImage(url, headers, { fileType }) {
    return fetch(url, { headers })
        .then(async (response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
                return Promise.reject("Redirected to Sign In");
            } else {
                return response.arrayBuffer().then((buffer) => {
                    const base64Flag = `data:image/${fileType};base64,`;
                    const imageStr = convertArrayBufferToBase64(buffer);
                    return base64Flag + imageStr;
                });
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

async function post(url, headers, body) {
    return fetch(url, {
        body,
        headers,
        method: "POST",
    })
        .then(async (response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
                return Promise.reject("Redirected to Sign In");
            } else {
                return response.json().then((json) => {
                    if (response.ok) {
                        return Promise.resolve({ json, response });
                    } else {
                        return Promise.reject({ json, response });
                    }
                });
            }
        })
        .catch((error) => {
            return Promise.reject({ error });
        });
}

async function put(url, headers, body) {
    return fetch(url, {
        body,
        headers,
        method: "PUT",
    })
        .then(async (response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
                return Promise.reject("Redirected to Sign In");
            } else {
                return response.json().then((json) => {
                    if (response.ok) {
                        return Promise.resolve({ json, response });
                    } else {
                        return Promise.reject({ json, response });
                    }
                });
            }
        })
        .catch((error) => {
            return Promise.reject({ error });
        });
}

function handleRedirectToSignIn() {
    SessionManager.signOut();
}

function shouldRedirectToSignIn(response) {
    return response.status === 401;
}

export { deleteFetch, getImage, get, post, put };
