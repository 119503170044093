export function getErrorMessage(err) {
    // Case 1: API error response format
    if (err.error?.json?.message) {
        return err.error.json.message;
    }

    // Case 2: Direct error message
    if (err.message) {
        return err.message;
    }

    // Case 3: Error is a string
    if (typeof err === "string") {
        return err;
    }

    // Case 4: JSON response error
    if (err.json?.message) {
        return err.json.message;
    }

    // Case 4: JSON response error
    if (err.error?.message) {
        return err.error.message;
    }

    // Default case: Unknown error format
    return "An unexpected error occurred";
}
