import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "components/Modals/Modal";

// Components
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { CheckmarkSuccessIcon } from "components/Icons/CheckmarkSuccessIcon";
import { Text } from "components/Text/Text";
import { WarningCircleIcon } from "components/Icons/WarningCircleIcon";

// CSS
import styles from "./support-squad-join-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const SupportSquadJoinModal = ({
    joinSupportSquad,
    shouldShowJoinModal,
    setShouldShowJoinModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [joinResultMessage, setJoinResultMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentSection, setCurrentSection] = useState("default");
    const [verificationCode, setVerificationCode] = useState(null);

    /**
     * End Hooks
     */

    function handleChange(value) {
        const formattedValue = value.join("");
        setVerificationCode(formattedValue);
    }

    function handleSubmit() {
        joinSupportSquad(
            verificationCode,
            handleJoinSuccess,
            handleJoinFailure
        );
        setLoading(true);
    }

    function handleJoinSuccess(message) {
        AnalyticsLogger.logSquadInviteJoined();
        setJoinResultMessage(message);
        setCurrentSection("success");
        setLoading(false);
    }

    function handleJoinFailure(error) {
        const errorMessage = getErrorMessage(error);
        setJoinResultMessage(errorMessage);
        setCurrentSection("failed");
        setLoading(false);
    }

    function handleModalClose() {
        setShouldShowJoinModal(false);
        setCurrentSection("default");
    }

    function renderOptions() {
        return (
            <div className={styles["container"]}>
                <div className={styles["input-container"]}>
                    <ConfirmVerificationCodeInput
                        characterType="all"
                        label=""
                        length={8}
                        onChange={handleChange}
                        size="small"
                    />
                </div>
                <div className={styles["buttons-container"]}>
                    <ButtonPrimary
                        classes={styles["submit-code"]}
                        cypressTestId="support-squad-join-modal-join-button"
                        isDisabled={
                            verificationCode?.length !== 8 ? true : false
                        }
                        isLoading={loading}
                        onClick={handleSubmit}
                    >
                        <Text>{t("join_squad", { ns: "common" })}</Text>
                    </ButtonPrimary>
                    <ButtonInvisible
                        cypressTestId="support-squad-join-modal-cancel-button"
                        onClick={handleModalClose}
                    >
                        <Text>{t("cancel", { ns: "common" })}</Text>
                    </ButtonInvisible>
                </div>
            </div>
        );
    }

    function renderSuccess() {
        return (
            <div className={styles["success-confirmation"]}>
                <CheckmarkSuccessIcon
                    className={styles["success-icon"]}
                    height="50px"
                    width="50px"
                />
                <h3>{t("success", { ns: "common" })}</h3>
                <span>{joinResultMessage}</span>
                <ButtonPrimary
                    classes={styles["success-confirmation-continue-button"]}
                    onClick={handleModalClose}
                >
                    <Text>{t("continue", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        );
    }

    function renderFailed() {
        return (
            <div
                className={styles["error-confirmation"]}
                data-cy="support-squad-join-modal-error-confirmation"
            >
                <WarningCircleIcon
                    className={styles["warning-icon"]}
                    height="45px"
                    width="45px"
                />
                <h3>{t("error", { ns: "common" })}</h3>
                <span className={styles["message"]}>{joinResultMessage}</span>
                <ButtonPrimary
                    classes={styles["enter-new-code"]}
                    cypressTestId="support-squad-join-modal-error-confirmation-new-code-button"
                    onClick={() => setCurrentSection("default")}
                >
                    <Text>
                        {t("join.join_enter_new_code", { ns: "support_squad" })}
                    </Text>
                </ButtonPrimary>
                <ButtonInvisible
                    classes={styles["confirm-cancel-button"]}
                    cypressTestId="support-squad-join-modal-error-confirmation-cancel-button"
                    onClick={handleModalClose}
                >
                    <Text>{t("cancel", { ns: "common" })}</Text>
                </ButtonInvisible>
            </div>
        );
    }

    function renderModalContent() {
        switch (currentSection) {
            case "default":
                return renderOptions();
            case "success":
                return renderSuccess();
            case "failed":
                return renderFailed();
            default:
                break;
        }
    }

    return (
        <Modal
            cypressCloseButtonTestId="support-squad-join-modal-close-button"
            cypressTestId="support-squad-join-modal"
            headerText={t("join.join_title", { ns: "support_squad" })}
            headerSubText={
                currentSection === "default"
                    ? t("join.join_subtitle", { ns: "support_squad" })
                    : ""
            }
            onClose={handleModalClose}
            onHide={handleModalClose}
            show={shouldShowJoinModal}
        >
            {renderModalContent()}
        </Modal>
    );
};

SupportSquadJoinModal.propTypes = {
    joinSupportSquad: PropTypes.func.isRequired,
    shouldShowJoinModal: PropTypes.bool.isRequired,
    setShouldShowJoinModal: PropTypes.func.isRequired,
};
