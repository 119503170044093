import { useHistory } from "react-router-dom";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Analytics } from "platforms/analytics";
import { Branch } from "platforms/branch";
import { Intercom } from "platforms/intercom";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const useAuthSignIn = ({
    fetchAccountAfterRowndEvent,
    resetAccountState,
}) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * End Hooks
     */

    async function onLogin() {
        let accountResponse = null;
        try {
            accountResponse = await fetchAccountAfterRowndEvent();
        } catch (error) {}

        // Intercom
        Intercom.registerUser(accountResponse?.user);

        // Analytics
        Analytics.initOnAuth({ user: accountResponse?.user });

        // UI
        history.push("/");
    }

    async function onLogout() {
        // Local Storage
        CurrentUser.clearUser();

        // Analytics
        Analytics.logout();

        // Intercom
        Intercom.shutdown();

        // User
        resetAccountState();

        // Local Storage
        localStorage.removeItem("authProvider");
        localStorage.removeItem("hasCompletedSignUpName");
        localStorage.removeItem("sandboxxMessageRecipient");

        // UI
        history.push("/signin");
    }

    async function onSignUp() {
        // Fetch account
        let accountResponse = null;
        try {
            accountResponse = await fetchAccountAfterRowndEvent();
        } catch (err) {}

        // Check for muster connection
        sessionStorage.setItem(
            "hasMusterConnection",
            accountResponse?.musterConnection || false
        );

        // Send install params
        try {
            SandboxxRestAPI.sendInstallParams({
                branch: Branch.generateSignUpParams(),
            });
        } catch (err) {}

        // Claim referral code
        const referralCode = localStorage.getItem("referralCode");
        if (referralCode) {
            try {
                SandboxxRestAPI.claimReferralCode(referralCode);
                localStorage.setItem("referralCode", "");
            } catch (err) {}
        }

        // Analytics
        Analytics.initOnAuth({
            onIterableIdentifySuccess: AnalyticsLogger.logSignUpEndIterable,
            user: accountResponse?.user,
        });
        AnalyticsLogger.logSignUpEnd();
    }

    return { onLogin, onLogout, onSignUp };
};
