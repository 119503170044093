import React, { useEffect, useState } from "react";
import { Modal } from "components/Modals/Modal";

// Components
import AddIcon from "@mui/icons-material/Add";
import { ButtonPrimary } from "../Buttons/Buttons";
import RemoveIcon from "@mui/icons-material/Remove";
import { Text } from "components/Text/Text";

// CSS
import "css/components/graduation/graduation.scss";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const GraduationModal = ({
    fetchDashboard,
    graduation,
    onHide,
    show,
    showNotification,
}) => {
    const { currentWeeksUntil, gradJointId, maxNumberOfWeeks } =
        graduation && graduation[0] ? graduation[0] : {};

    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    /**
     * useState
     **/

    const [loading, setLoading] = useState(false);
    const [requestedWeeksUntil, setRequestedWeeksUntil] =
        useState(currentWeeksUntil);

    /**
     * useEffect
     **/

    useEffect(() => {
        const { currentWeeksUntil } =
            graduation && graduation[0] ? graduation[0] : {};
        setRequestedWeeksUntil(currentWeeksUntil);
    }, [graduation]);

    /**
     * End Hooks
     **/

    const isDecreaseWeeksAvailable = requestedWeeksUntil - 1 > -1;
    const isIncreaseWeeksAvailable =
        requestedWeeksUntil + 1 <= maxNumberOfWeeks;

    function handleDecreaseWeeks() {
        const newRequestedWeeksUntil = requestedWeeksUntil - 1;
        if (newRequestedWeeksUntil > -1) {
            setRequestedWeeksUntil(newRequestedWeeksUntil);
        }
    }

    function handleIncreaseWeeks() {
        const newRequestedWeeksUntil = requestedWeeksUntil + 1;
        if (newRequestedWeeksUntil <= maxNumberOfWeeks) {
            setRequestedWeeksUntil(newRequestedWeeksUntil);
        }
    }

    async function handleSetGraduationWeek() {
        setLoading(true);
        try {
            const { json } = await SandboxxRestAPI.setGraduationWeek({
                gradJointId,
                requestedWeeksUntil,
            });
            onSetGraduationWeekSuccess(json);
        } catch (err) {
            onSetGraduationWeekError(err);
        }
    }

    function onSetGraduationWeekError(err) {
        const errorMessage = getErrorMessage(err);
        setLoading(false);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onSetGraduationWeekSuccess(res) {
        fetchDashboard().then(() => {
            setLoading(false);
            onHide();
        });
    }

    function renderRequestedWeeksUntil() {
        if (requestedWeeksUntil === 0) {
            return t("this_week", { ns: "common" });
        }
        if (requestedWeeksUntil === 1) {
            return t("next_week", { ns: "common" });
        }
        return t("week", { count: requestedWeeksUntil, ns: "common" });
    }

    const buttonClass = loading ? "disabled" : "";
    const leftIconClass = isDecreaseWeeksAvailable
        ? ""
        : "graduation-modal__selector__icon--disabled";
    const rightIconClass = isIncreaseWeeksAvailable
        ? ""
        : "graduation-modal__selector__icon--disabled";

    return (
        <Modal
            className="graduation-modal"
            cypressCloseButtonTestId="graduation-modal-close"
            cypressTestId="graduation-modal"
            headerText={t("graduation.title", { ns: "dashboard" })}
            onHide={onHide}
            show={show}
        >
            <div className="graduation-modal__content">
                <div className="graduation-modal__selector">
                    <RemoveIcon
                        className={`graduation-modal__selector__icon graduation-modal__selector__icon--left ${leftIconClass}`}
                        onClick={handleDecreaseWeeks}
                    />
                    <span className="graduation-modal__selector__option">
                        {renderRequestedWeeksUntil()}
                    </span>
                    <AddIcon
                        className={`graduation-modal__selector__icon graduation-modal__selector__icon--right ${rightIconClass}`}
                        onClick={handleIncreaseWeeks}
                    />
                </div>
                <ButtonPrimary
                    classes={buttonClass}
                    isLoading={loading}
                    onClick={handleSetGraduationWeek}
                >
                    <Text>{t("save", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        </Modal>
    );
};
