import React, { createContext, useContext, useEffect, useState } from "react";

// Context
import { AuthContext } from "context/auth/auth";
import { NotificationBarContext } from "../context/notificationBar";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const CreditsContext = createContext({});

export const CreditsProvider = (props) => {
    const { children } = props;

    /**
     * useContext
     **/

    const { isLoggingOut } = useContext(AuthContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     **/

    const [credits, setCredits] = useState(null);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (isLoggingOut) {
            resetCreditsContext();
        }
    }, [isLoggingOut]);

    /**
     * End Hooks
     **/

    async function fetchCredits() {
        try {
            const { json } = await SandboxxRestAPI.getCredits();
            setCredits(parseInt(json.user_credits, 10));
        } catch (err) {
            const errorMessage = getErrorMessage(err);
            showNotification({
                text: errorMessage,
                type: "warning",
            });
        }
    }

    function resetCreditsContext() {
        setCredits(null);
    }

    const creditsContext = { credits, fetchCredits, resetCreditsContext };

    return (
        <CreditsContext.Provider value={creditsContext}>
            {children}
        </CreditsContext.Provider>
    );
};
