import { useEffect, useState } from "react";
import { usePrevious } from "@uidotdev/usehooks";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "utils/sandboxx";
import {
    filterOutInvalidatedBlocks,
    generateStackCopyValues,
    generateSubmitAddressFormPayload,
} from "../utils/utils";

// Validation
import { AddressFormatterValidation } from "../validation/AddressFormatterValidation";

export const useAddressFormatter = ({ base }) => {
    /**
     * Custom Hooks
     */

    const previousBase = usePrevious(base);

    /**
     * useState
     */

    const [address, setAddress] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [formIdStack, setFormIdStack] = useState([]);
    const [formStack, setFormStack] = useState([]);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [triggerSubmitFlag, setTriggerSubmitFlag] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        /**
         * Submits the Address Formatter form when the user has
         * selected a base for the first time.
         */
        if (base && !previousBase) {
            fetchAddressFormatterForm();
        }

        /**
         * Resets the Address Formatter if the base changes after
         * the initial base selection. If a new base is present,
         * the Address Formatter will submit the initial form.
         */
        if (base && previousBase && base !== previousBase) {
            resetAddressFormatter();
            fetchAddressFormatterForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base]);

    useEffect(() => {
        if (triggerSubmitFlag) {
            handleSubmitAddressForm();
            setTriggerSubmitFlag(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerSubmitFlag]);

    /************************************************
     * End Hooks
     ************************************************/

    async function fetchAddressFormatterForm() {
        if (base) {
            try {
                const { json } = await SandboxxRestAPI.getAddressFormatterForm(
                    base.id
                );
                onFetchAddressFormatterFormSuccess(json);
            } catch (err) {
                onAddressFormatterRequestFailure(err);
            }
        }
    }

    function handleInputChange(e, { fieldIndex, formStack, index }) {
        try {
            e.preventDefault();
            const { value } = e.target;

            // Clear invalidated blocks
            const { formIdStackFiltered, formStackFiltered } =
                filterOutInvalidatedBlocks({
                    formStack,
                    formIdStack,
                    index,
                });

            // Generate formStackCopy variables
            const {
                formIdStackCopy,
                formStackCopy,
                formStackCopyTargetBlock,
                formStackCopyTargetBlockField,
            } = generateStackCopyValues({
                formStack: formStackFiltered,
                formIdStack: formIdStackFiltered,
                fieldIndex,
            });

            // Update formStackCopy field with new value
            formStackCopyTargetBlock.fields[fieldIndex] = {
                ...formStackCopyTargetBlockField,
                value,
            };

            // Set updated stacks in state
            setFormIdStack((prev) => formIdStackCopy);
            setFormStack((prev) => formStackCopy);

            // Submit form if all required block fields are valid
            if (
                AddressFormatterValidation.areAllBlockValuesValid(
                    formStackCopyTargetBlock
                )
            ) {
                setTriggerSubmitFlag(true);
            }
        } catch (err) {}
    }

    async function handleSubmitAddressForm(e) {
        e?.preventDefault();
        setErrorMessage(null);
        setIsSubmitting(true);
        try {
            const { json } = await SandboxxRestAPI.submitAddressFormatterForm(
                generateSubmitAddressFormPayload(formIdStack, formStack)
            );
            onSubmitAddressSuccess(json);
        } catch (err) {
            onAddressFormatterRequestFailure(err);
        }
    }

    function handleMarkInputComplete() {
        const { formStackCopy } = generateStackCopyValues({ formStack });
        formStackCopy[formStackCopy.length - 1].isComplete = true;
        setFormStack((prev) => formStackCopy);
    }

    function handleUpdateFormStack(formBlock) {
        const { formIdStackCopy } = generateStackCopyValues({ formIdStack });
        setFormIdStack(formIdStackCopy.concat(formBlock.formId));
        const newFormBlock = {
            ...formBlock,
            isComplete: false,
            fields: formBlock.fields.map((field) => ({
                ...field,
                value: null,
            })),
        };
        setFormStack((prev) => [...prev, newFormBlock]);
    }

    function onAddressFormatterRequestFailure(err) {
        const errorMessage = getErrorMessage(err);
        setErrorMessage(errorMessage);
        setIsSubmitting(false);
    }

    function onFetchAddressFormatterFormSuccess(formBlock) {
        handleUpdateFormStack(formBlock);
    }

    function onSubmitAddressSuccess({ address, completed, form }) {
        setIsSubmitting(false);
        handleMarkInputComplete();
        setIsFormComplete(completed);
        if (completed) {
            setAddress(address);
        } else {
            handleUpdateFormStack(form);
        }
    }

    function resetAddressFormatter() {
        setAddress({});
        setErrorMessage(null);
        setFormIdStack([]);
        setFormStack([]);
        setIsFormComplete(false);
        setIsSubmitting(false);
        setTriggerSubmitFlag(false);
    }

    return {
        address,
        errorMessage,
        formStack,
        handleInputChange,
        handleSubmitAddressForm,
        isFormComplete,
        isSubmitting,
    };
};
