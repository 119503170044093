import { useContext, useEffect, useState } from "react";

// Context
import { CartContext } from "context/cart/cart";

// Enums
import { CartShippingOptionsNavigation } from "../enums/CartShippingOptionsNavigation";

export const useCartShippingOptions = () => {
    /**
     * useContext
     */

    const { hasShippingAddress, shippingOptions } = useContext(CartContext);

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        CartShippingOptionsNavigation.SELECT
    );

    /**
     * useEffect
     */

    useEffect(() => {
        if (!hasShippingAddress && shippingOptions) {
            setCurrentSection(() => CartShippingOptionsNavigation.DISPLAY);
        }
    }, [hasShippingAddress, shippingOptions]);

    /**
     * End Hooks
     */

    return { currentSection };
};
