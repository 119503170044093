import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Components
import { Cart } from "components/Cart/Cart";
import { WrappedContactModal as ContactModal } from "./scenes/Contacts/ContactModal/ContactModal";
import { Footer } from "./scenes/App/Footer";
import { Interstitial } from "components/Interstitial/Interstitial";
import { NavBarWithRouter as NavBar } from "./components/NavBar/NavBar";
import { PageLayout } from "components/PageLayout/PageLayout";
import { PhoneVerificationModalWithRouter as PhoneVerificationModal } from "components/PhoneVerificationModal/PhoneVerificationModal";
import { Providers } from "./components/Providers/Providers";
import { PurchaseModal } from "./components/Purchase/PurchaseModal";
import { RedirectMobile } from "./components/Redirect/RedirectMobile";
import { Routes } from "./routes/Routes";

// Utils
import {
    isMobile,
    isUsingIPad,
    isVisitingNoAuthURL,
} from "utils/mediaQueryUtils";

const shouldRedirect =
    (isMobile() && !isVisitingNoAuthURL()) ||
    (isUsingIPad() && !isVisitingNoAuthURL());

export const App = () => {
    function renderRouter() {
        return (
            <Router forceRefresh={!supportsHistory()}>
                <Providers>
                    <PageLayout>
                        <div>
                            <ContactModal />
                            <PhoneVerificationModal />
                            <PurchaseModal />
                            <Interstitial />
                            <NavBar />
                            <Cart />
                        </div>
                        <div className="app-content">
                            {Routes()}
                            <Footer />
                        </div>
                    </PageLayout>
                </Providers>
            </Router>
        );
    }

    return shouldRedirect ? <RedirectMobile /> : renderRouter();
};

function supportsHistory() {
    return "pushState" in window.history;
}
