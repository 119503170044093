import { useEffect, useState } from "react";

// Enums
import { RightColumnMode } from "../enums/RightColumnMode";

export const useSignIn = ({ runShouldShowNavBarCheck, setUserInfo }) => {
    /**
     * useState
     */

    const [rightColumnMode, setRightColumnMode] = useState(
        RightColumnMode.WELCOME
    );
    const [verificationCode, setVerificationCode] = useState("");

    /**
     * useEffect
     */

    useEffect(() => {
        runShouldShowNavBarCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleChangeVerificationCode(value) {
        const formattedValue = value.join("");
        setVerificationCode(formattedValue);
    }

    return {
        handleChangeVerificationCode,
        rightColumnMode,
        setRightColumnMode,
        verificationCode,
    };
};
