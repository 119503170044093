import { useContext, useEffect, useState } from "react";

// Context
import { AuthContext } from "context/auth/auth";
import { BasesContext } from "context/bases";
import { ContactsContext } from "context/contacts";
import { NotificationBarContext } from "context/notificationBar";
import { TitlesContext } from "context/titles";

// Enums
import { PersonModalNavigation } from "../../../../components/PersonModal/enums/PersonModalNavigation";

// Hooks
import { useNestedFormState } from "hooks/forms/useNestedFormState";

// Logging
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { formatSquadContact } from "../utils/utils";
import { isEmptyObject } from "utils/objectUtils";
import { emptyFunction } from "utils/objectUtils";
import { SandboxxRestAPI } from "utils/sandboxx";
import {
    generateFullName,
    generateShouldReverseFullName,
} from "utils/userUtils";

export const useContactModal = () => {
    /**
     * useContext
     */

    const { isAuthenticated } = useContext(AuthContext);
    const { bases, fetchBases } = useContext(BasesContext);
    const {
        contactModal,
        createContact,
        editContact,
        fetchContacts,
        onBackButtonClickCallback,
        onSubmitContactSuccessFinalCallback,
        setOnSubmitContactSuccessFinalCallback,
        toggleContactModal,
    } = useContext(ContactsContext);
    const { contactToEdit, isSupportSquad, isWritingLetter, type } =
        contactModal;
    const { showNotification } = useContext(NotificationBarContext);
    const { titles, fetchTitles } = useContext(TitlesContext);

    /**
     * useState
     */

    const {
        formState: contactStaging,
        handleChange,
        setFormState: setContactStaging,
    } = useNestedFormState({
        address: {
            line1: "",
            line2: "",
            city: "",
            state: "",
            zipcode: "",
        },
        firstName: "",
        fullName: "",
        lastName: "",
        title: "",
    });
    const [currentSection, setCurrentSection] = useState(
        PersonModalNavigation.NAME
    );
    const [exampleAddresses, setExampleAddresses] = useState(null);
    const [headerText, setHeaderText] = useState(
        type === "edit" ? "Edit Recipient" : "Who are you writing to?"
    );
    const [loading, setLoading] = useState({
        form: false,
    });
    const [shouldReverseFullName, setShouldReverseFullName] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        if (contactToEdit && isAuthenticated) {
            setContactStaging(contactToEdit);
            setShouldReverseFullName(
                generateShouldReverseFullName(contactToEdit)
            );
            fetchBases();
            fetchTitles(contactToEdit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactToEdit, isAuthenticated]);

    useEffect(() => {
        updateFullName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        contactStaging.firstName,
        contactStaging.lastName,
        contactStaging.title,
    ]);

    useEffect(() => {
        fetchExampleAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactStaging.address.zipcode]);

    /**
     * Updates the `fullName` value in `contactStaging` whenever
     * the user interacts with the toggle switch
     */
    useEffect(() => {
        updateFullName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldReverseFullName]);

    useEffect(() => {
        setCurrentSection(contactModal.targetScreen);
    }, [contactModal.targetScreen]);

    /**
     * Constants
     */

    const headerTextOptions = {
        addressFormatter: "Who are you writing to?",
        addressManual: "",
        confirm: "Please confirm your recruit's address",
        form:
            contactModal.type === "edit"
                ? "Edit Recipient"
                : "Who are you writing to?",
        name: "Who are you writing to?",
    };

    /**
     * End Hooks
     */

    async function fetchExampleAddresses() {
        try {
            const { zipcode } = contactStaging.address;
            if (zipcode.length === 5) {
                const { json } = await SandboxxRestAPI.getExampleAddresses({
                    zipcode,
                });
                // If no example addresses are provided (most likely because the zipcode
                //  is not a valid base zipcode), save empty array into state
                if (isEmptyObject(json)) {
                    setExampleAddresses([]);
                }
                // If example addresses are provided, save them in state
                if (json?.example_address?.length > 0) {
                    setExampleAddresses(json.example_address);
                }
            }
        } catch (error) {
            setExampleAddresses(null);
        }
    }

    function handleCloseModal() {
        toggleContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            shouldShow: false,
            targetScreen: null,
        });
        setOnSubmitContactSuccessFinalCallback(() => emptyFunction);
        setTimeout(() => {
            setCurrentSection(PersonModalNavigation.NAME);
        }, 250);
    }

    function handleFormGoBack(e) {
        toggleContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            shouldShow: false,
            targetScreen: null,
        });
        setOnSubmitContactSuccessFinalCallback(() => emptyFunction);
        onBackButtonClickCallback();
    }

    async function handleSubmit(e) {
        e.preventDefault();
        AnalyticsLogger.logContactSave(isWritingLetter);
        setLoading((prev) => ({
            ...prev,
            form: true,
        }));
        if (
            contactStaging.address?.line1?.length > 40 ||
            contactStaging.address?.line2?.length > 40
        ) {
            showNotification({
                text: `Your address exceeds our character count limit, please contact our team at happiness@sandboxx.us for assistance.`,
                time: 10000,
                type: "warning",
            });
            setLoading((prev) => ({
                ...prev,
                form: false,
            }));
        } else if (type === "edit") {
            if (isSupportSquad) {
                try {
                    const supportSquadContact =
                        formatSquadContact(contactStaging);
                    await SandboxxRestAPI.updateSupportSquad(
                        supportSquadContact
                    );
                    onUpdateSupportSquadSubmitSuccess(
                        supportSquadContact.squadId
                    );
                } catch (err) {
                    onSubmitError(err);
                }
            } else {
                editContact(
                    contactStaging,
                    onSubmitSuccess,
                    onSubmitError,
                    onSubmitError
                );
            }
        } else if (type === "create") {
            createContact(contactStaging, onSubmitSuccess, onSubmitError, {
                shouldReverseFullName,
            });
        }
    }

    function handleSubmitAddressFormatter(e, address) {
        e.preventDefault();
        setContactStaging((prev) => ({
            ...prev,
            address,
        }));
        setCurrentSection(PersonModalNavigation.CONFIRM);
    }

    function onSubmitError(err) {
        setLoading((prev) => ({
            ...prev,
            form: false,
        }));
    }

    function onSubmitSuccess(res) {
        setLoading((prev) => ({
            ...prev,
            form: false,
        }));
        onSubmitContactSuccessFinalCallback(res, { contactStaging });
        fetchContacts();
        toggleContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            shouldShow: false,
            targetScreen: null,
        });
        setOnSubmitContactSuccessFinalCallback(() => emptyFunction);
        setTimeout(() => {
            setCurrentSection(PersonModalNavigation.FORM);
        }, 250);
    }

    function onUpdateSupportSquadSubmitSuccess(id) {
        onSubmitContactSuccessFinalCallback(id);
        setLoading((prev) => ({
            ...prev,
            form: false,
        }));
    }

    function updateFullName() {
        const fullName = generateFullName(
            contactStaging,
            shouldReverseFullName
        );
        setContactStaging((prev) => ({ ...prev, fullName }));
    }

    function toggleShouldReverseFullName() {
        setShouldReverseFullName((prev) => !prev);
    }

    return {
        bases,
        contactModal,
        contactStaging,
        currentSection,
        exampleAddresses,
        handleChange,
        handleCloseModal,
        handleFormGoBack,
        handleSubmit,
        handleSubmitAddressFormatter,
        headerText,
        headerTextOptions,
        loading,
        onBackButtonClickCallback,
        setCurrentSection,
        setHeaderText,
        shouldReverseFullName,
        titles,
        toggleContactModal,
        toggleShouldReverseFullName,
    };
};
