import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { AuthContext } from "context/auth/auth";

// Utils
import { generateSignInStartingPoint } from "../utils/navigationUtils";
import { generateRedirectData } from "utils/routeUtils";

export const useSignInNavigation = ({ goToNameEntry, goToOnboarding }) => {
    /**
     * Routing
     */

    const history = useHistory();

    /**
     * useContext
     */

    const { account, isAuthenticated, isInitializing } =
        useContext(AuthContext);

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        generateSignInStartingPoint({
            goToNameEntry,
            goToOnboarding,
        })
    );
    const [hasInitializedNavigation, setHasInitializedNavigation] =
        useState(false);
    const [hasRedirected, setHasRedirected] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        if (!isInitializing && !isAuthenticated) {
            setHasInitializedNavigation(true);
        }
        if (!isInitializing && isAuthenticated && account && !hasRedirected) {
            const redirectData = generateRedirectData({ account });
            if (redirectData) {
                setCurrentSection(redirectData.value);
            } else {
                history.push("/letters");
            }
            setHasInitializedNavigation(true);
            setHasRedirected(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, isAuthenticated, isInitializing]);

    /**
     * End Hooks
     */

    return {
        currentSection,
        hasInitializedNavigation,
        setCurrentSection,
    };
};
