import React, { useContext } from "react";

// Context
import { NavBarContext } from "context/navbar";

// Utils
import { isMobile, isUsingIPad } from "utils/mediaQueryUtils";

export const PageLayout = ({ children }) => {
    /**
     * useContext
     */

    const { shouldShowNavBar } = useContext(NavBarContext);

    /**
     * End Hooks
     */

    const shouldShowMobileVersion = isMobile() && !isUsingIPad();
    const appClassNames = shouldShowMobileVersion
        ? "app-mobile"
        : `app ${shouldShowNavBar ? "" : "hide-nav-bar"}`;
    return <div className={appClassNames}>{children}</div>;
};
