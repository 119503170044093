import { useEffect, useState } from "react";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const useDashboardPromotions = (props) => {
    const { promotions, taxIdentity } = props;

    /**
     * useState
     */

    const [promotionsFormatted, setPromotionsFormatted] = useState([]);

    /**
     * useEffect
     */

    useEffect(() => {
        if (hasPromotions) {
            fetchImages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotions]);

    /**
     * Constants
     */

    const carouselConfig = {
        shouldShowHeader: false,
        shouldShowNavInternal: true,
    };
    const hasPromotions = !!promotions?.length;
    const hasPromotionsFormatted = !!promotionsFormatted?.length;
    const promotionItemStyle = {
        marginRight: "24px",
        width: "60%",
        minWidth: "60%",
    };
    const shouldShowPromotions = taxIdentity && hasPromotions;

    /**
     * End Hooks & Constants
     */

    async function fetchImages() {
        await Promise.all(
            promotions.map(async (promotion) => {
                try {
                    const json = await SandboxxRestAPI.getImageCloudfront(
                        promotion.lightModeUrl
                    );
                    return json;
                } catch (err) {
                    return "";
                }
            })
        ).then((photoUrls) => {
            setPromotionsFormatted(
                promotions.map((promotion, i) => ({
                    ...promotion,
                    imageUrl: photoUrls[i],
                }))
            );
        });
    }

    return {
        carouselConfig,
        hasPromotions,
        hasPromotionsFormatted,
        promotionItemStyle,
        promotionsFormatted,
        shouldShowPromotions,
    };
};
