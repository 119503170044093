import { useEffect, useState } from "react";

// Enums
import { LetterType } from "enums/LetterType";

// Media
import imageEmptySrc from "media/icons/image-empty.svg";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const useLetterPreview = ({ letter, letterType }) => {
    /**
     * useState
     */

    const [imageSrc, setImageSrc] = useState(imageEmptySrc);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchLetterImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Constants
     */

    const tagText = letter?.newsletterType
        ? letter.newsletterType.replace(/_/g, " ")
        : "LETTER";

    /**
     * End Hooks & Constants
     */

    async function fetchLetterImage() {
        try {
            if (letterType === LetterType.SENT) {
                const json = await SandboxxRestAPI.getImageCloudfront(
                    letter.photo_url
                );
                setImageSrc(json);
            }
        } catch (error) {}
    }

    return { imageSrc, tagText };
};
