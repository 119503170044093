import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Modal } from "components/Modals/Modal";
import { MusterRecruitInviteCodeFailureModal } from "./MusterRecruitInviteCodeFailureModal/MusterRecruitInviteCodeFailureModal";
import { MusterRecruitInviteCodeModal } from "./MusterRecruitInviteCodeModal/MusterRecruitInviteCodeModal";

// CSS
import styles from "./muster-recruit-join-modal.module.scss";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const MusterRecruitJoinModal = (props) => {
    const {
        contactList,
        fetchRecruiters,
        redeemMusterCode,
        rootPersona,
        setShouldShowJoinModal,
        setShouldShowJoinSuccessModal,
        shouldShowJoinModal,
        showNotification,
    } = props;

    /**
     * useState
     **/
    const [currentSection, setCurrentSection] = useState("join");
    const [failureText, setFailureText] = useState("");
    const [joinModalheaderText, setJoinModalHeaderText] =
        useState("Join Waypoints");
    const [joinModalheaderSubText, setJoinModalHeaderSubText] = useState(
        "Enter the code you received from your recruiter to connect!"
    );

    const [loading, setLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        handleRedeemMusterCode();
        if (contactList.length === 0) {
            setJoinModalHeaderText("Join Waypoints");
        } else {
            setJoinModalHeaderText("Change Recruiter");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactList]);

    /**
     * End Hooks
     **/

    function handleChange(value) {
        const formattedValue = value.join("");
        setVerificationCode(formattedValue);
    }

    function handleGoBack() {
        setCurrentSection("join");
        setJoinModalHeaderSubText(
            "Enter the code you received from your recruiter to connect!"
        );
        setJoinModalHeaderText("Join Waypoints");
        setVerificationCode(null);
    }

    function handleModalClose() {
        setShouldShowJoinModal(false);
        handleGoBack();
    }

    function handleRedeemMusterCode() {
        const { musterInviteCode } =
            JSON.parse(localStorage.getItem("musterInviteCode")) || "";
        if (musterInviteCode && rootPersona.id !== PersonaEnum.RECRUITER) {
            setLoading(true);
            redeemMusterCode(
                musterInviteCode,
                onJoinMusterSuccess,
                onJoinMusterFailure
            );
            localStorage.setItem("musterInviteCode", "{}");
        }
    }

    function handleSubmit() {
        redeemMusterCode(
            verificationCode,
            onJoinMusterSuccess,
            onJoinMusterFailure,
            onJoinMusterFailure
        );
        setLoading(true);
    }

    function onJoinMusterFailure(err) {
        const errorMessage = getErrorMessage(err);
        setFailureText(errorMessage);
        setJoinModalHeaderText("");
        setJoinModalHeaderSubText("");
        setCurrentSection("failure");
        setLoading(false);
        setShouldShowJoinModal(true);
    }

    function onJoinMusterSuccess(res) {
        setShouldShowJoinModal(false);
        AnalyticsLogger.logMusterJoined();
        setLoading(false);
        fetchRecruiters();
        setShouldShowJoinSuccessModal(true);
    }

    function renderModalContent() {
        switch (currentSection) {
            case "join":
                return (
                    <MusterRecruitInviteCodeModal
                        {...props}
                        handleChange={handleChange}
                        handleModalClose={handleModalClose}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        showNotification={showNotification}
                        verificationCode={verificationCode}
                    />
                );
            case "failure":
                return (
                    <MusterRecruitInviteCodeFailureModal
                        {...props}
                        failureText={failureText}
                        handleGoBack={handleGoBack}
                        handleModalClose={handleModalClose}
                    />
                );
            default:
                break;
        }
    }

    return (
        <Modal
            className={styles["muster-recruit-join-modal"]}
            dialogClassName={styles["muster-recruit-join-modal-dialog"]}
            onHide={handleModalClose}
            shouldHideHeader={!joinModalheaderText}
            headerSubText={joinModalheaderSubText}
            headerText={joinModalheaderText}
            show={shouldShowJoinModal}
            size={
                currentSection === "success" || currentSection === "failure"
                    ? "sm"
                    : ""
            }
        >
            {renderModalContent()}
        </Modal>
    );
};

MusterRecruitJoinModal.propTypes = {
    contactList: PropTypes.array.isRequired,
    fetchRecruiters: PropTypes.func.isRequired,
    redeemMusterCode: PropTypes.func.isRequired,
    rootPersona: PropTypes.object.isRequired,
    setShouldShowJoinModal: PropTypes.func.isRequired,
    setShouldShowJoinSuccessModal: PropTypes.func.isRequired,
    shouldShowJoinModal: PropTypes.bool.isRequired,
    showNotification: PropTypes.func,
};
