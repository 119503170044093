// Interfaces
import { SessionManager } from "interfaces/auth/SessionManager.js";

// Utils
import { generateDepLocationsUrl } from "./depUtils.js";
import { deleteFetch, getImage, get, post, put } from "./fetchUtils.js";
import { removeNullUndefinedEmptyFromObject } from "./objectUtils.js";
import { DEV, restBase } from "./urlUtils.js";

function getSandboxxUserKey() {
    return DEV ? "SandboxxDevUser" : "SandboxxUser";
}

export class CurrentUser {
    static clearUser() {
        localStorage.removeItem(getSandboxxUserKey());
        localStorage.setItem("urlQueryParams", "{}");
    }

    static getUser() {
        return JSON.parse(localStorage.getItem(getSandboxxUserKey()));
    }

    static isLoggedIn() {
        try {
            return SessionManager.isAuthenticated();
        } catch (error) {
            return false;
        }
    }

    static isOnboardingRequired() {
        const user = JSON.parse(localStorage.getItem(getSandboxxUserKey()));
        return user?.onboarding;
    }

    static isVerified() {
        const user = JSON.parse(localStorage.getItem(getSandboxxUserKey()));
        return user?.phoneVerified;
    }

    static storeUser({ intercom_hash, onboarding, user, verification }) {
        const userToStore = {
            avatar: user.avatarS,
            birthday: user.birthday,
            branchId: user.branchId,
            city: user.address && user.address.city,
            country: user.address && user.address.country,
            email: user.email,
            firstName: user.firstName,
            fullName: user.fullName,
            gender: user.gender,
            hasSandboxxPlus: user.hasSandboxxPlus,
            intercomHash: intercom_hash,
            lastName: user.lastName,
            line1: user.address && user.address.line1,
            line2: user.address && user.address.line2,
            onboarding: onboarding.required,
            personas: user.personas || [],
            phoneNumber: user.phoneNumber,
            phoneVerified: verification.phoneVerified,
            rank: user.rank,
            role: user.role,
            state: user.address && user.address.state,
            userId: user.id,
            zipcode: user.address && user.address.zipcode,
        };
        localStorage.setItem(getSandboxxUserKey(), JSON.stringify(userToStore));
    }

    static toggleOnboarding(bool) {
        const user = JSON.parse(localStorage.getItem(getSandboxxUserKey()));
        const userToStore = { ...user, onboarding: bool };
        localStorage.setItem(getSandboxxUserKey(), JSON.stringify(userToStore));
    }
}

export class SandboxxRestAPI {
    static async generateHeaders() {
        try {
            const headers = new Headers();
            const accessToken = await SessionManager.getAccessToken();
            if (accessToken) {
                headers.append("Authorization", `Bearer ${accessToken}`);
            } else {
                throw new Error("User is not logged in");
            }
            headers.append("Content-Type", "application/json");
            return headers;
        } catch (error) {
            return {};
        }
    }

    /** Amazon **/

    static async getImageCloudfront(
        urlFragment,
        config = { fileType: "jpeg" }
    ) {
        if (CurrentUser.isLoggedIn() && urlFragment) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/media/i/${urlFragment}`;
            return getImage(url, headers, config);
        }
        return Promise.reject("User is not logged in or invalid URL fragment");
    }

    static async putImageCloudfront(urlFragment, imageBinary) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn && urlFragment) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/media/i/${urlFragment}`;
            return put(url, headers, imageBinary);
        }
        return Promise.reject("User is not logged in or invalid URL fragment");
    }

    /** Address Book */

    static async createAddressBookContact(contact) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(contact);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/address_book/contact`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async deleteAddressBookContact(contact) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const id = contact.abContactId || contact.id;
            const body = JSON.stringify({});
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/address_book/contact?ab_contact_id=${id}`;
            return deleteFetch(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async editAddressBookContact(contact) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                ...contact,
                abContactId: contact.abContactId || contact.id,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/address_book/contact`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getAddressBookContact(id) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/address_book/contact?ab_contact_id=${id}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getAddressBookContacts() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/address_book/contacts`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getAddressBookRecentContacts() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/address_book/recent`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Auth **/

    static async checkForOnboardingSkip() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/associations/kin/skip_onboard`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async checkPhoneVerificationCode({ verificationCode }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                verificationCode,
                verifyVersion: "v2",
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/phone/verification/v2/verify`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async generatePhoneVerificationCode({ phoneNumber, via }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                countryCode: phoneNumber.countryCode,
                phoneNumber: phoneNumber.baseNumber.replace(/^(\+)|\D/g, "$1"),
                verifyVersion: "v2",
                via,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/phone/verification/v2/generate`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async sendInstallParams(installParams) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ installParams });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/install/params`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async resendPhoneVerificationCode({
        phoneNumber,
        verificationType,
    }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const { baseNumber, countryCode } = phoneNumber;
            const body = JSON.stringify({
                countryCode,
                phoneNumber: baseNumber.replace(/^(\+)|\D/g, "$1"),
                verifyVersion: "v2",
                via: verificationType,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/phone/verification/v2/resend`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Braintree */

    static async addBraintreeCard({ nonce }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ brainTreePaymentNonce: nonce });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/cards/v2`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getBraintreeSavedCards() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/cards/v2`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getRecentPaymentMethod() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/recent/payment/v2`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Bundles **/

    static async applyDiscount(params) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const { bundleId, code } = params;
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/bundles/discount/check?bundleId=${bundleId}&discountCode=${code}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getBundles(params) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const { recipientUserId, zipCode } = params;
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/bundles/v2?recipientUserId=${recipientUserId}&zipCode=${zipCode}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async purchaseBundleV3({
        bundleId,
        discount,
        paymentMethod,
        token,
    }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const { code, status } = discount;
            const discountPayload =
                status === "active" ? { discountCode: code } : {};
            const body = JSON.stringify({
                paymentMethod,
                paymentToken: token,
                ...discountPayload,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/bundles/${bundleId}/purchase/v3`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Dashboard */

    static async getDashboard() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/dashboard`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getNewsItems({ page = 1, pageSize = 20 } = {}) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const params = `?page=${page}&pageSize=${pageSize}`;
            const url = `${restBase}/wordpress/news${params}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Dep */

    static async consumeDepUpdate({ weeklyUpdates }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const { contentNumber } = weeklyUpdates.selected;
            const body = JSON.stringify({
                branch: "Navy",
                contentNumber,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/dep/content/consumption`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getDepLocations({ branchId }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const depPath = generateDepLocationsUrl(branchId);
            if (branchId && depPath) {
                const headers = await SandboxxRestAPI.generateHeaders();
                const url = `${restBase}/${depPath}`;
                return get(url, headers);
            }
            return Promise.reject("User has does not have valid dep value");
        }
        return Promise.reject("User is not logged in");
    }

    static async setDepLocation({ depsShortCode }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ depsShortCode });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/dep/location`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getDepUpdatesConsumed() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/dep/content/consumption`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Graduation **/

    static async getGraduation() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/graduation`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /**
     * * The API's `requestedWeeksUntil` key is misspelled
     */
    static async setGraduationWeek({ gradJointId, requestedWeeksUntil }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                gradJointId,
                requestedWeeksUtil: requestedWeeksUntil,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/graduation`;
            return post(url, headers, body);
        }
    }

    /** Digital Wallet **/

    static async addGiftCardToWalletWithBarcode({ giftCardNumber, pin }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                giftCardNumber,
                pin,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/wallet/giftcards/add`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getWalletSuggestedContacts() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/wallet/giftcards/v2/suggested_contacts`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getWalletGiftCardOptions({ provider }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const providerQuery = provider ? `?provider=${provider}` : "";
            const url = `${restBase}/wallet/giftcards/purchase${providerQuery}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getWalletGiftCardOptionsV2() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/wallet/giftcards/v2/options`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getWalletGiftCards({ ownershipType }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const ownershipTypeQuery = ownershipType
                ? `?ownershipType=${ownershipType}`
                : "";
            const url = `${restBase}/wallet/giftcards${ownershipTypeQuery}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async purchaseWalletGiftCard({ giftcard, payment }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                giftcard,
                payment,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/wallet/giftcards/purchase`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async purchaseWalletGiftCardV2(payload) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(payload);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/wallet/giftcards/v2/purchase`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Interstitial */

    static async getInterstitialByName({ name }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/interstitial?name=${name}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getInterstitialByScreen({ screen }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/interstitial?screen=${screen}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Letters: Compose **/

    static async getMultiphotoStatus() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/options`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /**** Letters: Add-Ons ****/

    static async getAddOns(payload) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(payload);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/addons`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getGiftCardOptions({ zipCode }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/giftcards?zipcode=${zipCode}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    // TODO: Remove paymentMethod hardcoding when options expand
    static async purchaseAddOns({
        bools,
        giftCard,
        newsletters,
        postage,
        sentLetter,
    }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const {
                hasAddOnsTotal,
                hasPurchasedGiftCard,
                hasSelectedAddOns,
                hasSelectedPostage,
            } = bools;
            const { amount, braintree, purchased } = giftCard;
            const { sponsor } = purchased;
            const { token } = braintree;
            const { letterId } = sentLetter;

            const giftCardPayload = hasPurchasedGiftCard
                ? {
                      giftcard: {
                          sponsor,
                          value: amount.base.toString(),
                      },
                  }
                : {};
            const newslettersPayload = hasSelectedAddOns ? { newsletters } : {};
            const postagePayload = hasSelectedPostage
                ? { postage: postage[0] }
                : {};
            const payment =
                hasAddOnsTotal || hasPurchasedGiftCard
                    ? {
                          payment: {
                              paymentMethod: "BRAINTREE",
                              paymentToken: token,
                          },
                      }
                    : {};

            const body = JSON.stringify({
                ...giftCardPayload,
                ...newslettersPayload,
                ...postagePayload,
                ...payment,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/${letterId}/addon/purchase`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /**** Letters: Address Formatter ****/

    static async getAddressFormatterBases() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/bases`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getAddressFormatterForm(baseId) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/address/forms/${baseId}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async submitAddressFormatterForm(form) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/address/forms/submit`;
            return post(url, headers, JSON.stringify(form));
        }
        return Promise.reject("User is not logged in");
    }

    /**** Letters: Resolution ****/

    static async getExampleAddresses({ zipcode }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/address/examples?zipcode=${zipcode}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateLetterAddress({ letterId, payload }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(payload);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/${letterId}`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Iterable **/

    /**
     * @returns {Promise}
     */
    static async getIterableEmbeddedMessages() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/iterable/embedded-messaging/messages`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /**
     * @param {Function} callback
     * @param {Function} onFailure
     * @returns {Promise}
     */
    static async getIterableJWT() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                emailSignature: true,
                platform: "WEB",
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/iterable/jwt`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /**
     * @param {Object} payload
     *  @param {String?} buttonId
     *  @param {String} messageId
     *  @param {String} targetUrl
     * @param {Function} callback
     * @param {Function} onFailure
     * @returns {Promise}
     */
    static async trackIterableEmbeddedMessageClickEvent({ payload }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(payload);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/iterable/embedded-messaging/events/click`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /**
     * @param {Object} payload
     *  @param {String} messageId
     * @returns {Promise}
     */
    static async trackIterableEmbeddedMessageReceivedEvent(payload) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(payload);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/iterable/embedded-messaging/events/received`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Letters **/

    static async createLetterDraft(body) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/draft`;
            return post(url, headers, JSON.stringify(body));
        }
        return Promise.reject("User is not logged in");
    }

    static async getLetterDraft() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/draft`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getLetterRepliedTracking({ id }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/${id}/track/replied`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getLetterSentTrackingV2({ id }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/${id}/track/sent`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getKinConnections() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/associations/kin`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getLettersReplied(cursor) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/replied?cursor=${cursor}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getLettersSent(cursor) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters?cursor=${cursor}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getTitles({ zipcode }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const zipcodeQuery = zipcode ? `?zipcode=${zipcode}` : "";
            const url = `${restBase}/letters/recommended/titles${zipcodeQuery}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async searchForUser({ role = "", term }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/search?role=${role}&term=${term}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async sendLetter(payload) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(payload);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/create`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateLetterDraft(body) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/letters/draft/${body.id}`;
            return put(url, headers, JSON.stringify(body));
        }
        return Promise.reject("User is not logged in");
    }

    /** Personas **/

    static async getChildPersonas({ id }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/personas/${id}/children`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getRootPersonas() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/personas/root`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async updatePersona(personas) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ personas });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/personas`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Muster **/

    static async addMusterDocument({ categoryId, description }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                categoryId,
                description,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/muster/document`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async deleteMusterDocument({ documentId }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({});
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/muster/document/${documentId}`;
            return deleteFetch(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getMagicLink() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/associations/muster/code`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getMusterRecruiters() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/associations/muster/recruiter`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getMusterRecruits() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/associations/muster/recruit`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getRecruitDashboard() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/muster/recruit/dashboard`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getRecruiterDashboard() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/muster/recruiter/dashboard`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async joinMusterRecruit(musterInviteCode) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ musterInviteCode });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/associations/muster/redeem`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async submitMusterRecruitEmailInvites({ emailList = [] }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                emailList,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/associations/muster/invite`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateMusterDocument({
        categoryId,
        id,
        description,
        completed,
    }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                categoryId,
                description,
                completed,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/muster/document/${id}`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Profile **/

    static async deleteAccount() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({});
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/account/v2`;
            return deleteFetch(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getAccount() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/meV2?platform=WEB`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getCredits() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/credits`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getIntercomHash() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/intercom/hash?platform=WEB`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getProfile() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/account`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async setUserShipDate({ baseId, shipDate }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ baseId, shipDate });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/shipdate`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserAddress({ address, firstName, lastName }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                ...removeNullUndefinedEmptyFromObject(address),
                firstName,
                lastName,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/address/v2`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserAvatar(avatarUrl) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/avatar`;
            const body = JSON.stringify({ avatarS: avatarUrl });
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserBirthday({ birthday }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ value: birthday });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/birthday`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserBranch({ branch }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ branch });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/update/branch`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserFirstName({ firstName }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ value: firstName });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/firstname`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserFullName({ firstName, lastName }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ firstName, lastName });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/fullname`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserGender({ gender }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ value: gender });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/gender`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserLastName({ lastName }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ value: lastName });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/lastname`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserMaritalStatus({ maritalStatus }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ value: maritalStatus });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/maritalstatus`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserRank({ rank }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ value: rank });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/rank`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateUserRole({ role }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ role });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/update/role`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Referrals **/

    static async claimReferralCode(referralCode) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ referralCode });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/referral`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getReferralCode() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/referral`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getReferralRewards() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/rewards`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Store **/

    static async applyDiscountCode({ cart, discountCode }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({});
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books/${cart.id}/discount-code/${discountCode}`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async completeCartSession({ cart }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({});
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books/${cart.id}/complete`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getCartSession() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getShippingOptions({ cart }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn && cart) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books/${cart.id}/shipping-options`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async submitPaymentDetails({
        billingAddress,
        cart,
        paymentMethodToken,
    }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ billingAddress, paymentMethodToken });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books/${cart.id}/payment`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async submitShippingAddress({ cart, shippingAddress }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ shippingAddress });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books/${cart.id}/shipping-address`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async submitShippingOption({ address, cart, shippingOptionId }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ address, shippingOptionId });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books/${cart.id}/shipping-options`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async startCartSession() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({});
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateCartBookQuantity({ quantity }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ quantity });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/store/memory-books`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Support Squad **/

    static async createSupportSquad(squad) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(squad);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async deleteSupportSquad(squadId) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ squadId });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad`;
            return deleteFetch(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async donateTokens({ squadId, tokenDonationAmount }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ squadId, tokenDonationAmount });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad/donate`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getSupportSquadItem(squadId) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad?squadId=${squadId}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getSupportSquadList() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad/list`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getSupportSquadMembers(squadId) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad/list/users?squadId=${squadId}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async joinSupportSquad(supportSquadInviteCode) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ supportSquadInviteCode });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad/join`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateSupportSquadMemberRole(squadId, userToPromote, newRole) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({ squadId, userToPromote, newRole });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad/role_change`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async removeSupportSquadMember(leavingUserId, squadId) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = leavingUserId
                ? JSON.stringify({ squadId, leavingUserId })
                : JSON.stringify({ squadId });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad/leave`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async submitSupportSquadEmailInvites({
        squadId,
        supportSquadEmails = [],
        supportSquadUserIds = [],
    }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify({
                squadId,
                supportSquadEmails,
                supportSquadUserIds,
            });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad/invite`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async updateSupportSquad(squad) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const body = JSON.stringify(squad);
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/support_squad`;
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    /** Users **/

    static async getUserOrderHistory({ cursor, userId }) {
        if (CurrentUser.isLoggedIn()) {
            const cursorQueryParam = cursor ? `?cursor=${cursor}` : "";
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/${userId}/orders${cursorQueryParam}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Weekly Updates **/

    static async consumeWeeklyUpdate({ weeklyUpdates }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const { graduation, selected } = weeklyUpdates;
            const { specialization, trainingBase } = graduation[0];
            const { week } = selected;
            const graduationTrackCode = specialization
                ? `${trainingBase}-${specialization}`.toLowerCase()
                : trainingBase.toLowerCase();
            const body = JSON.stringify({ graduationTrackCode, week });
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/weekly_updates/consumed`;
            return post(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }

    static async getWeeklyUpdates({ baseSpecialization }) {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const params = `?weeklyUpdateTrack=${baseSpecialization}`;
            const url = `${restBase}/wordpress/weekly_updates${params}`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static async getWeeklyUpdatesConsumed() {
        const isLoggedIn = CurrentUser.isLoggedIn();
        if (isLoggedIn) {
            const headers = await SandboxxRestAPI.generateHeaders();
            const url = `${restBase}/users/weekly_updates/consumed`;
            return get(url, headers);
        }
        return Promise.reject("User is not logged in");
    }
}
