import React, { createContext, useEffect, useMemo } from "react";

// Hooks
import { useAuthSignIn } from "./useAuthSignIn";
import { useAuthRownd } from "./useAuthRownd";
import { useAuthAccount } from "./useAuthAccount";

// Platforms
import { Analytics } from "platforms/analytics";
import { Intercom } from "platforms/intercom";

// Constants
export const AuthContext = createContext({});

export const AuthProvider = React.memo((props) => {
    /**
     * Custom Hooks
     */

    const {
        account,
        fetchAccount,
        fetchAccountAfterRowndEvent,
        resetAccountState,
    } = useAuthAccount();
    const { onLogin, onLogout, onSignUp } = useAuthSignIn({
        fetchAccountAfterRowndEvent,
        resetAccountState,
    });
    const {
        getAccessToken,
        isAuthenticated,
        isInitializing,
        isLoggingIn,
        isLoggingOut,
        isSigningUp,
        setIsLoggingIn,
        setIsLoggingOut,
        setIsSigningUp,
        signOut,
    } = useAuthRownd();

    /**
     * useEffect
     */

    useEffect(() => {
        if (isAuthenticated && !account && !isLoggingIn && !isSigningUp) {
            const handleFetchAccount = async () => {
                try {
                    const json = await fetchAccountAfterRowndEvent();
                    Intercom.registerUser(json);
                    Analytics.initOnAuth({ user: json?.user });
                } catch (err) {}
            };
            handleFetchAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        if (isLoggingIn && isAuthenticated) {
            onLogin();
            setIsLoggingIn(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoggingIn]);

    useEffect(() => {
        if (isLoggingOut) {
            onLogout();
            setIsLoggingOut(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggingOut]);

    useEffect(() => {
        if (isSigningUp && isAuthenticated) {
            onSignUp();
            setIsSigningUp(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isSigningUp]);

    /**
     * End Hooks
     */

    const authContext = useMemo(
        () => ({
            account,
            fetchAccount,
            getAccessToken,
            isAuthenticated,
            isInitializing,
            isLoggingOut,
            signOut,
        }),
        [
            account,
            fetchAccount,
            getAccessToken,
            isAuthenticated,
            isInitializing,
            isLoggingOut,
            signOut,
        ]
    );

    return (
        <AuthContext.Provider value={authContext}>
            {props.children}
        </AuthContext.Provider>
    );
});
