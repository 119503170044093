// Enums
import { NavigationEnum as SignInNavigationEnum } from "scenes/SignIn/constants/constants";

/**
 * Checks if the current pathname is a sign-in route.
 *
 * @param {string} pathname - The pathname to check
 * @returns {boolean} True if the pathname is a sign-in route, false otherwise
 */
export function checkIfIsSignInRoute(pathname) {
    const pathnameToCheck = pathname || window.location.pathname;
    return pathnameToCheck.includes("signin");
}

/**
 * Generates redirect data based on the user's account state to ensure required setup steps are completed.
 * Used by ProtectedPage to enforce required user actions before allowing full app access.
 *
 * @param {Object} param0 - The parameters object
 * @param {Object} param0.account - The user's account object containing user and onboarding data
 * @param {Object} param0.account.user - User data containing firstName and lastName
 * @param {Object} param0.account.onboarding - Onboarding configuration containing required status
 * @returns {Object|null} Redirect data object containing pathname and value if action required (null if no redirect needed):
 * @returns {string} returns.pathname - The URL path to redirect to
 * @returns {string} returns.value - The corresponding navigation enum value
 */
export function generateRedirectData({ account }) {
    if (!account.user.firstName || !account.user.lastName) {
        return {
            pathname: "/signin/name",
            value: SignInNavigationEnum.SIGN_UP_NAME,
        };
    }
    if (account.onboarding.required) {
        return {
            pathname: "/signin/onboarding",
            value: SignInNavigationEnum.SIGN_UP_PERSONA_ROOT,
        };
    }
    return null;
}
