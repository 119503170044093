import { useContext, useEffect, useState } from "react";

// Context
import { CartContext } from "context/cart/cart";
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { CartNavigation } from "components/Cart/enums/CartNavigation";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { formatPhoneNumberForShippingAddress } from "../utils/utils";
import { CurrentUser } from "utils/sandboxx";

// Validation
import { Validation } from "../validation/Validation";

export const useCartShippingAddressForm = ({
    setCartCurrentSection,
    setHeaderText,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { cart, isUpdatingCart, submitShippingAddress } =
        useContext(CartContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shippingAddress, setShippingAddress] = useState({
        city: "",
        countryCode: "us",
        firstName: "",
        lastName: "",
        line1: "",
        line2: "",
        phone: {
            baseNumber: "",
            countryCode: "",
            isValid: false,
        },
        postalCode: "",
        province: "",
    });

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(t("cart.add_shipping_address", { ns: "components" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setHeaderText]);

    useEffect(() => {
        syncShippingAddressWithCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        cart?.shipping_address?.address_1,
        cart?.shipping_address?.address_2,
        cart?.shipping_address?.city,
        cart?.shipping_address?.country_code,
        cart?.shipping_address?.first_name,
        cart?.shipping_address?.last_name,
        cart?.shipping_address?.phone,
        cart?.shipping_address?.postal_code,
        cart?.shipping_address?.province,
    ]);

    /**
     * Constants
     */

    const user = CurrentUser.getUser();
    const hasValidProfileAddress = Validation.hasValidProfileAddress({
        user: CurrentUser.getUser(),
    });
    const hasValidShippingAddress = Validation.hasValidShippingAddress({
        shippingAddress,
    });

    /**
     * End Hooks
     */

    function handleChange(e) {
        const { name, value } = e.target;
        setShippingAddress((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    function handleGoBack() {
        setCartCurrentSection(() => CartNavigation.MAIN);
    }

    function handleChangePhoneNumber(isValid, value, countryData) {
        const { dialCode } = countryData;
        setShippingAddress((prev) => ({
            ...prev,
            phone: { baseNumber: value, countryCode: dialCode, isValid },
        }));
    }

    function handleClearPhoneNumber() {
        const { dialCode } = arguments[1];
        setShippingAddress((prev) => ({
            ...prev,
            phone: {
                baseNumber: "",
                countryCode: dialCode,
                isValid: false,
            },
        }));
    }

    function handleSubmit() {
        if (!isSubmitting) {
            setIsSubmitting(() => true);
            return submitShippingAddress({
                onFailure: onSubmitShippingAddressFailure,
                onSuccess: onSubmitShippingAddressSuccess,
                shippingAddress,
            });
        }
    }

    function populateShippingAddress() {
        if (hasValidProfileAddress) {
            setShippingAddress((prev) => ({
                ...prev,
                city: user.city,
                firstName: user.firstName,
                lastName: user.lastName,
                line1: user.line1,
                line2: user.line2,
                province: user.state,
                postalCode: user.zipcode,
            }));
        }
    }

    function onSubmitShippingAddressFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIsSubmitting(() => false);
        showNotification({ text: errorMessage, type: "warning" });
    }

    function onSubmitShippingAddressSuccess() {
        setIsSubmitting(() => false);
        setCartCurrentSection(() => CartNavigation.MAIN);
    }

    /**
     * Syncs local state with the shipping address in the user's cart
     * session, if that data is present.
     */
    function syncShippingAddressWithCart() {
        if (cart) {
            setShippingAddress((prev) => ({
                city: cart.shipping_address?.city || "",
                countryCode: "us",
                firstName: cart.shipping_address?.first_name || "",
                lastName: cart.shipping_address?.last_name || "",
                line1: cart.shipping_address?.address_1 || "",
                line2: cart.shipping_address?.address_2 || "",
                phone: {
                    // Removes +1 country code from front of base number
                    baseNumber: formatPhoneNumberForShippingAddress(
                        cart.shipping_address?.phone
                    ),
                    countryCode: "1",
                    isValid: false,
                },
                province: cart.shipping_address?.province || "",
                postalCode: cart.shipping_address?.postal_code || "",
            }));
        }
    }

    return {
        handleChange,
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        handleGoBack,
        handleSubmit,
        hasValidProfileAddress,
        hasValidShippingAddress,
        isSubmitting,
        isUpdatingCart,
        populateShippingAddress,
        shippingAddress,
    };
};
