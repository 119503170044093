import { useContext, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { NotificationContext } from "context/notification/notification";

// Enums
import { NotificationSeverity } from "enums/notifications/NotificationSeverity";

// Models
import { Notification } from "models/notification/Notification";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const useReferralsRedeemCodeModal = ({
    setShouldShowRedeemCodeModal,
}) => {
    /**
     * useContext
     */

    const { addNotification } = useContext(NotificationContext);

    /**
     * useState
     */

    const [code, setCode] = useState("");
    const [isSubmittingCode, setIsSubmittingCode] = useState(false);

    /**
     * End Hooks
     */

    function handleChange(value) {
        const formattedValue = value.join("");
        setCode(formattedValue);
    }

    async function redeemCode(e) {
        e?.preventDefault();
        setIsSubmittingCode(true);
        try {
            const { json } = await SandboxxRestAPI.claimReferralCode(code);
            onRedeemCodeSuccess(json);
        } catch (err) {
            onRedeemCodeFailure(err);
        }
    }

    function onRedeemCodeFailure(err) {
        setIsSubmittingCode(false);
        const errorMessage = getErrorMessage(err);
        addNotification(
            new Notification({
                id: "REFERRALS_REDEEM_CODE_FAILURE",
                message: errorMessage,
                options: { severity: NotificationSeverity.ERROR },
            })
        );
    }

    function onRedeemCodeSuccess(res) {
        setIsSubmittingCode(false);
        setShouldShowRedeemCodeModal(false);
        addNotification(
            new Notification({
                id: "REFERRALS_REDEEM_CODE_SUCCESS",
                message: res.message,
                options: { severity: NotificationSeverity.SUCCESS },
            })
        );
    }

    return { code, handleChange, isSubmittingCode, redeemCode };
};
