import React from "react";
import { Link, withRouter } from "react-router-dom";
import cx from "classnames";

// Components
import { AuthModalLogoutWithRouter as AuthModalLogout } from "components/Modals/AuthModalLogout";
import { NavBarItem } from "./components/NavBarItem/NavBarItem";

// CSS
import styles from "./navbar.module.scss";
import navBarItemStyles from "./components/NavBarItem/nav-bar-item.module.scss";

// Enums
import { NavBarItemKeys } from "./enums/NavBarItemKeys";

// Hooks
import { useNavBar } from "./hooks/useNavBar";

// Images
import { IconLogout } from "components/Images/SVGIcons";

// Localization
import { useTranslation } from "localization/localization";

// Media
import sandboxxLogoSrc from "media/shared/logo-text-orange.svg";

// Utils
import { CurrentUser } from "utils/sandboxx";
import { handleKeyDown } from "utils/eventUtils";
import {
    generateLettersDynamicParams,
    generateMusterDynamicParams,
    generateNavBarItemDataList,
} from "./utils/utils";

const NavBar = () => {
    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    const {
        hasUnreadInboxMessages,
        history,
        setShouldShowLogOutModal,
        shouldShowLogOutModal,
    } = useNavBar();

    /**
     * End Hooks
     **/

    function renderLogoutOption() {
        return (
            <div
                className={navBarItemStyles.navBarItem}
                data-cy="navbar-logout-option"
                onClick={() => setShouldShowLogOutModal(true)}
                onKeyDown={(e) =>
                    handleKeyDown(e, () => setShouldShowLogOutModal(true))
                }
                tabIndex="0"
            >
                <IconLogout
                    className={cx(
                        navBarItemStyles.icon,
                        navBarItemStyles.iconLogout
                    )}
                />
                <span className={navBarItemStyles.text}>
                    {t("logout", { ns: "common" })}
                </span>
            </div>
        );
    }

    function renderNavItems() {
        const user = CurrentUser.getUser();
        const rootPersona = (user?.personas && user?.personas?.[0]) || {
            id: "UNKNOWN",
        };
        const dynamicParams = {
            [NavBarItemKeys.INBOX]: { unread: hasUnreadInboxMessages },
            [NavBarItemKeys.LETTERS]: generateLettersDynamicParams(rootPersona),
            [NavBarItemKeys.MUSTER]: generateMusterDynamicParams(rootPersona),
        };
        const navData = generateNavBarItemDataList(rootPersona, dynamicParams);

        return navData.map((data, i) => {
            return <NavBarItem data={data} history={history} key={i} />;
        });
    }

    function renderNav() {
        return (
            <nav className={styles.navbar} data-cy="navbar" role="menu">
                <div className={styles.header}>
                    <Link className={styles.logo} to="/">
                        <img
                            alt={t("sandboxx_logo", { ns: "common" })}
                            src={sandboxxLogoSrc}
                        />
                    </Link>
                </div>
                {renderNavItems()}
                <div className={styles["logout-spacer"]}></div>
                {renderLogoutOption()}
            </nav>
        );
    }

    return (
        <>
            {renderNav()}
            <AuthModalLogout
                setShouldShowLogOutModal={setShouldShowLogOutModal}
                shouldShowLogOutModal={shouldShowLogOutModal}
            />
        </>
    );
};

export const NavBarWithRouter = withRouter(NavBar);
