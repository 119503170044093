// Utils
import { CurrentUser } from "utils/sandboxx";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Components
import { Muster } from "scenes/Muster/scenes/Muster";
import { WrappedDashboard as Dashboard } from "scenes/Dashboard/Dashboard";

export const BaseComponent = (props) => {
    const user = CurrentUser.getUser();
    const rootPersona = user?.personas[0];
    if (
        rootPersona?.id === PersonaEnum.RECRUITER ||
        rootPersona?.id === PersonaEnum.RECRUIT ||
        rootPersona?.id === PersonaEnum.RECRUITER_COMMAND
    ) {
        return <Muster {...props} />;
    } else {
        return <Dashboard {...props} />;
    }
};
