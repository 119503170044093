// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { DEV } from "../utils/urlUtils";

function intercomAppId() {
    return DEV
        ? process.env.REACT_APP_INTERCOM_ID_DEV
        : process.env.REACT_APP_INTERCOM_ID_PROD;
}

export class Intercom {
    static hideWidget() {
        window.Intercom("update", { hide_default_launcher: true });
    }

    static async registerUser(account = {}) {
        const { user } = account;
        try {
            const { json } = await SandboxxRestAPI.getIntercomHash();
            window.Intercom("update", {
                app_id: intercomAppId(),
                Branch: user?.branchId,
                email: user?.email,
                name: user?.fullName,
                Rank: user?.rank,
                Role: user?.role,
                user_id: user?.id,
                user_hash: json?.intercom_hash,
            });
        } catch (err) {
            window.Intercom("update", {
                app_id: intercomAppId(),
                Branch: user?.branchId,
                email: user?.email,
                name: user?.fullName,
                Rank: user?.rank,
                Role: user?.role,
                user_id: user?.id,
            });
        }
    }

    static showNewMessage(content = "") {
        window.Intercom("showNewMessage", content);
    }

    static showWidget() {
        window.Intercom("update", { hide_default_launcher: false });
    }

    static shutdown() {
        window.Intercom("shutdown");
    }

    static trackEvent(eventName, metaData) {
        window.Intercom("trackEvent", eventName, metaData);
    }

    static update() {
        window.Intercom("update");
    }

    static updateUser(currentUser) {
        window.Intercom("update", {});
    }
}
