import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { AuthContext } from "context/auth/auth";
import { PhoneVerificationContext } from "context/phoneVerification";
import { NotificationBarContext } from "context/notificationBar";

// Local
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Models
import { Contact } from "models/contacts/Contact";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const usePhoneVerificationModal = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const { fetchAccount } = useContext(AuthContext);
    const {
        phoneVerificationModalSetting,
        showPhoneVerificationModal,
        togglePhoneVerificationModal,
    } = useContext(PhoneVerificationContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [connection, setConnection] = useState([]);
    const [currentSection, setCurrentSection] = useState("phoneNumber");
    const [loading, setLoading] = useState({
        phoneNumber: false,
        verificationCode: false,
    });
    const [phoneNumber, setPhoneNumber] = useState({
        baseNumber: "",
        countryCode: "",
        isValid: false,
    });
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationType] = useState("sms");

    /**
     * End Hooks & Constants
     */

    async function fetchVerificationCode() {
        try {
            const payload = { phoneNumber, via: verificationType };
            const { json } =
                await SandboxxRestAPI.generatePhoneVerificationCode(payload);
            onGenerateCodeSuccess(json);
        } catch (err) {
            onGenerateCodeError(err);
        }
    }

    function handleChangePhoneNumber(isValid, value, countryData) {
        const { dialCode } = countryData;
        setPhoneNumber((prev) => ({
            ...prev,
            baseNumber: value,
            countryCode: dialCode,
            isValid,
        }));
    }

    function handleChangeVerificationCode(value) {
        const formattedValue = value.join("");
        setVerificationCode(formattedValue);
    }

    function handleClearPhoneNumber() {
        const { dialCode } = arguments[1];
        setPhoneNumber((prev) => ({
            ...prev,
            baseNumber: "",
            countryCode: dialCode,
            isValid: false,
        }));
    }

    function handleCloseModal() {
        togglePhoneVerificationModal(false);
        setTimeout(() => {
            setCurrentSection("phoneNumber");
        }, 1000);
    }

    function handleGoToLetterCompose() {
        const contact = new Contact(connection);
        const isContactInstance = contact instanceof Contact;
        if (isContactInstance) {
            localStorage.setItem(
                "sandboxxMessageRecipient",
                JSON.stringify(contact.generateContactObject())
            );
        }
        history.go(0);
    }

    function handleSubmitPhoneNumber(e) {
        e && e.preventDefault();
        setLoading((prev) => ({ ...prev, phoneNumber: true }));
        if (phoneNumber.isValid) {
            fetchVerificationCode();
        } else {
            setLoading((prev) => ({ ...prev, phoneNumber: false }));
            showFailedValidationNotification();
        }
    }

    async function handleSubmitVerificationCode(e) {
        e && e.preventDefault();
        setLoading((prev) => ({ ...prev, verificationCode: true }));
        try {
            const { json } = await SandboxxRestAPI.checkPhoneVerificationCode({
                verificationCode,
            });
            onCheckVerificationCodeSuccess(json);
        } catch (err) {
            onCheckVerificationCodeError(err);
        }
    }

    function onCheckVerificationCodeError(err) {
        setLoading((prev) => ({ ...prev, verificationCode: false }));
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    async function onCheckVerificationCodeSuccess(res) {
        const { success } = res;
        if (success) {
            fetchAccount();
            try {
                const { json } = await SandboxxRestAPI.checkForOnboardingSkip();
                onCheckForOnboardingSkipSuccess(json);
            } catch (err) {
                onCheckForOnboardingSkipFailure(err);
            }
            AnalyticsLogger.logVerificationConfirmPhone();
        } else {
            showNotification({
                text: t(
                    "phone_verification_modal.check_verification_code_error",
                    { ns: "components" }
                ),
                type: "warning",
            });
            setLoading((prev) => ({ ...prev, verificationCode: false }));
        }
    }

    function onCheckForOnboardingSkipFailure(err) {
        setCurrentSection("complete");
        setLoading((prev) => ({ ...prev, verificationCode: false }));
    }

    function onCheckForOnboardingSkipSuccess(res) {
        const hasConnection = !!(res && res.length);
        if (hasConnection) {
            setConnection(res[0]);
            setCurrentSection("completeConnection");
        } else {
            setCurrentSection("complete");
        }
        setLoading((prev) => ({ ...prev, verificationCode: false }));
    }

    function onGenerateCodeError(err) {
        setLoading((prev) => ({ ...prev, phoneNumber: false }));
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onGenerateCodeSuccess(res) {
        setLoading((prev) => ({ ...prev, phoneNumber: false }));
        if (res.success) {
            AnalyticsLogger.logVerificationEnterPhone();
            setCurrentSection("verificationCode");
        } else {
            showNotification({
                text: t(
                    "phone_verification_modal.generate_verification_code_error",
                    { ns: "components" }
                ),
                type: "warning",
            });
        }
    }

    async function resendVerificationCode() {
        try {
            const payload = { phoneNumber, verificationType };
            const { json } = await SandboxxRestAPI.resendPhoneVerificationCode(
                payload
            );
            onResendVerificationCodeSuccess(json);
        } catch (err) {
            onResendVerificationCodeError(err);
        }
    }

    function onResendVerificationCodeError(err) {
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onResendVerificationCodeSuccess() {
        showNotification({
            text: t(
                "phone_verification_modal.resend_verification_code_success",
                { ns: "components", phoneNumber: phoneNumber.baseNumber }
            ),
            type: "success",
        });
    }

    function showFailedValidationNotification() {
        showNotification({
            text: t("phone_verification_modal.validate_phone_number_error", {
                ns: "components",
            }),
            type: "warning",
        });
    }

    return {
        connection,
        currentSection,
        handleChangePhoneNumber,
        handleChangeVerificationCode,
        handleClearPhoneNumber,
        handleCloseModal,
        handleGoToLetterCompose,
        handleSubmitPhoneNumber,
        handleSubmitVerificationCode,
        loading,
        phoneNumber,
        phoneVerificationModalSetting,
        resendVerificationCode,
        showPhoneVerificationModal,
        togglePhoneVerificationModal,
    };
};
