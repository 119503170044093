import React from "react";
import { Modal } from "components/Modals/Modal";

// Components
import { ProfileDeleteAccountModalConfirm } from "./components/ProfileDeleteAccountModalConfirm";
import { ProfileDeleteAccountModalVerify } from "./components/ProfileDeleteAccountModalVerify";

// CSS
import styles from "./profile-delete-account-modal.module.scss";

// Hooks
import { useProfileDeleteAccountModal } from "./hooks/useProfileDeleteAccountModal";

// Localization
import { useTranslation } from "localization/localization";

export const ProfileDeleteAccountModal = ({
    profileInfo,
    setShouldShowProfileDeleteAccountModal,
    shouldShowProfileDeleteAccountModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        currentSection,
        handleConfirmationToggle,
        handleDeleteAccount,
        hasConfirmedDeleteIntent,
        loading,
        setHasConfirmedDeleteIntent,
        setCurrentSection,
    } = useProfileDeleteAccountModal();

    /**
     * Constants
     */

    const isOnConfirmSection = currentSection === "confirm";

    /**
     * End Hooks
     */

    function getHeaderText(section) {
        if (section === "confirm") {
            return t("delete_account.confirm_title", { ns: "profile" });
        }
        if (section === "verify") {
            return t("delete_account.verify_title", { ns: "profile" });
        }
    }

    function onCloseModal() {
        setShouldShowProfileDeleteAccountModal(false);
        setCurrentSection("verify");
        setHasConfirmedDeleteIntent(false);
    }

    function renderCurrentSection() {
        return {
            confirm: (
                <ProfileDeleteAccountModalConfirm
                    handleConfirmationToggle={handleConfirmationToggle}
                    handleDeleteAccount={handleDeleteAccount}
                    hasConfirmedDeleteIntent={hasConfirmedDeleteIntent}
                    loading={loading}
                    onCloseModal={onCloseModal}
                    profileInfo={profileInfo}
                />
            ),
            verify: (
                <ProfileDeleteAccountModalVerify
                    onCloseModal={onCloseModal}
                    setCurrentSection={setCurrentSection}
                />
            ),
        }[currentSection];
    }

    return (
        <Modal
            centered="true"
            className={styles["profile-account-delete-modal"]}
            cypressTestId="profile-account-delete-modal"
            cypressCloseButtonTestId="profile-account-delete-modal-close-button"
            dialogClassName={isOnConfirmSection && styles["confirm-dialog"]}
            headerText={getHeaderText(currentSection)}
            onClose={onCloseModal}
            onHide={onCloseModal}
            shouldHideHeader={isOnConfirmSection}
            shouldHideHeaderBorder={isOnConfirmSection}
            show={shouldShowProfileDeleteAccountModal}
            size="md"
        >
            {renderCurrentSection()}
        </Modal>
    );
};
