import React from "react";
import PropTypes from "prop-types";
import { Text } from "components/Text/Text";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Skeleton } from "@mui/material";

// Utils
import { findTimeDifference } from "utils/dateTimeUtils";
import { prettifyTimeUntilCutoff } from "../../utils";

// Localization
import { useTranslation } from "localization/localization";

// CSS
import styles from "./dashboard-letters-time-to-qualify.module.scss";

export const DashboardLettersTimeToQualify = ({ letterCutOffTime }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderTimeToQualifyLabel() {
        if (
            letterCutOffTime?.timeUntilNextCutoff === null ||
            letterCutOffTime?.timeUntilNextCutoff === undefined
        ) {
            return <Skeleton width={150} />;
        }

        const { hours, minutes } = findTimeDifference(
            letterCutOffTime.timeUntilNextCutoff
        );
        const hoursPretty = Math.floor(hours);
        const minutesPretty = Math.floor(minutes);

        return (
            <Text isBold>
                {hoursPretty < 0 || (hours === 0 && minutesPretty < 0)
                    ? t("compose.come_back_later", { ns: "dashboard" })
                    : t("compose.time_to_qualify", { ns: "dashboard" })}
            </Text>
        );
    }

    function renderHoursLeftForNextDay() {
        if (
            letterCutOffTime?.timeUntilNextCutoff === null ||
            letterCutOffTime?.timeUntilNextCutoff === undefined
        ) {
            return <Skeleton width={60} />;
        }

        const formattedTime = prettifyTimeUntilCutoff(
            letterCutOffTime.timeUntilNextCutoff
        );
        return formattedTime ? <Text>{formattedTime}</Text> : null;
    }

    return (
        <div className={styles.dashboardLettersTimeToQualify}>
            <div className={styles.header}>
                <div className={styles.label}>
                    <AccessTimeIcon className={styles.icon} />
                    {renderTimeToQualifyLabel()}
                </div>
                <Text classes={styles.value} isBold>
                    {renderHoursLeftForNextDay()}
                </Text>
            </div>
            <div className={styles.description}>
                <Text classes={styles.text}>
                    {t("compose.next_day_delivery", {
                        ns: "dashboard",
                    })}
                </Text>
                <Text classes={styles.text} isBold>
                    <a
                        className="link"
                        href="https://www.sandboxx.us/blog/when-will-sandboxx-letter-arrive/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t("learn_more", { ns: "common" })}
                    </a>
                </Text>
            </div>
        </div>
    );
};

DashboardLettersTimeToQualify.propTypes = {
    letterCutOffTime: PropTypes.object,
};
