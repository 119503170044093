import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Modal } from "components/Modals/Modal";
import { MusterRecruitInviteModalSubmit } from "./components/MusterRecruitInviteModalSubmit/MusterRecruitInviteModalSubmit";
import { MusterRecruitInviteModalSuccess } from "./components/MusterRecruitInviteSuccess/MusterRecruitInviteSuccess";

// CSS
import styles from "./muster-recruit-invite-modal.module.scss";

// Enums
import { MusterRecruitInviteModalNavigation } from "./enums/MusterRecruitInviteModalNavigation";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Validation
import { Validation } from "validation/validation";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const MusterRecruitInviteModal = (props) => {
    const {
        magicLink,
        setShouldShowRecruitModal,
        shouldShowRecruitModal,
        showNotification,
    } = props;

    /**
     * useState
     **/

    const [currentSection, setCurrentSection] = useState(
        MusterRecruitInviteModalNavigation.SUBMIT
    );
    const [emailErrors, setEmailErrors] = useState([false, false]);
    const [headerText, setHeaderText] = useState("");
    const [headerSubText, setHeaderSubText] = useState("");
    const [inviteEmails, setInviteEmails] = useState(["", ""]);
    const [numberOfEmails, setNumberOfEmails] = useState(2);
    const [isSendingEmails, setIsSendingEmails] = useState(false);

    /**
     * useMemo
     */

    const isEmailInviteButtonDisabled = useMemo(
        () => inviteEmails.every((e) => e === ""),
        [inviteEmails]
    );

    /**
     * End Hooks
     **/

    function handleAddEmail() {
        setNumberOfEmails(numberOfEmails + 1);
        setInviteEmails((prev) => [...prev, ""]);
        setEmailErrors((prev) => [...prev, false]);
    }

    function handleBlur(i) {
        const placeHolderArray = emailErrors;
        if (
            !Validation.isEmailAddress(inviteEmails[i]) &&
            inviteEmails[i] &&
            document.activeElement.name !== `supportSquadEmail-${i}`
        ) {
            placeHolderArray[i] = true;
            setEmailErrors([...placeHolderArray]);
        }
    }

    function handleClearData() {
        setInviteEmails(["", ""]);
        setNumberOfEmails(2);
    }

    function handleEmailChange(index, e) {
        const placeHolderArray = inviteEmails;
        placeHolderArray[index] = e.target.value;
        setInviteEmails([...placeHolderArray]);
    }

    function handleFocus(i) {
        const placeHolderArray = emailErrors;
        placeHolderArray[i] = false;
        setEmailErrors([...placeHolderArray]);
    }

    function handleModalClose() {
        setShouldShowRecruitModal(false);
        setTimeout(() => {
            setCurrentSection(MusterRecruitInviteModalNavigation.SUBMIT);
            handleClearData();
        }, 1000);
    }

    async function handleSubmitEmailInvites(e) {
        e.preventDefault();
        setIsSendingEmails(true);
        AnalyticsLogger.logMagicLinkShared();
        const validation = validateBeforeSubmit();
        if (validation) {
            try {
                const nonEmptyEmails = inviteEmails.filter((element) => {
                    return element !== "";
                });
                const { json } =
                    await SandboxxRestAPI.submitMusterRecruitEmailInvites({
                        emailList: nonEmptyEmails,
                    });
                onSubmitEmailInviteSuccess(json);
            } catch (err) {
                onSubmitEmailInviteFailure(err);
            }
        } else {
            showFailedValidationNotification();
            setIsSendingEmails(false);
        }
    }

    function onSubmitEmailInviteFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIsSendingEmails(false);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onSubmitEmailInviteSuccess(nonEmptyEmails) {
        nonEmptyEmails.forEach((email) =>
            AnalyticsLogger.logMusterInvitationSent(email)
        );
        setCurrentSection(MusterRecruitInviteModalNavigation.SUCCESS);
        setIsSendingEmails(false);
    }

    function showFailedValidationNotification() {
        showNotification({
            text: "One or more of the emails entered is invalid.",
            type: "warning",
        });
    }

    function validateBeforeSubmit() {
        return emailErrors.every((e) => e === false);
    }

    function renderModalContent() {
        switch (currentSection) {
            case MusterRecruitInviteModalNavigation.SUCCESS:
                return (
                    <MusterRecruitInviteModalSuccess
                        {...props}
                        handleModalClose={handleModalClose}
                        inviteEmails={inviteEmails}
                        setCurrentSection={setCurrentSection}
                        setHeaderSubText={setHeaderSubText}
                        setHeaderText={setHeaderText}
                        setInviteEmails={setInviteEmails}
                    />
                );
            case MusterRecruitInviteModalNavigation.SUBMIT:
            default:
                return (
                    <MusterRecruitInviteModalSubmit
                        {...props}
                        emailErrors={emailErrors}
                        handleAddEmail={handleAddEmail}
                        handleBlur={handleBlur}
                        handleClearData={handleClearData}
                        handleEmailChange={handleEmailChange}
                        handleFocus={handleFocus}
                        handleModalClose={handleModalClose}
                        handleSubmitEmailInvites={handleSubmitEmailInvites}
                        inviteEmails={inviteEmails}
                        isEmailInviteButtonDisabled={
                            isEmailInviteButtonDisabled
                        }
                        isSendingEmails={isSendingEmails}
                        magicLink={magicLink}
                        numberOfEmails={numberOfEmails}
                        setShouldShowRecruitModal={setShouldShowRecruitModal}
                        setHeaderSubText={setHeaderSubText}
                        setHeaderText={setHeaderText}
                        showNotification={showNotification}
                    />
                );
        }
    }

    return (
        <Modal
            centered={true}
            className={styles["muster-recruit-invite-modal"]}
            cypressCloseButtonTestId="muster-recruit-invite-modal-close-button"
            cypressTestId="muster-recruit-invite-modal"
            dialogClassName={styles["muster-recruit-invite-modal-dialog"]}
            onHide={handleModalClose}
            shouldHideHeader={!headerText}
            headerSubText={headerSubText}
            headerText={headerText}
            show={shouldShowRecruitModal}
            size={
                currentSection === MusterRecruitInviteModalNavigation.SUCCESS
                    ? "sm"
                    : ""
            }
        >
            {renderModalContent()}
        </Modal>
    );
};

MusterRecruitInviteModal.propTypes = {
    magicLink: PropTypes.string,
    setShouldShowRecruitModal: PropTypes.func.isRequired,
    shouldShowRecruitModal: PropTypes.bool.isRequired,
    showNotification: PropTypes.func,
};
