import { useContext } from "react";
import { DateTime } from "luxon";

// Context
import { CartContext } from "context/cart/cart";

export const useCartConfirmation = ({ resetCartNavigation }) => {
    /**
     * useContext
     */

    const { completedOrder, hideCart } = useContext(CartContext);

    /**
     * Constants
     */

    const orderPlacedDateTime = DateTime.fromISO(
        completedOrder.updated_at
    ).toLocaleString(DateTime.DATETIME_FULL);

    /**
     * End Hooks & Constants
     */

    function closeCartAfterConfirmation() {
        hideCart();
        resetCartNavigation();
    }

    return { closeCartAfterConfirmation, completedOrder, orderPlacedDateTime };
};
