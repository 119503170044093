import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSpring } from "react-spring";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { AddOnsNavigation } from "scenes/Letters/scenes/Compose/enums/enums";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { isEmptyObject } from "utils/objectUtils";
import { isAddOnVariantAlreadySelected } from "scenes/Letters/utils/addOnsUtils";

export const useLettersAddOnsCarouselItem = ({
    addOn,
    addOns,
    giftCard,
    handleAddOnsModalToggle,
    handleRemoveAddOn,
    handleSelectAddOn,
    handleGiftCardClear,
    position,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useRef
     */

    const itemRef = useRef(null);

    /**
     * useState
     */

    const [imageSrc, setImageSrc] = useState(null);
    const [itemWidth, setItemWidth] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        addOn.imageUrl && fetchLetterImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (itemRef) {
            setItemWidth(itemRef.current.offsetWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemRef]);

    /**
     * useMemo
     */

    const shouldDisableButton = useMemo(
        () => isAddOnVariantAlreadySelected({ addOns, item: addOn }),
        [addOns, addOn]
    );

    /**
     * Constants
     */

    const hasGiftCard = !isEmptyObject(giftCard.selected);
    const isGiftCard = addOn.productType === "GIFTCARD";
    const offset = position * 40;

    const { left: itemLeft } = useSpring({
        left: 0 - position * itemWidth - offset,
    });

    /**
     * End Hooks
     */

    async function fetchLetterImage() {
        try {
            const json = await SandboxxRestAPI.getImageCloudfront(
                addOn.imageUrl
            );
            setImageSrc(json);
        } catch (err) {}
    }

    function handleOptionOnClick() {
        if (isGiftCard) {
            if (hasGiftCard) {
                handleGiftCardClear();
                showNotification({
                    text: t("gift_card_remove_from_cart_success", {
                        ns: "letters",
                    }),
                    type: "info",
                });
            } else {
                AnalyticsLogger.logGiftCardStart();
                handleAddOnsModalToggle(true, {
                    section: AddOnsNavigation.SELECT_DESIGN,
                });
            }
        } else {
            if (addOn.isSelected) {
                handleRemoveAddOn(addOn);
                showNotification({
                    text: t("add_on_remove_from_cart_success", {
                        ns: "letters",
                        title: addOn.title,
                    }),
                    type: "info",
                });
            } else {
                handleSelectAddOn(addOn);
                showNotification({
                    text: t("add_on_add_to_cart_success", {
                        ns: "letters",
                        title: addOn.title,
                    }),
                    type: "success",
                });
            }
        }
    }

    return {
        handleOptionOnClick,
        hasGiftCard,
        imageSrc,
        isGiftCard,
        itemLeft,
        itemRef,
        shouldDisableButton,
    };
};
