export const NavigationEnum = Object.freeze({
    /**
     * LOG IN
     */

    SIGN_IN_MAIN: "SIGN_IN_MAIN",

    /**
     * SIGN UP
     */

    SIGN_UP_CONFIRM_PHONE: "SIGN_UP_CONFIRM_PHONE",
    SIGN_UP_CONNECTED: "SIGN_UP_CONNECTED",
    SIGN_UP_NAME: "SIGN_UP_NAME",
    SIGN_UP_ONBOARDING_INTRO: "SIGN_UP_ONBOARDING_INTRO",
    SIGN_UP_PERSONA_CHILD: "SIGN_UP_PERSONA_CHILD",
    SIGN_UP_PERSONA_ROOT: "SIGN_UP_PERSONA_ROOT",
    SIGN_UP_PHONE: "SIGN_UP_PHONE",
    SIGN_UP_RECRUITING_STATION: "SIGN_UP_RECRUITING_STATION",
    SIGN_UP_SHIP_DATE: "SIGN_UP_SHIP_DATE",
    SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
});
