import React, { createContext, useContext, useEffect, useState } from "react";

// Context
import { AuthContext } from "context/auth/auth";

// Models
import { Contact } from "../models/contacts/Contact";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { generateFullName } from "utils/userUtils";

export const ContactsContext = createContext({});

export const ContactsProvider = (props) => {
    const { children } = props;

    /**
     * useContext
     **/

    const { isLoggingOut } = useContext(AuthContext);

    /**
     * useState
     **/

    const [contactModal, setContactModal] = useState({
        contactToEdit: {
            firstName: "",
            fullName: "",
            lastName: "",
            title: "",
            address: {
                line1: "",
                line2: "",
                city: "",
                state: "",
                zipcode: "",
            },
        },
        isSupportSquad: false,
        isWritingLetter: false,
        shouldShow: false,
        shouldShowBackButton: false,
        targetScreen: null,
        type: "edit",
    });
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState({
        contacts: false,
        recentContacts: false,
    });
    const [onSubmit, setOnSubmit] = useState(() => emptyFunction);
    const [
        onSubmitContactSuccessFinalCallback,
        setOnSubmitContactSuccessFinalCallback,
    ] = useState(() => emptyFunction);
    const [onBackButtonClickCallback, setOnBackButtonClickCallback] = useState(
        () => emptyFunction
    );
    const [recentContacts, setRecentContacts] = useState([]);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (isLoggingOut) {
            resetContactsContext();
        }
    }, [isLoggingOut]);

    /**
     * End Hooks
     **/

    function resetContactsContext() {
        setContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            isSupportSquad: false,
            isWritingLetter: false,
            shouldShow: false,
            shouldShowBackButton: false,
            type: "edit",
        });
        setContacts([]);
        setLoading({
            contacts: false,
            recentContacts: false,
        });
        setRecentContacts([]);
    }

    async function createContact(
        contact,
        callback,
        onFailure,
        { shouldReverseFullName }
    ) {
        try {
            const contactPayload = {
                ...contact,
                fullName: generateFullName(contact, shouldReverseFullName),
            };
            const { json } = await SandboxxRestAPI.createAddressBookContact(
                contactPayload
            );
            callback(json);
        } catch (err) {
            onFailure(err);
        }
    }

    async function deleteContact(contact, callback, onFailure) {
        try {
            const { json } = await SandboxxRestAPI.deleteAddressBookContact(
                contact
            );
            callback(json);
        } catch (err) {
            onFailure(err);
        }
    }

    async function editContact(contact, callback, onFailure) {
        try {
            const { json } = await SandboxxRestAPI.editAddressBookContact(
                contact
            );
            callback(json);
        } catch (err) {
            onFailure(err);
        }
    }

    async function fetchContact(id, callback, onFailure) {
        try {
            const { json } = await SandboxxRestAPI.getAddressBookContact(id);
            callback(json);
        } catch (err) {
            onFailure(err);
        }
    }

    async function fetchContacts() {
        try {
            setLoading((prev) => ({ ...prev, contacts: true }));
            const { json } = await SandboxxRestAPI.getAddressBookContacts();
            const contacts = json.map((contact) => new Contact(contact));
            setContacts(contacts);
            setLoading((prev) => ({ ...prev, contacts: false }));
        } catch (err) {
            setLoading((prev) => ({ ...prev, contacts: false }));
        }
    }

    async function fetchRecentContacts() {
        try {
            setLoading((prev) => ({ ...prev, recentContacts: true }));
            const { json } =
                await SandboxxRestAPI.getAddressBookRecentContacts();
            const recentContacts = json.map((contact) => new Contact(contact));
            setRecentContacts(recentContacts);
            setLoading((prev) => ({
                ...prev,
                recentContacts: false,
            }));
        } catch (err) {}
    }

    function toggleContactModal({
        contactToEdit,
        isSupportSquad,
        isWritingLetter,
        shouldShow,
        shouldShowBackButton,
        targetScreen,
        type,
    }) {
        setContactModal((prev) => ({
            ...prev,
            contactToEdit: contactToEdit || prev.contactToEdit,
            isSupportSquad,
            isWritingLetter,
            shouldShow,
            shouldShowBackButton,
            targetScreen: targetScreen || null,
            type: type || prev.type,
        }));
    }

    const contactsContext = {
        contactModal,
        contacts,
        createContact,
        deleteContact,
        editContact,
        fetchContact,
        fetchContacts,
        fetchRecentContacts,
        loading,
        onBackButtonClickCallback,
        onSubmit,
        onSubmitContactSuccessFinalCallback,
        recentContacts,
        resetContactsContext,
        setOnBackButtonClickCallback,
        setOnSubmit,
        setOnSubmitContactSuccessFinalCallback,
        toggleContactModal,
    };

    return (
        <ContactsContext.Provider value={contactsContext}>
            {children}
        </ContactsContext.Provider>
    );
};
