import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LetterPreview } from "components/LetterPreview/LetterPreview";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-sent-letters.module.scss";

// Enums
import { LetterType } from "enums/LetterType";

// Localization
import { useTranslation } from "localization/localization";

// Models
import { SentLetter } from "models/letters/SentLetter";

export const DashboardSentLetters = ({
    history,
    lettersSent,
    lettersSentCursor,
}) => {
    /**
     * Custom Hooks
     **/

    const { t } = useTranslation();

    /**
     * useState
     **/

    const [letters, setLetters] = useState({
        list: lettersSent,
        type: LetterType.SENT,
    });

    /**
     * useEffect
     **/

    useEffect(() => {
        if (letters.list === null) {
            setLetters((prev) => ({
                ...prev,
                list: lettersSent,
                type: LetterType.SENT,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lettersSent]);

    /**
     * End Hooks
     **/

    function renderContent() {
        if (letters.list === null) {
            return renderLoading();
        }
        if (letters.list && letters.list.length) {
            return renderLetters();
        }
        if (letters.list && !letters.list.length) {
            return renderEmptyState();
        }
    }

    function renderEmptyState() {
        return (
            <div className={styles.empty}>
                <span>
                    {t("letters.sent_letters_empty", { ns: "dashboard" })}
                </span>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div className={styles.list}>
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={true}
                    history={history}
                    key={0}
                />
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={true}
                    history={history}
                    key={1}
                />
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={true}
                    history={history}
                    key={2}
                />
                {renderShowAll()}
            </div>
        );
    }

    function renderLetters() {
        const items = letters.list.slice(0, 3).map((letter, key) => {
            const linkParams = {
                pathname: `/letters/sent/${letter.id}`,
                state: { letter, letterType: letters.type },
            };
            return (
                <LetterPreview
                    className={styles.letterPreview}
                    isLoading={false}
                    key={letter.id}
                    letter={letter}
                    letterType={letters.type}
                    linkParams={linkParams}
                />
            );
        });
        return (
            <div className={styles.list}>
                {items}
                {renderShowAll()}
            </div>
        );
    }

    function renderShowAll() {
        return (
            <Link
                data-cy="dashboard-sent-letters-show-all"
                to={{
                    pathname: "/letters/sent",
                    state: {
                        cursorSent: lettersSentCursor,
                        lettersSent,
                    },
                }}
            >
                <div className={styles.showAll}>
                    <Text>{t("show_all", { ns: "common" })}</Text>
                    <ChevronRightIcon className={styles.icon} />
                </div>
            </Link>
        );
    }

    return (
        <Panel classes={styles.dashboardSentLetters}>
            <div className="panel__header">
                <span className="panel__header__text">
                    {t("history", { ns: "common" }).toUpperCase()}
                </span>
            </div>
            {renderContent()}
        </Panel>
    );
};

DashboardSentLetters.propTypes = {
    history: PropTypes.object,
    lettersSent: PropTypes.arrayOf(PropTypes.instanceOf(SentLetter)),
    moreLettersSentCursor: PropTypes.string,
};
