import { useEffect, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Constants
import { WalletListTabIds } from "scenes/Wallet/constants/constants";
const { MY_GIFT_CARDS } = WalletListTabIds;

export const useWalletManageListCard = ({
    card,
    selectedGiftCard,
    selectedWalletListTabId,
}) => {
    /**
     * useState
     */

    const [imageSrc, setImageSrc] = useState("");

    /**
     * useEffect
     */

    // Fetch full gift card image once the imageUrl is available
    useEffect(() => {
        card.imageUrl && fetchGiftCardImage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card]);

    /**
     * Constants
     */

    const isSelected =
        selectedWalletListTabId === MY_GIFT_CARDS &&
        card.giftCardNumber === selectedGiftCard.giftCardNumber;

    /**
     * End Hooks
     */

    async function fetchGiftCardImage() {
        try {
            const json = await SandboxxRestAPI.getImageCloudfront(
                card.imageUrl,
                { fileType: "png" }
            );
            setImageSrc(json);
        } catch (err) {}
    }

    return { imageSrc, isSelected };
};
