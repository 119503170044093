import { useContext, useState } from "react";

// Context
import { AuthContext } from "context/auth/auth";

export const useAuthModalLogout = ({ setShouldShowLogOutModal }) => {
    /**
     * useContext
     **/

    const { signOut } = useContext(AuthContext);

    /**
     * useState
     */

    const [isLoading, setIsLoading] = useState(false);

    /**
     * End Hooks & Constants
     */

    function closeLogOutModal() {
        setShouldShowLogOutModal(false);
    }

    async function handleLogout() {
        setIsLoading(true);
        await signOut();
        setIsLoading(false);
    }

    return { closeLogOutModal, handleLogout, isLoading };
};
