import { useCallback } from "react";

export const useFetchRetry = () => {
    const fetchWithRetry = useCallback(
        async (
            fetchFn,
            {
                maxRetries = 3,
                retryDelay = 1000,
                onSuccess = null,
                onError = null,
            } = {}
        ) => {
            for (let attempt = 1; attempt <= maxRetries; attempt++) {
                try {
                    const result = await fetchFn();
                    if (onSuccess) {
                        onSuccess(result);
                    }
                    return result;
                } catch (error) {
                    if (attempt === maxRetries) {
                        if (onError) {
                            onError(error);
                        }
                        throw error;
                    }
                    await new Promise((resolve) =>
                        setTimeout(resolve, retryDelay)
                    );
                }
            }
        },
        []
    );
    return { fetchWithRetry };
};
