import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Context
import { AuthContext } from "context/auth/auth";

// Utils
import { generateRedirectData } from "utils/routeUtils";

export const useProtectedPage = () => {
    /**
     * Routing
     */

    const history = useHistory();

    /**
     * useContext
     */

    const { account, isAuthenticated, isInitializing, signOut } =
        useContext(AuthContext);

    /**
     * useEffect
     */

    useEffect(() => {
        // If not authenticated, sign out
        if (!isInitializing && !isAuthenticated) {
            signOut();
        }

        // If user is authenticated and account data is available, check if redirect is required
        if (account && !isInitializing && isAuthenticated) {
            const redirectData = generateRedirectData({ account });
            if (redirectData) {
                history.push(redirectData.pathname);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, isAuthenticated, isInitializing, signOut]);

    /**
     * End Hooks
     */

    return { account, isAuthenticated, isInitializing };
};
