import { useEffect, useState } from "react";

// Hooks
import { useRownd } from "@rownd/react";

// Utils
import { SessionManager } from "interfaces/auth/SessionManager";

export const useAuthRownd = () => {
    /**
     * Custom Hooks
     */

    const {
        events,
        getAccessToken,
        is_authenticated,
        is_initializing,
        requestSignIn,
        signOut,
    } = useRownd();

    /**
     * useState
     */

    const [hasInitializedHook, setHasInitializedHook] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        if (!hasInitializedHook) {
            initializeAccessTokenManager();
            initializeEvents();
            setHasInitializedHook(true);
        }

        // Cleanup function
        return () => {
            events.removeEventListener("auth");
            events.removeEventListener("sign_in_completed");
            events.removeEventListener("sign_in_failed");
            events.removeEventListener("sign_out");
            events.removeEventListener("user_data");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasInitializedHook]);

    useEffect(() => {
        SessionManager.setIsAuthenticated(is_authenticated);
    }, [is_authenticated]);

    /**
     * End Hooks
     */

    function initializeAccessTokenManager() {
        SessionManager.setAccessTokenFn(() =>
            getAccessToken({ waitForToken: true })
        );
        SessionManager.setIsAuthenticated(is_authenticated);
        SessionManager.setSignOutFn(signOut);
    }

    function initializeEvents() {
        events.addEventListener("auth", ({ detail }) => {});
        events.addEventListener("sign_in_completed", async ({ detail }) => {
            const { app_variant_user_type } = detail;
            if (app_variant_user_type === "existing_user") {
                setIsLoggingIn(true);
            }
            if (app_variant_user_type === "new_user") {
                setIsSigningUp(true);
            }
        });
        events.addEventListener("sign_in_failed", ({ detail }) => {});
        events.addEventListener("sign_out", () => {
            setIsLoggingOut(true);
        });
        events.addEventListener("user_data", ({ detail }) => {});
    }

    return {
        getAccessToken,
        isAuthenticated: is_authenticated,
        isInitializing: is_initializing,
        isLoggingIn,
        isLoggingOut,
        isSigningUp,
        requestSignIn,
        setIsLoggingIn,
        setIsLoggingOut,
        setIsSigningUp,
        signOut,
    };
};
