import React, { useContext } from "react";
import cx from "classnames";

// Components
import { SignInMain } from "./scenes/SignIn/SignInMain/SignInMain";
import { SignInSlideshow } from "./SignInSlideshow";
import { SignUpConfirmPhone } from "./scenes/SignUp/SignUpConfirmPhone/SignUpConfirmPhone";
import { SignUpConnected } from "./scenes/SignUp/SignUpConnected/SignUpConnected";
import { SignUpName } from "./scenes/SignUp/SignUpName/SignUpName";
import { SignUpPersonaChild } from "./scenes/SignUp/SignUpPersonaChild/SignUpPersonaChild";
import { SignUpPersonaRoot } from "./scenes/SignUp/SignUpPersonaRoot/SignUpPersonaRoot";
import { SignUpPhone } from "./scenes/SignUp/SignUpPhone/SignUpPhone";
import { SignUpRecruitingStation } from "./scenes/SignUp/SignUpRecruitingStation/SignUpRecruitingStation";
import { SignUpShipDate } from "./scenes/SignUp/SignUpShipDate/SignUpShipDate";
import { SignUpSuccess } from "./scenes/SignUp/SignUpSuccess/SignUpSuccess";
import { SignInWelcome } from "scenes/SignIn/components/SignInWelcome/SignInWelcome";
import { Skeleton } from "@mui/material";

// Context
import { DepsContext } from "context/deps";
import { NavBarContext } from "context/navbar";
import { NotificationBarContext } from "../../context/notificationBar";

// CSS
import styles from "./sign-in.module.scss";

// Enums
import { NavigationEnum } from "./constants/constants";
import { RightColumnMode } from "./enums/RightColumnMode";

// Hooks
import { usePersonaWizard } from "hooks/wizards/usePersonaWizard/usePersonaWizard";
import { useSignIn } from "./hooks/useSignIn";
import { useSignInNavigation } from "./hooks/useSignInNavigation";
import { useSignInUserInfo } from "./hooks/useSignInUserInfo";
import { useSignUp } from "./hooks/useSignUp";

// Localization
import { useTranslation } from "localization/localization";

// Media
import sandboxxHorizontalLogo from "media/logos/sandboxx-horizontal.svg";

export const SignIn = (props) => {
    /**
     * useContext
     */

    const { depsLocations, fetchDepsLocations } = useContext(DepsContext);
    const { runShouldShowNavBarCheck } = useContext(NavBarContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useSignInNavigation
     * -------------------
     * Custom hook for managing navigation state
     */

    const { currentSection, hasInitializedNavigation, setCurrentSection } =
        useSignInNavigation(props);

    /**
     * useSignInUserInfo
     * -------------------
     * Custom hook for managing userInfo state
     */

    const {
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        setUserInfo,
        userInfo,
    } = useSignInUserInfo(props);

    /**
     * useSignIn
     * ---------
     * Custom hook for general state management
     */

    const {
        handleChangeVerificationCode,
        rightColumnMode,
        setRightColumnMode,
        verificationCode,
    } = useSignIn({
        runShouldShowNavBarCheck,
        setUserInfo,
    });

    /**
     * useSignUp
     * ---------
     * Custom hook for managing state in the SignUp flow
     */

    const {
        connections,
        handleLoadingToggle,
        handleUpdateName,
        loading,
        setConnections,
    } = useSignUp({
        ...props,
        setCurrentSection,
        showNotification,
        userInfo,
        setUserInfo,
    });

    /**
     * usePersonaWizard
     * ----------------
     * Custom hook for determining user's persona
     */

    const {
        childPersonas,
        fetchRootPersonas,
        handleGoBackToPersonaSelection,
        handleSelectRootPersona,
        handleSelectChildPersona,
        isLoadingChildPersonas,
        isLoadingRootPersonaSelection,
        rootPersonas,
        selectedRootPersona,
        setIsLoadingRootPersonaSelection,
    } = usePersonaWizard({
        ...props,
        setCurrentSection,
        setUserInfo,
    });

    /**
     * End Hooks
     */

    function renderCurrentSection() {
        return {
            [NavigationEnum.SIGN_IN_MAIN]: <SignInMain />,
            [NavigationEnum.SIGN_UP_CONFIRM_PHONE]: (
                <SignUpConfirmPhone
                    handleChangeVerificationCode={handleChangeVerificationCode}
                    handleLoadingToggle={handleLoadingToggle}
                    loading={loading}
                    setConnections={setConnections}
                    setCurrentSection={setCurrentSection}
                    userInfo={userInfo}
                    verificationCode={verificationCode}
                />
            ),
            [NavigationEnum.SIGN_UP_CONNECTED]: (
                <SignUpConnected
                    connections={connections}
                    setCurrentSection={setCurrentSection}
                />
            ),
            [NavigationEnum.SIGN_UP_NAME]: (
                <SignUpName
                    handleUpdateName={handleUpdateName}
                    loading={loading}
                    setUserInfo={setUserInfo}
                    showNotification={showNotification}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_PERSONA_CHILD]: (
                <SignUpPersonaChild
                    childPersonas={childPersonas}
                    handleGoBackToPersonaSelection={
                        handleGoBackToPersonaSelection
                    }
                    handleSelectChildPersona={handleSelectChildPersona}
                    isLoadingChildPersonas={isLoadingChildPersonas}
                    selectedRootPersona={selectedRootPersona}
                    setCurrentSection={setCurrentSection}
                    setUserInfo={setUserInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_PERSONA_ROOT]: (
                <SignUpPersonaRoot
                    connections={connections}
                    fetchRootPersonas={fetchRootPersonas}
                    handleSelectRootPersona={handleSelectRootPersona}
                    isLoadingRootPersonaSelection={
                        isLoadingRootPersonaSelection
                    }
                    rootPersonas={rootPersonas}
                    setConnections={setConnections}
                    setCurrentSection={setCurrentSection}
                    setIsLoadingRootPersonaSelection={
                        setIsLoadingRootPersonaSelection
                    }
                    setRightColumnMode={setRightColumnMode}
                    setUserInfo={setUserInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_PHONE]: (
                <SignUpPhone
                    handleChangePhoneNumber={handleChangePhoneNumber}
                    handleClearPhoneNumber={handleClearPhoneNumber}
                    handleLoadingToggle={handleLoadingToggle}
                    loading={loading}
                    setCurrentSection={setCurrentSection}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_RECRUITING_STATION]: (
                <SignUpRecruitingStation
                    depsLocations={depsLocations}
                    fetchDepsLocations={fetchDepsLocations}
                    handleGoBackToPersonaSelection={
                        handleGoBackToPersonaSelection
                    }
                    setCurrentSection={setCurrentSection}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_SHIP_DATE]: (
                <SignUpShipDate
                    handleLoadingToggle={handleLoadingToggle}
                    loading={loading}
                    setCurrentSection={setCurrentSection}
                    setUserInfo={setUserInfo}
                    showNotification={showNotification}
                    userInfo={userInfo}
                />
            ),
            [NavigationEnum.SIGN_UP_SUCCESS]: (
                <SignUpSuccess
                    runShouldShowNavBarCheck={runShouldShowNavBarCheck}
                    selectedRootPersona={selectedRootPersona}
                    userInfo={userInfo}
                />
            ),
        }[currentSection];
    }

    function renderLoading() {
        return (
            <div className={cx(styles.content, styles.loading)}>
                <Skeleton
                    className={styles.skeleton}
                    height={26}
                    style={{ marginBottom: "48px" }}
                    width="80%"
                />
                <Skeleton
                    className={styles.skeleton}
                    height={64}
                    style={{ borderRadius: "32px", marginBottom: "48px" }}
                    width="100%"
                />
                <Skeleton
                    className={styles.skeleton}
                    height={12}
                    style={{ marginBottom: "8px" }}
                    width="90%"
                />
                <Skeleton
                    className={styles.skeleton}
                    height={12}
                    style={{ marginBottom: "24px" }}
                    width="20%"
                />
                <Skeleton className={styles.skeleton} height={12} width="85%" />
            </div>
        );
    }

    function renderSandboxxLogo() {
        return (
            <div className={styles.mainHeader}>
                <img
                    alt={t("sandboxx_logo", { ns: "common" })}
                    className={styles.logo}
                    src={sandboxxHorizontalLogo}
                />
            </div>
        );
    }

    function renderRightColumn() {
        if (rightColumnMode === RightColumnMode.SLIDESHOW) {
            return <SignInSlideshow />;
        } else if (rightColumnMode === RightColumnMode.WELCOME) {
            return <SignInWelcome />;
        }
    }

    return (
        <div className={styles["sign-in"]}>
            <div className={styles.grid}>
                <div className={styles.gridContent}>
                    {renderSandboxxLogo()}
                    {hasInitializedNavigation
                        ? renderCurrentSection()
                        : renderLoading()}
                </div>
                {renderRightColumn()}
            </div>
        </div>
    );
};
