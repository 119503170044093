import React from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";
import { TextFieldValidated } from "components/Inputs/TextFieldValidated/TextFieldValidated";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpNameStyles from "./sign-up-name.module.scss";

// Hooks
import { useSignUpName } from "./hooks/useSignUpName";

// Localization
import { useTranslation } from "localization/localization";

export const SignUpName = ({
    handleUpdateName,
    loading,
    setUserInfo,
    showNotification,
    userInfo,
}) => {
    const { firstName, lastName } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const { handleSubmit, hasValidFirstName, hasValidForm, hasValidLastName } =
        useSignUpName({ handleUpdateName, showNotification, userInfo });

    /**
     * End Hooks
     */

    function handleChange({ target }) {
        const { name, value } = target;
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    }

    function renderForm() {
        return (
            <form
                className={cx(signInStyles.form, signUpNameStyles.form, "form")}
                onSubmit={handleSubmit}
            >
                <input type="submit" hidden />
                <TextFieldValidated
                    autoFocus={true}
                    className={signUpNameStyles.input}
                    cypressTestId="sign-up-name-first-name-input"
                    id="firstName"
                    isValid={hasValidFirstName}
                    label={t("first_name", { ns: "common" })}
                    name="firstName"
                    onChange={handleChange}
                    value={firstName}
                />
                <TextFieldValidated
                    className={signUpNameStyles.input}
                    cypressTestId="sign-up-name-last-name-input"
                    id="lastName"
                    isValid={hasValidLastName}
                    label={t("last_name", { ns: "common" })}
                    name="lastName"
                    onChange={handleChange}
                    value={lastName}
                />
                <ButtonPrimary
                    classes={signInStyles.button}
                    cypressTestId="sign-up-name-next-button"
                    isDisabled={!hasValidForm}
                    isLoading={loading.name}
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                >
                    <Text>{t("sign_up", { ns: "common" })}</Text>
                </ButtonPrimary>
            </form>
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpNameStyles["sign-up-name"]
            )}
        >
            <div className={signInStyles.top}>
                <div className={signInStyles.header}>
                    <span
                        className={signInStyles.text}
                        data-cy="sign-in-content-top-header-text"
                    >
                        {t("sign_up_name.title", { ns: "onboarding" })}
                    </span>
                    <span className={signInStyles["text-secondary"]}>
                        {t("sign_up_name.subtitle", { ns: "onboarding" })}
                    </span>
                </div>
                {renderForm()}
            </div>
        </div>
    );
};
