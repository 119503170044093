// Constants
export const DATES_ASSUMED = true;
export const DATES_EXPLICIT = false;

/*************************************************************************/
/****************************** URL Bases ********************************/
/*************************************************************************/

export const DEV =
    process.env.NODE_ENV === "development"
        ? true
        : process.env.REACT_APP_WEB_ENV === "dev";
export const PRODBASE = "https://api.v2.sandboxx.us";
export const DEVBASE = "https://api-stage.sandboxx.us";

export const publicBase = process.env.PUBLIC_URL;
export const restBase = DEV ? `${DEVBASE}/rest` : `${PRODBASE}/rest`;

/*************************************************************************/
/******************************** URLs ***********************************/
/*************************************************************************/

/** Images **/

export const imagePublicBase = "https://dkpdw0r5j9hhy.cloudfront.net";

export function imageUrl() {
    return `${restBase}/media/i/`;
}

export function formatURLForDev(url) {
    if (!isExternalURL(url)) {
        return stripOriginFromURL(url);
    } else {
        return url;
    }
}

/**
 * Checks if the provided URL links to the app or an external site. When in dev/stage,
 * if the URL points to a Sandboxx property then the link will be marked as internal.
 *
 * @param {String} url
 * @returns {Boolean} indicating whether the URL is an external link
 */
export function isExternalURL(url = "") {
    try {
        if (!url) return false;

        // Marks as internal link in dev/staging if URL contains a Sandboxx pattern
        if (DEV) {
            if (isSandboxxEnvironmentURL(url)) {
                return false;
            }
        }

        // Marks as internal link if URL is relative
        if (url?.startsWith("/")) return false;

        // Checks if the link is external by comparing the link's host to the window's host
        const link = new URL(url);
        return link.hostname !== window.location.hostname;
    } catch (err) {}
}

/**
 * Determines whether the URL is a Sandboxx deep link (environment-agnostic) that points
 * to a location in the app
 *
 * @param {String} url
 * @returns {Boolean}
 */
export function isSandboxxEnvironmentURL(url = "") {
    try {
        return (
            url?.includes("app.sandboxx.us") ||
            url?.includes("sandboxx://") ||
            url?.includes("app-dev.sandboxx.us")
        );
    } catch (err) {}
}

/**
 * Retrieves URL query parameters from localStorage
 *
 * @returns {Object} that contains query params stored in the browser's localStorage
 */
export function getURLQueryParamsFromStorage() {
    return JSON.parse(localStorage.getItem("urlQueryParams"));
}

/**
 * Retrieves the user's intended destination path in localStorage
 */
export function getEntryPathFromStorage() {
    return localStorage.getItem("entryPath");
}

/**
 * Saves the user's intended destination path in localStorage
 */
export function setEntryPathinStorage() {
    if (!getEntryPathFromStorage()) {
        localStorage.setItem("entryPath", window.location.pathname);
    }
}

/**
 * Takes available query parameters in URL and places them in `urlQueryParams`
 * object that is stored in the browser's localStorage
 */
export function setURLQueryParamsInStorage() {
    const existingParams = JSON.parse(localStorage.getItem("urlQueryParams"));
    const paramsToStore = { ...existingParams };
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.forEach((value, key) => {
        paramsToStore[key] = value;
    });
    localStorage.setItem("urlQueryParams", JSON.stringify(paramsToStore));
}

/**
 * Returns a version of the incoming URL string that does not have an origin/base
 *
 * @param {String} url
 * @returns {String}
 */
export function stripOriginFromURL(url = "") {
    try {
        if (!url) return "";
        const urlObject = new URL(url);
        return urlObject.pathname + urlObject.search + urlObject.hash;
    } catch (err) {}
}
