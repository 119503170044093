import React, { createContext, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

export const TitlesContext = createContext({});

export const TitlesProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [titles, setTitles] = useState([]);

    /**
     * End Hooks
     **/

    async function fetchTitles(contact) {
        try {
            const { json } = await SandboxxRestAPI.getTitles(contact);
            json && setTitles(json);
        } catch (error) {}
    }

    const titlesContext = { fetchTitles, titles };

    return (
        <TitlesContext.Provider value={titlesContext}>
            {children}
        </TitlesContext.Provider>
    );
};
