import { useState } from "react";

// Enums
import { NavigationEnum } from "../constants/constants";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { emptyFunction } from "utils/objectUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useSignUp = ({
    setCurrentSection,
    showNotification,
    userInfo,
}) => {
    /**
     * useState
     */

    const [connections, setConnections] = useState(null);
    const [loading, setLoading] = useState({
        base: false,
        branch: false,
        phone: false,
        rank: false,
        role: false,
        roleMilitary: false,
        signUp: false,
        shipDate: false,
        verificationCode: false,
        verify: false,
    });

    /**
     * End Hooks
     */

    function handleLoadingToggle(key, bool, callback = emptyFunction) {
        setLoading({
            ...loading,
            [key]: bool,
        });
        callback();
    }

    async function handleUpdateName() {
        try {
            const { firstName, lastName } = userInfo;
            handleLoadingToggle("name", true);
            await SandboxxRestAPI.updateUserFullName({ firstName, lastName });
            onUpdateNameSuccess();
        } catch (err) {
            onUpdateNameFailure(err);
        }
    }

    function onUpdateNameFailure(err) {
        const errorMessage = getErrorMessage(err);
        handleLoadingToggle("name", false);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onUpdateNameSuccess(res) {
        handleLoadingToggle("name", false);
        setCurrentSection(NavigationEnum.SIGN_UP_PHONE);
    }

    return {
        connections,
        handleLoadingToggle,
        handleUpdateName,
        loading,
        setConnections,
    };
};
