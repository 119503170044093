// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { areObjectsEqual } from "utils/objectUtils";

export function generateSelectDefaults(user) {
    return {
        branchId: {
            empty: !user.branchId,
            airForce: user.branchId === "AIRFORCE",
            army: user.branchId === "ARMY",
            coastGuard: user.branchId === "COASTGUARD",
            marines: user.branchId === "MARINES",
            navy: user.branchId === "NAVY",
            unknown: user.branchId === "UNKNOWN",
        },
        gender: {
            empty: !user.gender,
            female: user.gender === "Female",
            male: user.gender === "Male",
        },
        maritalStatus: {
            empty: !user.maritalStatus,
            divorced: user.maritalStatus === "Divorced",
            married: user.maritalStatus === "Married",
            separated: user.maritalStatus === "Separated",
            single: user.maritalStatus === "Single",
        },
        role: {
            empty: !user.role,
            activeDuty: user.role === "ACTIVE_DUTY",
            guard: user.role === "GUARD",
            military: user.role === "MILITARY",
            recruiter: user.role === "RECRUITER",
            supporter: user.role === "SUPPORTER",
            trainee: user.role === "TRAINEE",
            veteran: user.role === "VETERAN",
        },
    };
}

export function generateProfileUpdateDiff(userStaging, profileInfo) {
    return Object.keys(userStaging).reduce((acc, curr) => {
        if (curr === "address") {
            if (
                !areObjectsEqual(userStaging.address, profileInfo.address || {})
            ) {
                return {
                    ...acc,
                    address: userStaging.address,
                };
            }
        } else if (curr === "triggerSave") {
            return acc;
        } else if (userStaging[curr] !== profileInfo[curr]) {
            return { ...acc, [curr]: userStaging[curr] };
        }
        return acc;
    }, {});
}

export function generateUserStaging(profileInfo) {
    return {
        address: profileInfo.address || {},
        birthday: profileInfo.birthday,
        branchId: profileInfo.branchId,
        firstName: profileInfo.firstName,
        gender: profileInfo.gender,
        lastName: profileInfo.lastName,
        maritalStatus: profileInfo.maritalStatus,
        role: profileInfo.role,
    };
}

// TODO: Do these need to be awaited?
export function generateProfileUpdateMethods(userStaging) {
    return {
        address: async () => {
            await SandboxxRestAPI.updateUserAddress(userStaging);
        },
        birthday: async () => {
            await SandboxxRestAPI.updateUserBirthday(userStaging);
        },
        branchId: async () => {
            await SandboxxRestAPI.updateUserBranch({
                ...userStaging,
                branch: userStaging.branchId,
            });
        },
        firstName: async () => {
            await SandboxxRestAPI.updateUserFirstName(userStaging);
        },
        gender: async () => {
            await SandboxxRestAPI.updateUserGender(userStaging);
        },
        lastName: async () => {
            await SandboxxRestAPI.updateUserLastName(userStaging);
        },
        maritalStatus: async () => {
            await SandboxxRestAPI.updateUserMaritalStatus(userStaging);
        },
        rank: async () => {
            await SandboxxRestAPI.updateUserRank(userStaging);
        },
        role: async () => {
            await SandboxxRestAPI.updateUserRole(userStaging);
        },
    };
}
