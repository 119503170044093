import { useState } from "react";
import { cloneDeep } from "lodash";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { SandboxxV2API } from "api/SandboxxV2API";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { generateImageNameAndPath } from "utils/imageUtils";

export const usePhotoManagement = ({
    handleInitializeSaveDraft,
    hasEditedMessage,
    message,
    setDisplay,
    setHasEditedMessage,
    setIsUploadingImage,
    scrollToTop,
    setLoading,
    setMessage,
    showNotification,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    /**
     * This state is used to represent the active image being edited with the Imgly library
     */
    const [activeImage, setActiveImage] = useState({
        imageFile: null,
        imageName: null,
        imagePreviewUrl: null,
        imageUploadUrl: null,
        imageUploadSuccess: false,
    });

    /**
     * This state is all used for the LettersPhotoManageModal
     */
    const [previewImage, setPreviewImage] = useState(null);
    const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
    const [shouldShowPhotoManageModal, setShouldShowPhotoManageModal] =
        useState(false);

    /**
     * End Hooks
     */

    function handleImageCancel() {
        const messageCopy = cloneDeep(message);
        // Removed the image at the provided index
        messageCopy.customPhotoArray.splice(
            messageCopy.customPhotoArray.length - 1,
            1
        );
        setMessage((prev) => ({
            ...prev,
            customPhotoArray: [...messageCopy.customPhotoArray],
        }));
        handleImageCropModalToggle();
    }

    function handleImageDelete(index) {
        const messageCopy = cloneDeep(message);
        // Remove the image at the provided index
        messageCopy.customPhotoArray.splice(index, 1);
        setMessage((prev) => ({
            ...prev,
            customPhotoArray: [...messageCopy.customPhotoArray],
        }));
        setPreviewImage(null);
        !hasEditedMessage && setHasEditedMessage(true);
        handleInitializeSaveDraft();
    }

    function handleImageCancelPreview() {
        setMessage((prev) => ({
            ...prev,
            imageFile: null,
            imageName: null,
            imagePreviewUrl: null,
            imageUploadSuccess: false,
            imageUploadUrl: null,
        }));
    }

    function handleImageCropModalToggle() {
        setDisplay((prev) => ({
            ...prev,
            showImageCropModal: !prev,
        }));
    }

    /**
     * TODO: Everything inside of the `onloadend` callback should
     * TODO: passed to this method as a param so that it can be modular.
     * TODO: Consider defining closer to image select/crop logic.
     */
    function handleImageFileSelect(e, index) {
        e.persist();
        e.preventDefault();
        AnalyticsLogger.logLetterComposeAddPhoto(index);
        const reader = new FileReader();
        const file = e.target.files[0];
        setIsUploadingImage(true);

        reader.onloadend = () => {
            setMessage((prev) => ({
                ...prev,
                customPhotoArray: [
                    ...prev.customPhotoArray,
                    {
                        binary: reader.result,
                        imageName: file.name,
                        uuid: crypto.randomUUID(),
                    },
                ],
            }));
            setActiveImage({
                imageFile: file,
                imageName: file.name,
                imagePreviewUrl: reader.result,
            });
            setDisplay((prev) => ({
                ...prev,
                showImageCropModal: true,
            }));
            setShouldShowPhotoManageModal(false);
            scrollToTop();
        };
        reader.readAsDataURL(file);
    }

    function handleImageSubmit() {
        handleInitializeSaveDraft();
        setIsUploadingImage(false);
    }

    async function handleImageUpload(imageFile, callback) {
        try {
            const { imagePath } = generateImageNameAndPath();
            await SandboxxV2API.uploadImage({
                imageFile,
                imagePath,
            });
            onImageUploadSuccess(imagePath, callback);
        } catch (err) {
            onImageUploadFailure(err);
        }
    }

    function handleSaveCroppedImage(croppedImage) {
        const { imageFile, imagePreviewUrl } = croppedImage;
        setActiveImage((prevImage) => ({
            ...prevImage,
            imageFile,
            imagePreviewUrl,
        }));

        const customPhotoArrayCopy = cloneDeep(message.customPhotoArray);
        const lastCustomPhoto = customPhotoArrayCopy.pop();

        setMessage((prev) => ({
            ...prev,
            customPhotoArray: prev.customPhotoArray.map((image) => {
                if (image.binary === lastCustomPhoto.binary) {
                    return {
                        ...image,
                        binary: imagePreviewUrl,
                    };
                } else return image;
            }),
        }));

        setPreviewImage(imagePreviewUrl);

        handleImageCropModalToggle();
        setShouldShowPhotoManageModal(true);
        handleImageUpload(imageFile, handleImageSubmit);
    }

    function onImageUploadFailure(err) {
        const errorMessage = getErrorMessage(err);
        setMessage((prev) => ({
            ...prev,
            imageFile: null,
            imagePreviewUrl: null,
            imageUploadSuccess: false,
            imageUploadUrl: null,
        }));
        showNotification({
            text: errorMessage ?? t("image_upload_error", { ns: "letters" }),
            type: "warning",
        });
        setLoading((prevLoading) => ({
            ...prevLoading,
            isSubmittingForReview: false,
        }));
    }

    function onImageUploadSuccess(imagePath, callback) {
        const customPhotoArrayCopy = cloneDeep(message.customPhotoArray);
        const lastCustomPhoto = customPhotoArrayCopy.pop();

        setMessage((prev) => ({
            ...prev,
            customPhotoArray: prev.customPhotoArray.map((photo) => {
                if (photo.uuid === lastCustomPhoto.uuid) {
                    return {
                        ...photo,
                        url: imagePath,
                    };
                } else return photo;
            }),
        }));

        !hasEditedMessage && setHasEditedMessage(true);

        callback && callback();
    }
    return {
        activeImage,
        handleImageCancel,
        handleImageCancelPreview,
        handleSaveCroppedImage,
        handleImageFileSelect,
        handleImageDelete,
        handleImageUpload,
        previewImage,
        setPreviewImage,
        shouldShowDeleteModal,
        shouldShowPhotoManageModal,
        setShouldShowDeleteModal,
        setShouldShowPhotoManageModal,
    };
};
