import React from "react";

// Components
import CircularProgress from "@mui/material/CircularProgress";

// CSS
import styles from "./protected-page.module.scss";

// Hooks
import { useProtectedPage } from "./useProtectedPage";

export const ProtectedPage = ({ children }) => {
    /**
     * Custom Hooks
     */

    const { account, isAuthenticated, isInitializing } = useProtectedPage();

    /**
     * If user is authenticated and account data is available, show the page
     */
    if (!isInitializing && isAuthenticated && account) {
        return children;
    }

    /**
     * Otherwise, show loading state
     */
    return (
        <div className={styles.protectedPage}>
            <CircularProgress />
        </div>
    );
};
