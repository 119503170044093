import React, { useContext, useEffect, useRef } from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Enums
import { LettersComposeNavigation } from "../../scenes/Letters/scenes/Compose/enums/enums";
import { PurchaseModalNavigation } from "../Purchase/enums/enums";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { Braintree } from "../../platforms/braintree";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { emptyFunction } from "utils/objectUtils";
import { SandboxxRestAPI } from "../../utils/sandboxx";

export const AddPaymentOption = ({
    changeCurrentSection,
    classNames,
    context,
    handleCardSelect,
    handleCloseModal,
    handleGoBack,
    hideBackButton,
    loading,
    onSuccess,
    setLoading,
    text,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useRef
     */

    const dropinContainerRef = useRef(null);
    const dropinSubmitButtonRef = useRef(null);

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useEffect
     */

    useEffect(() => {
        AnalyticsLogger.logAddPaymentInfoInitiated();
    }, []);

    useEffect(() => {
        const refs = {
            container: dropinContainerRef.current,
            submitButton: dropinSubmitButtonRef.current,
        };
        if (refs.container) {
            Braintree.createDropinForm(refs, (dropinInstance) => {
                setLoading({ ...loading, braintreeNewCard: true });
                dropinInstance.requestPaymentMethod((err, card) => {
                    if (err) {
                        setLoading({ ...loading, braintreeNewCard: false });
                        return;
                    }
                    handleAddBraintreeCard(card);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropinContainerRef]);

    /**
     * Constants
     */

    const isContextCredits = context === "credits";
    const isContextWallet = context === "wallet";

    /**
     * End Hooks
     */

    async function handleAddBraintreeCard(card) {
        try {
            const { nonce } = card;
            const { json } = await SandboxxRestAPI.addBraintreeCard({ nonce });
            onAddBraintreeCardSuccess(json, card);
        } catch (err) {
            onAddBraintreeCardFailure(err);
        }
    }

    function onAddBraintreeCardFailure(err) {
        const errorMessage = getErrorMessage(err);
        AnalyticsLogger.logAddPaymentInfoFail();
        setLoading({
            ...loading,
            braintreeNewCard: false,
        });
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onAddBraintreeCardSuccess({ message: token }, card) {
        AnalyticsLogger.logAddPaymentInfoSuccess();
        const { details } = card;
        const { cardType, expirationMonth, expirationYear, lastFour } = details;
        const nextSections = {
            credits: PurchaseModalNavigation.REVIEW,
            giftCard: LettersComposeNavigation.REVIEW,
            wallet: "REVIEW",
        };
        const selectedCard = {
            brand: cardType,
            expMonth: expirationMonth,
            expYear: expirationYear,
            last4: lastFour,
            token,
        };
        handleCardSelect(selectedCard, false);
        setLoading({
            ...loading,
            braintreeNewCard: false,
        });
        if (isContextCredits || isContextWallet) {
            changeCurrentSection(nextSections[context]);
        } else {
            handleCloseModal();
            onSuccess(selectedCard);
        }
    }

    /**
     * NOTE: Submit callback is assigned by Braintree platform
     */
    function renderButtons() {
        return (
            <div
                className={cx(
                    "button__container",
                    "force-right",
                    classNames.buttons
                )}
            >
                {!hideBackButton && (
                    <ButtonInvisible
                        classes={classNames.backButton}
                        onClick={handleGoBack}
                    >
                        <Text>
                            {text.backButton || t("back", { ns: "common" })}
                        </Text>
                    </ButtonInvisible>
                )}
                <ButtonPrimary
                    className="button button--primary button-small"
                    isLoading={loading.braintreeNewCard}
                    ref={dropinSubmitButtonRef}
                >
                    <Text>
                        {text.submitButton || t("next", { ns: "common" })}
                    </Text>
                </ButtonPrimary>
            </div>
        );
    }

    function renderForm() {
        return (
            <div
                className={cx(
                    "letters-compose-purchase-payment-stripe-inner",
                    classNames.form
                )}
                data-cy="add-payment-container"
            >
                <div id="dropin-container" ref={dropinContainerRef} />
            </div>
        );
    }

    return (
        <div
            className={cx(
                "letters-compose-purchase-payment-stripe-container",
                classNames.container
            )}
        >
            {renderForm()}
            {renderButtons()}
        </div>
    );
};

AddPaymentOption.defaultProps = {
    classNames: {},
    handleCardSelect: emptyFunction,
    handleCloseModal: emptyFunction,
    onSuccess: emptyFunction,
    text: {},
};
