import { SandboxxRestAPI } from "../../utils/sandboxx";
import { emptyFunction } from "utils/objectUtils";
import { DateTime } from "luxon";

// TODO: Eventually expand this class to LetterManager and maintain entire letter
// TODO: When server can handle it, incorporate storing recipient draft information
// TODO: Store draft everywhere as object, not three individual strings
export class DraftManager {
    async createDraft(draftPayload, callback) {
        try {
            const { json } = await SandboxxRestAPI.createLetterDraft(
                draftPayload
            );
            this.draft = json;
            callback();
        } catch (err) {}
    }

    async fetchDraft(callback = emptyFunction) {
        if (!this.hasRequestedDraft) {
            this.hasRequestedDraft = true;
            try {
                const { json } = await SandboxxRestAPI.getLetterDraft();
                this.draft = json;
                callback();
            } catch (err) {}
        }
    }

    generateRecipientPayload({ id, isSandboxxUser, isSquadRecipient }) {
        if (isSandboxxUser) {
            return { recipientId: id };
        } else if (isSquadRecipient) {
            return { squadId: id };
        } else {
            return { abContactId: id };
        }
    }

    saveDraft(
        { customMessage, customPhotoArray, recipient },
        callback = emptyFunction
    ) {
        const draftId = this.draft?.id || null;
        const recipientPayload = this.generateRecipientPayload(recipient);
        const timestamp = Math.floor(DateTime.local().ts / 1000);
        const draftPayload = {
            ...recipientPayload,
            customMessage,
            customPhotoArray,
            id: draftId,
            timestamp,
        };
        draftId
            ? this.updateDraft(draftPayload, callback)
            : this.createDraft(draftPayload, callback);
    }

    async updateDraft(draftPayload, callback = emptyFunction) {
        const { customPhotoArray } = draftPayload;
        if (customPhotoArray !== null) {
            try {
                const { json } = await SandboxxRestAPI.updateLetterDraft(
                    draftPayload
                );
                callback(json);
            } catch (err) {}
        }
    }
}
