import { useContext, useEffect, useState } from "react";

// Context
import { CartContext } from "context/cart/cart";

// Enums
import { CartShippingAddressNavigation } from "../enums/CartShippingAddressNavigation";

export const useCartShippingAddress = () => {
    /**
     * useContext
     */

    const { cart, hasShippingAddress } = useContext(CartContext);

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        CartShippingAddressNavigation.LOADING
    );

    /**
     * useEffect
     */

    useEffect(() => {
        if (!cart) {
            setCurrentSection(CartShippingAddressNavigation.LOADING);
        } else if (cart && hasShippingAddress) {
            setCurrentSection(CartShippingAddressNavigation.DISPLAY);
        } else if (cart && !hasShippingAddress) {
            setCurrentSection(CartShippingAddressNavigation.EMPTY);
        }
    }, [cart, cart?.update_at, hasShippingAddress]);

    /**
     * End Hooks
     */

    return {
        currentSection,
        hasShippingAddress,
        setCurrentSection,
    };
};
