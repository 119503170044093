import React from "react";
import { Link } from "react-router-dom";

// Components
import { DashboardAlerts } from "../DashboardAlerts/DashboardAlerts";
import { DashboardLetters } from "../DashboardLetters/DashboardLetters";
import { DashboardNews } from "../DashboardNews/DashboardNews";
import { DashboardMessages } from "../DashboardMessages/DashboardMessages";
import { DashboardPromotions } from "../DashboardPromotions/DashboardPromotions";
import { DashboardSentLetters } from "../DashboardSentLetters/DashboardSentLetters";
import { DashboardStoryly } from "../DashboardStoryly/DashboardStoryly";
import { DashboardWallet } from "../DashboardWallet/DashboardWallet";
import { DashboardWeeklyUpdates } from "../DashboardWeeklyUpdates/DashboardWeeklyUpdates";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { imagePublicBase } from "../../../../utils/urlUtils";

// Constants
const imageLeftUrl = `${imagePublicBase}/web/media/dashboard/referral_left.svg`;
const imageRightUrl = `${imagePublicBase}/web/media/dashboard/referral_right.svg`;

export const DashboardMain = (props) => {
    const {
        activeDraft,
        connections,
        credits,
        graduation,
        handleGraduationModalToggle,
        handlePurchaseModalToggle,
        history,
        letterCutOffTime,
        lettersSent,
        lettersSentCursor,
        weeklyUpdates,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderReferralsPanel() {
        const connection = connections && connections[0];
        const bodyText = connection
            ? t("referrals.get_more_letters_to", {
                  firstName: connection.firstName,
                  ns: "dashboard",
              })
            : t("referrals.give_a_letter", {
                  ns: "dashboard",
              });
        const linkToParams = {
            pathname: "/letters/referrals",
            state: {
                referringRecruit: {
                    id: connection && connection.id,
                    name: connection && connection.fullName,
                },
            },
        };
        const onClick = connection
            ? AnalyticsLogger.logReferralWebRecruitShareStartDashboard
            : emptyFunction;
        return (
            <div className="dashboard__referrals panel">
                <img
                    alt={t("referrals.alt_woman_holding_letter", {
                        ns: "dashboard",
                    })}
                    className="dashboard__referrals__image dashboard__referrals__image--left"
                    src={imageLeftUrl}
                />
                <div className="dashboard__referrals__body">
                    <div className="dashboard__referrals__body__text">
                        <span>{bodyText}</span>
                    </div>
                    <Link onClick={onClick} to={linkToParams}>
                        <button className="dashboard__referrals__body__button button button--size-short button--secondary">
                            <span>{t("refer_a_friend", { ns: "common" })}</span>
                        </button>
                    </Link>
                </div>
                <img
                    alt={t("referrals.alt_man_holding_letter", {
                        ns: "dashboard",
                    })}
                    className="dashboard__referrals__image dashboard__referrals__image--right"
                    src={imageRightUrl}
                />
            </div>
        );
    }

    return (
        <div className="page__body">
            <div className="dashboard-container" data-cy="dashboard">
                <div className="dashboard-column-one">
                    <DashboardAlerts />
                    <DashboardLetters
                        activeDraft={activeDraft}
                        credits={credits}
                        graduation={graduation}
                        handleGraduationModalToggle={
                            handleGraduationModalToggle
                        }
                        handlePurchaseModalToggle={handlePurchaseModalToggle}
                        letterCutOffTime={letterCutOffTime}
                        lettersSent={lettersSent}
                    />
                    <DashboardWeeklyUpdates weeklyUpdates={weeklyUpdates} />
                    <DashboardWallet />
                    <DashboardSentLetters
                        history={history}
                        lettersSent={lettersSent}
                        lettersSentCursor={lettersSentCursor}
                    />
                </div>
                <div className="dashboard-column-two">
                    <DashboardStoryly />
                    {renderReferralsPanel()}
                    <DashboardMessages />
                    <DashboardPromotions {...props} />
                    <DashboardNews />
                </div>
            </div>
        </div>
    );
};
