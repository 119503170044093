import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Components
import BadRoute from "components/ErrorStates/index";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { Contact } from "../scenes/Contacts/Contact/Contact";
import { WrappedDashboard as Dashboard } from "../scenes/Dashboard/Dashboard";
import { Inbox } from "../scenes/Inbox/Inbox";
import { LettersCompose } from "../scenes/Letters/scenes/Compose/LettersCompose";
import { LettersComposeConfirmation } from "../scenes/Letters/scenes/Compose/LettersComposeConfirmation/LettersComposeConfirmation";
import { LettersSentLetter } from "../scenes/Letters/scenes/Sent/components/LettersSentLetter/LettersSentLetter";
import { LettersSentMenu } from "../scenes/Letters/scenes/Sent/components/LettersSentMenu/LettersSentMenu";
import { MemoryBook } from "scenes/MemoryBook/MemoryBook";
import { Muster } from "scenes/Muster/scenes/Muster";
import { OrderHistory } from "scenes/OrderHistory/OrderHistory";
import { Profile } from "../scenes/Profile/Profile";
import { ProtectedPage } from "components/ProtectedPage/ProtectedPage";
import { Referrals } from "../scenes/Referrals/Referrals";
import { SignIn } from "../scenes/SignIn/SignIn";
import { SupportSquad } from "../scenes/SupportSquad/SupportSquad";
import { Wallet } from "scenes/Wallet/Wallet";
import { WeeklyUpdates } from "../scenes/WeeklyUpdates/WeeklyUpdates";

// Enums
import { NavigationEnum as MusterNavigationEnum } from "scenes/Muster/constants/constants";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

export function Routes() {
    const mobileClasses = isMobile()
        ? "main-component-2-mobile"
        : "main-component-2";

    return (
        <Switch className={mobileClasses}>
            <Route
                exact
                path="/book"
                render={(props) => (
                    <ProtectedPage>
                        <MemoryBook {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/bundles"
                render={(props) => (
                    <ProtectedPage>
                        <Dashboard isUsingBundlesRoute={true} {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/contact/:id"
                render={(props) => (
                    <ProtectedPage>
                        <Contact {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/feed"
                render={(props) => {
                    window.location =
                        "https://www.sandboxx.us/news/?utm_source=webapp&utm_medium=navigation&utm_campaign=news&_ga=2.207752282.990096088.1627911351-1333736628.1627660927";
                    return (
                        <ProtectedPage>
                            <Dashboard {...props} />
                        </ProtectedPage>
                    );
                }}
            />
            <Route
                exact
                path="/forgot-password"
                render={(props) => <SignIn {...props} />}
            />
            <Route
                exact
                path="/inbox"
                render={(props) => (
                    <ProtectedPage>
                        <Inbox {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/leads/upload/:depsShortCode"
                render={(props) => (
                    <ProtectedPage>
                        <Dashboard {...props} hasDepsShortCode={true} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters"
                render={(props) => (
                    <ProtectedPage>
                        <Dashboard {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/bundles"
                render={(props) => (
                    <ProtectedPage>
                        <Dashboard isUsingBundlesRoute={true} {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/compose"
                render={(props) => (
                    <ProtectedPage>
                        <LettersCompose {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/confirmation"
                render={(props) => (
                    <ProtectedPage>
                        <LettersComposeConfirmation {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/feed"
                render={(props) => (
                    <ProtectedPage>
                        <Dashboard {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/referrals"
                render={(props) => (
                    <ProtectedPage>
                        <Referrals {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/referral-program"
                render={(props) => (
                    <ProtectedPage>
                        <Referrals {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/sent"
                render={(props) => (
                    <ProtectedPage>
                        <LettersSentMenu {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/letters/sent/:id"
                render={(props) => {
                    const { state } = props.location || {};
                    if (state && state.letter) {
                        const { letter, letterType, replyMailId } = state;
                        const key =
                            letterType === "replied" ? replyMailId : letter.id;
                        return (
                            <ProtectedPage>
                                <LettersSentLetter {...props} key={key} />
                            </ProtectedPage>
                        );
                    } else {
                        return <Redirect to="/letters/sent" />;
                    }
                }}
            />
            <Route
                path="/letters/*"
                render={() => <Redirect to="/letters" />}
            />
            <Route
                exact
                path="/login"
                render={(props) => <SignIn {...props} />}
            />
            <Route
                exact
                path="/order-history"
                render={(props) => (
                    <ProtectedPage>
                        <OrderHistory {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/profile"
                render={(props) => (
                    <ProtectedPage>
                        <Profile {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/profile/*"
                render={() => <Redirect to="/profile" />}
            />
            <Route
                path="/muster/articles/:id"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={MusterNavigationEnum.MUSTER_ARTICLE}
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/waypoints/articles/:id"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={MusterNavigationEnum.MUSTER_ARTICLE}
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/muster/chat"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/waypoints/chat"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/muster/checklist"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_CHECKLIST
                            }
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/waypoints/checklist"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_CHECKLIST
                            }
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/muster"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                path="/waypoints"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/recruit-invitation"
                render={(props) => (
                    <ProtectedPage>
                        <Muster
                            {...props}
                            config={{
                                shouldShowMusterRecruitInviteModal: true,
                            }}
                            initialSection={
                                MusterNavigationEnum.MUSTER_OVERVIEW
                            }
                        />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/referrals"
                render={(props) => (
                    <ProtectedPage>
                        <Referrals {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/sandboxx-gift-card"
                render={(props) => (
                    <ProtectedPage>
                        <Wallet {...props} key={crypto.randomUUID()} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/settings"
                render={(props) => (
                    <ProtectedPage>
                        <Profile {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/signin"
                render={(props) => <SignIn {...props} />}
            />
            <Route
                exact
                path="/signin/name"
                render={(props) => <SignIn goToNameEntry={true} {...props} />}
            />
            <Route
                exact
                path="/signin/onboarding"
                render={(props) => <SignIn goToOnboarding={true} {...props} />}
            />
            <Route
                exact
                path="/signup"
                render={(props) => <SignIn {...props} />}
            />
            <Route
                exact
                path="/support-squad"
                render={(props) => (
                    <ProtectedPage>
                        <SupportSquad {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/verify"
                render={(props) => <SignIn {...props} />}
            />
            <Route
                exact
                path="/weekly-updates"
                render={(props) => (
                    <ProtectedPage>
                        <WeeklyUpdates {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                exact
                path="/weekly-updates/:base/:id"
                render={(props) => <Redirect to="/weekly-updates" />}
            />
            <Route
                exact
                path="/weekly-updates/*"
                render={(props) => <Redirect to="/weekly-updates" />}
            />
            <Route
                exact
                path="/"
                render={(props) => (
                    <ProtectedPage>
                        <BaseComponent {...props} />
                    </ProtectedPage>
                )}
            />
            <Route
                render={(props) => (
                    <ProtectedPage>
                        <BadRoute {...props} />
                    </ProtectedPage>
                )}
            />
        </Switch>
    );
}
