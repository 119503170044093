import React, { createContext, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

// Context
export const DepsContext = createContext({});

export const DepsProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [depsLocations, setDepsLocations] = useState([]);

    /**
     * End Hooks
     **/

    async function fetchDepsLocations({ branchId } = {}) {
        try {
            const { json } = await SandboxxRestAPI.getDepLocations({
                branchId,
            });
            onFetchDepsLocationsSuccess(json);
        } catch (err) {}
    }

    function onFetchDepsLocationsSuccess(res) {
        setDepsLocations(res.recruitingRegions);
    }

    const depsContext = { depsLocations, fetchDepsLocations };

    return (
        <DepsContext.Provider value={depsContext}>
            {children}
        </DepsContext.Provider>
    );
};
