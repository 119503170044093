import { useContext } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { emptyFunction } from "utils/objectUtils";
import { generateSubmitShippingAddressPayload } from "../utils/apiUtils";

export const useCartShippingAddress = ({
    cart,
    setCart,
    setIsUpdatingCart,
}) => {
    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * End Hooks
     */

    function onSubmitShippingAddressFailure(err, onFailure) {
        const errorMessage = getErrorMessage(err);
        showNotification({ text: errorMessage, type: "warning" });
        setIsUpdatingCart(() => false);
        onFailure(err);
    }

    function onSubmitShippingAddressSuccess(res, onSuccess) {
        setCart(res.cart);
        setIsUpdatingCart(() => false);
        onSuccess(res);
    }

    async function submitShippingAddress({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        shippingAddress,
    } = {}) {
        try {
            setIsUpdatingCart(() => true);
            const shippingAddressPayload = generateSubmitShippingAddressPayload(
                {
                    shippingAddress,
                }
            );
            const { json } = await SandboxxRestAPI.submitShippingAddress({
                cart,
                shippingAddress: shippingAddressPayload,
            });
            onSubmitShippingAddressSuccess(json, onSuccess);
        } catch (err) {
            onSubmitShippingAddressFailure(err, onFailure);
        }
    }

    return {
        submitShippingAddress,
    };
};
