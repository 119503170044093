export const SessionManager = {
    _accessTokenFn: null,
    _isAuthenticated: false,
    _signOutFn: null,

    async getAccessToken() {
        return this._accessTokenFn ? await this._accessTokenFn() : null;
    },

    setAccessTokenFn(fn) {
        this._accessTokenFn = fn;
    },

    isAuthenticated() {
        return this._isAuthenticated;
    },

    setIsAuthenticated(value) {
        this._isAuthenticated = value;
    },

    setSignOutFn(fn) {
        this._signOutFn = fn;
    },

    signOut() {
        if (this._signOutFn) {
            this._signOutFn();
        }
    },
};
