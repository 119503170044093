import { useContext, useState } from "react";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useSignUpConfirmPhone = ({
    handleChangeVerificationCode,
    handleLoadingToggle,
    setConnections,
    setCurrentSection,
    userInfo,
    verificationCode,
}) => {
    const { phoneNumber, verificationType } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [shouldShowErrorState, setShouldShowErrorState] = useState(false);

    /**
     * End Hooks
     */

    function handleChangeConfirmVerificationCodeInput(value) {
        setShouldShowErrorState(false);
        handleChangeVerificationCode(value);
    }

    async function handleSubmit(e) {
        try {
            e && e.preventDefault();
            handleLoadingToggle("verify", true);
            const userPayload = {
                phoneNumber: phoneNumber.baseNumber,
                countryCode: phoneNumber.countryCode,
                platform: "WEB",
                verificationCode,
            };
            const { json } = await SandboxxRestAPI.checkPhoneVerificationCode(
                userPayload
            );
            onCheckPhoneVerificationCodeSuccess(json);
        } catch (err) {
            onCheckVerificationCodeFailure(err);
        }
    }

    function onCheckForOnboardingSkipSuccess(connections) {
        setConnections(connections);
    }

    function onCheckVerificationCodeFailure(err) {
        const errorMessage = getErrorMessage(err);
        setShouldShowErrorState(true);
        handleLoadingToggle("verify", false);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    async function onCheckPhoneVerificationCodeSuccess(res) {
        if (res.success) {
            // Trigger check for onboarding skip
            try {
                const { json } = await SandboxxRestAPI.checkForOnboardingSkip();
                onCheckForOnboardingSkipSuccess(json);
            } catch (err) {}

            // Analytics
            AnalyticsLogger.logVerificationConfirmPhone();

            // Update UI state
            setShouldShowErrorState(false);
            handleLoadingToggle("verify", false);
            setCurrentSection(NavigationEnum.SIGN_UP_PERSONA_ROOT);
        } else {
            // Update UI state
            handleLoadingToggle("verify", false);
            setShouldShowErrorState(true);
            showNotification({
                text: t(
                    "sign_up_confirm_phone.resend_verification_code_error",
                    {
                        ns: "onboarding",
                    }
                ),
                type: "warning",
            });
        }
    }

    function onResendVerificationCodeFailure(err) {
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onResendVerificationCodeSuccess() {
        showNotification({
            text: t("sign_up_confirm_phone.resend_verification_code_success", {
                ns: "onboarding",
                phoneNumber: phoneNumber.baseNumber,
            }),
            type: "success",
        });
    }

    async function resendVerificationCode() {
        try {
            const { json } = await SandboxxRestAPI.resendPhoneVerificationCode({
                phoneNumber,
                verificationType,
            });
            onResendVerificationCodeSuccess(json);
        } catch (err) {
            onResendVerificationCodeFailure(err);
        }
    }

    return {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    };
};
