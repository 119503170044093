import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

// Components
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-letters-credits.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardLettersCredits = ({ credits }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles.dashboardLettersCredits}>
            <div className={styles.label}>
                <MailOutlineIcon className={styles.icon} />
                <Text isBold>{t("letters_remaining", { ns: "common" })}</Text>
            </div>
            <Text classes={styles.value} isBold>
                {credits === null || credits === undefined ? (
                    <Skeleton width={40} />
                ) : (
                    credits
                )}
            </Text>
        </div>
    );
};

DashboardLettersCredits.propTypes = {
    credits: PropTypes.number,
};
