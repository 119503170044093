import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary, ButtonSecondary } from "components/Buttons/Buttons";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendIcon from "@mui/icons-material/Send";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-letters-buttons.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardLettersButtons = ({
    credits,
    handleComposeClick,
    handlePurchaseModalToggle,
    hasActiveDraft,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const shouldShowGradient = credits !== null && credits <= 2;
    const primaryButtonText = hasActiveDraft
        ? t("compose.finish_letter", { ns: "dashboard" })
        : t("start_writing", { ns: "common" });

    /**
     * End Constants & Hooks
     */

    return (
        <div className={styles.dashboardLettersButtons}>
            <ButtonPrimary
                classes={styles.button}
                cypressTestId="dashboard-letters-compose-button"
                onClick={handleComposeClick}
            >
                <SendIcon />
                <Text>{primaryButtonText}</Text>
            </ButtonPrimary>
            <ButtonSecondary
                classes={styles.button}
                cypressTestId="dashboard-letters-purchase-button"
                onClick={handlePurchaseModalToggle}
                shouldShowGradient={shouldShowGradient}
            >
                <MailOutlineIcon />
                <Text>{t("buy_letters", { ns: "common" })}</Text>
            </ButtonSecondary>
        </div>
    );
};

DashboardLettersButtons.propTypes = {
    credits: PropTypes.number,
    handleComposeClick: PropTypes.func.isRequired,
    handlePurchaseModalToggle: PropTypes.func.isRequired,
    hasActiveDraft: PropTypes.bool.isRequired,
};
