import React, { createContext, useContext, useEffect, useState } from "react";

// Context
import { AuthContext } from "context/auth/auth";

// Utils
import { checkIfIsSignInRoute } from "utils/routeUtils";

// Constants
export const NavBarContext = createContext({});

export const NavBarProvider = (props) => {
    const { children } = props;

    /**
     * useContext
     */

    const { isAuthenticated } = useContext(AuthContext);

    /**
     * useState
     */

    const [shouldShowNavBar, setShouldShowNavBar] = useState(
        isAuthenticated && !checkIfIsSignInRoute()
    );

    /**
     * useEffect
     */

    useEffect(() => {
        runShouldShowNavBarCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, shouldShowNavBar]);

    /**
     * End Hooks
     */

    function runShouldShowNavBarCheck(pathname) {
        const pathnameToCheck = pathname || window.location.pathname;
        setShouldShowNavBar(
            isAuthenticated && !checkIfIsSignInRoute(pathnameToCheck)
        );
    }

    const navBarContext = {
        runShouldShowNavBarCheck,
        setShouldShowNavBar,
        shouldShowNavBar,
    };

    return (
        <NavBarContext.Provider value={navBarContext}>
            {children}
        </NavBarContext.Provider>
    );
};
