import { useContext, useState } from "react";

// Contexts
import { AuthContext } from "context/auth/auth";
import { NotificationBarContext } from "context/notificationBar";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { getErrorMessage } from "utils/errorUtils";
import { SandboxxRestAPI } from "utils/sandboxx";

export const useProfileDeleteAccountModal = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { signOut } = useContext(AuthContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState("verify");
    const [hasConfirmedDeleteIntent, setHasConfirmedDeleteIntent] =
        useState(false);
    const [loading, setLoading] = useState({
        confirm: false,
    });

    /**
     * End Hooks & Constants
     */

    function handleConfirmationToggle(e) {
        setHasConfirmedDeleteIntent(e.target.checked);
    }

    async function handleDeleteAccount() {
        if (hasConfirmedDeleteIntent) {
            setLoading(true);
            AnalyticsLogger.logDeleteAccountConfirm();
            try {
                await SandboxxRestAPI.deleteAccount();
                onDeleteAccountSuccess();
            } catch (err) {
                onDeleteAccountFailure(err);
            }
        }
    }

    function onDeleteAccountFailure(err) {
        const errorMessage = getErrorMessage(err);
        AnalyticsLogger.logDeleteAccountError();
        setLoading((prev) => {
            return {
                ...prev,
                confirm: false,
            };
        });
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onDeleteAccountSuccess() {
        showNotification({
            text: t("delete_account.success", { ns: "profile" }),
            type: "success",
        });
        AnalyticsLogger.logDeleteAccountSuccess();
        signOut();
    }

    return {
        hasConfirmedDeleteIntent,
        currentSection,
        handleConfirmationToggle,
        handleDeleteAccount,
        loading,
        setHasConfirmedDeleteIntent,
        setCurrentSection,
    };
};
