import React, { createContext, useState } from "react";

// Utils
import { SandboxxRestAPI } from "../utils/sandboxx";

// Context
export const InterstitialContext = createContext({});

export const InterstitialProvider = (props) => {
    const { children } = props;

    /**
     * useState
     **/

    const [interstitialContent, setInterstitialContent] = useState({
        callToActionText: "",
        messages: [],
        name: "",
        screen: "",
        subtitle: "",
        title: "",
    });

    const [showInterstitial, setShowInterstitial] = useState(false);

    /**
     * End Hooks
     **/

    async function fetchInterstitialContentByName(name) {
        try {
            const { json } = await SandboxxRestAPI.getInterstitialByName({
                name,
            });
            onFetchInterstitialByNameSuccess(json);
        } catch (err) {}
    }

    async function fetchInterstitialContentByScreen(screen) {
        try {
            const { json } = await SandboxxRestAPI.getInterstitialByScreen({
                screen,
            });
            onFetchInterstitialByScreenSuccess(json);
        } catch (err) {}
    }

    function onFetchInterstitialByNameSuccess(res) {
        if (res.interstitial) {
            toggleInterstitial(true);
            setInterstitialContent(res.interstitial);
        }
    }

    function onFetchInterstitialByScreenSuccess(res) {
        if (res.interstitial) {
            toggleInterstitial(true);
            setInterstitialContent(res.interstitial);
        }
    }

    function toggleInterstitial(bool) {
        setShowInterstitial((prev) => bool || !prev);
    }

    const interstitialContext = {
        fetchInterstitialContentByName,
        fetchInterstitialContentByScreen,
        interstitialContent,
        showInterstitial,
        toggleInterstitial,
    };

    return (
        <InterstitialContext.Provider value={interstitialContext}>
            {children}
        </InterstitialContext.Provider>
    );
};
