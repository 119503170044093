import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import EditIcon from "@mui/icons-material/Edit";
import { Text } from "components/Text/Text";

// CSS
import styles from "./dashboard-letters-graduation.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardLettersGraduation = ({
    graduation,
    handleGraduationModalToggle,
    lettersSent,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const graduationFirst = graduation && graduation[0];
    const hasSentLetter = lettersSent?.length > 0;

    /**
     * End Constants & Hooks
     */

    if (!hasSentLetter || !graduation?.length) {
        return null;
    }

    return (
        <div className={styles.dashboardLettersGraduation}>
            <Text>
                {`${graduationFirst?.currentWeeksUntil} ${t(
                    "header.weeks_until_graduation",
                    {
                        firstName: graduationFirst.contactFname,
                        ns: "dashboard",
                    }
                )}`}
            </Text>
            <button
                className={cx(styles.editButton, "link")}
                data-cy="dashboard-letters-graduation-modal-toggle"
                onClick={handleGraduationModalToggle}
            >
                <EditIcon />
                <Text isBold>{t("edit", { ns: "common" })}</Text>
            </button>
        </div>
    );
};

DashboardLettersGraduation.propTypes = {
    graduation: PropTypes.array,
    handleGraduationModalToggle: PropTypes.func.isRequired,
    lettersSent: PropTypes.array,
};
