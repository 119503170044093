import { useEffect, useMemo, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { isEmptyObject } from "utils/objectUtils";

export const useWalletManageGiftCard = () => {
    /**
     * useState
     *
     * giftCard
     * -----------------------------------
     * - `amount`: Object containing the `base`, `fee`, and `total` dollar
     *      values associated with the card
     * - `braintree`: Contains Braintree information to be used when
     *      purchasing the Gift Card
     * - `hasFetchedPhotos`: Boolean that indicates whether Gift Card photos
     *      have been fetched or not
     * - `options`: Array containing Gift Card options
     * - `selected`: Object containing information about a selected Gift Card
     *      prior to purchase
     */

    const [giftCard, setGiftCard] = useState({
        amount: {
            base: 0,
            fee: 0,
            total: 0,
        },
        braintree: {},
        hasFetchedPhotos: false,
        options: [],
        selected: {},
    });

    /**
     * useMemo
     */

    const hasSelectedGiftCard = useMemo(
        () => !isEmptyObject(giftCard.selected),
        [giftCard.selected]
    );

    /**
     * useEffect
     */

    /**
     * Fetch Gift Card photos after options have been successfully fetched
     */
    useEffect(() => {
        const { hasFetchedPhotos, options } = giftCard;
        if (!!options.length && !hasFetchedPhotos) {
            fetchGiftCardPhotos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giftCard.options]);

    /**
     * End Hooks
     */

    /**
     * Resets Gift Card in state to initial values
     */
    function clearGiftCard() {
        setGiftCard((prev) => ({
            ...prev,
            amount: { base: 0, fee: 0, total: 0 },
            braintree: {},
            hasFetchedPhotos: false,
            options: [],
            selected: {},
        }));
    }

    /**
     * Fetches gift card options availabled based on provider
     */
    async function fetchWalletGiftCardOptions(provider) {
        try {
            const { json } = await SandboxxRestAPI.getWalletGiftCardOptions({
                provider,
            });
            onFetchWalletGiftCardOptionsSuccess(json);
        } catch (err) {}
    }

    function onFetchWalletGiftCardOptionsSuccess(options) {
        setGiftCard((prev) => ({ ...prev, options }));
    }

    /**
     * Fetches and updates Gift Card options in state with photo data
     */
    async function fetchGiftCardPhotos() {
        await Promise.all(
            giftCard.options.map(async (option) => {
                try {
                    const json = await SandboxxRestAPI.getImageCloudfront(
                        option.photoUrl
                    );
                    return json;
                } catch (err) {
                    return "";
                }
            })
        ).then((photoUrls) => {
            setGiftCard((prev) => ({
                ...prev,
                hasFetchedPhotos: true,
                options: prev.options.map((option, i) => ({
                    ...option,
                    photoUrlFull: photoUrls[i],
                })),
            }));
        });
    }

    function selectGiftCardAmount({ base, fee, total }) {
        setGiftCard((prev) => ({
            ...prev,
            amount: {
                base,
                fee,
                total,
            },
        }));
    }

    return {
        clearGiftCard,
        fetchWalletGiftCardOptions,
        hasSelectedGiftCard,
        giftCard,
        selectGiftCardAmount,
        setGiftCard,
    };
};
