// Interfaces
import { SessionManager } from "interfaces/auth/SessionManager.js";

// Utils
import { put } from "utils/fetchUtils";
import { DEV, DEVBASE, PRODBASE } from "utils/urlUtils";

// Constants
const v2Base = DEV ? `${DEVBASE}/v2` : `${PRODBASE}/v2`;

export class SandboxxV2API {
    static async generateHeaders() {
        try {
            const headers = new Headers();
            const accessToken = await SessionManager.getAccessToken();
            if (accessToken) {
                headers.append("Authorization", `Bearer ${accessToken}`);
            } else {
                throw new Error("User is not logged in");
            }
            headers.append("Accept-Encoding", "gzip");
            headers.append("Accept-Language", "en-US,en;q=0.9");
            headers.append("Content-Type", "application/json");
            return headers;
        } catch (err) {
            return {};
        }
    }

    static async isLoggedIn() {
        try {
            const accessToken = await SessionManager.getAccessToken();
            if (accessToken) return true;
            return false;
        } catch (error) {
            return false;
        }
    }

    /** Image Upload **/

    static async uploadImage({ imageFile, imagePath }) {
        const isLoggedIn = await SandboxxV2API.isLoggedIn();
        if (isLoggedIn) {
            const url = `${v2Base}/media/i/${imagePath}`;
            const headers = await SandboxxV2API.generateHeaders();
            headers.delete("Content-Type"); // Remove so that browser auto-generates content type with multi-file boundaries
            const body = new FormData();
            body.append(
                "file",
                new File([imageFile], imagePath, { type: "image/jpeg" })
            );
            return put(url, headers, body);
        }
        return Promise.reject("User is not logged in");
    }
}
