import React, { useEffect, useState } from "react";
import cx from "classnames";

// Utils
import { imageUrl } from "utils/urlUtils";
import { generateAvatarUrl, generateInitials } from "./utils";
import { SandboxxRestAPI } from "../../utils/sandboxx";

export const Avatar = (props) => {
    const { additionalClasses, directImageSrc, isBlue, isSquare, user } = props;
    const avatarUrl = generateAvatarUrl(user);

    /**
     * useState
     */

    const [avatarSrc, setAvatarSrc] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        async function fetchAvatar() {
            try {
                if (directImageSrc) {
                    const base = `${imageUrl()}${directImageSrc}`;
                    const avatarUrlFrag = base.substring(
                        base.indexOf("/i/") + 3
                    );
                    const json = await SandboxxRestAPI.getImageCloudfront(
                        avatarUrlFrag
                    );
                    setAvatarSrc(json);
                } else if (avatarUrl) {
                    const avatarUrlFrag = avatarUrl.substring(
                        avatarUrl.indexOf("/i/") + 3
                    );
                    const json = await SandboxxRestAPI.getImageCloudfront(
                        avatarUrlFrag
                    );
                    setAvatarSrc(json);
                }
            } catch (err) {}
        }
        fetchAvatar();
    }, [avatarUrl, directImageSrc]);

    /**
     * End Hooks
     */

    function renderAvatarWithImage() {
        return (
            <img
                alt="User avatar"
                className={cx("avatar", additionalClasses, {
                    "avatar--square": isSquare,
                })}
                src={avatarSrc}
            />
        );
    }

    function renderAvatarWithoutImage() {
        const { firstName, fullName, lastName } = user || {};
        const initials = generateInitials(firstName, fullName, lastName);
        return (
            <div
                className={cx(
                    "avatar avatar-without-image",
                    additionalClasses,
                    { "avatar--blue": isBlue },
                    { "avatar--square": isSquare }
                )}
            >
                <span className="avatar-without-image-initial">
                    {`${initials.first}${initials.last}`}
                </span>
            </div>
        );
    }

    return avatarSrc ? renderAvatarWithImage() : renderAvatarWithoutImage();
};
