import React from "react";

// Components
import { Text } from "components/Text/Text";

// CSS
import { ButtonInvisible, ButtonSecondary } from "components/Buttons/Buttons";
import styles from "../profile-delete-account-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const ProfileDeleteAccountModalVerify = (props) => {
    const { onCloseModal, setCurrentSection } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles.verify}>
            <div className={styles.body}>
                <Text>
                    {t("delete_account.verify_description", { ns: "profile" })}
                </Text>
            </div>
            <div className={styles.buttons}>
                <ButtonSecondary
                    cypressTestId="profile-delete-account-modal-verify-continue-button"
                    onClick={() => setCurrentSection("confirm")}
                    text={t("continue", { ns: "common" })}
                />
                <ButtonInvisible
                    onClick={onCloseModal}
                    text={t("cancel", { ns: "common" })}
                />
            </div>
        </div>
    );
};
