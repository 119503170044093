import { useContext, useEffect, useMemo, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { AuthContext } from "context/auth/auth";

// Utils
import { generateSubmitPaymentDetailsPayload } from "../utils/apiUtils";
import { emptyFunction } from "utils/objectUtils";

// Validation
import { Validation } from "../validation/Validation";

export const useCart = () => {
    /**
     * useContext
     **/

    const { isLoggingOut } = useContext(AuthContext);

    /**
     * useState
     **/

    const [cart, setCart] = useState(null);
    const [completedOrder, setCompletedOrder] = useState(null);
    const [isSubmittingCart, setIsSubmittingCart] = useState(false);
    const [isUpdatingCart, setIsUpdatingCart] = useState(false);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (isLoggingOut) {
            resetCartContext();
        }
    }, [isLoggingOut]);

    /**
     * useMemo
     **/

    const hasShippingAddress = useMemo(() => {
        return Validation.hasShippingAddress(cart);
    }, [cart]);

    /**
     * End Hooks
     **/

    async function applyDiscountCode({ discountCode, onFailure, onSuccess }) {
        try {
            setIsUpdatingCart(() => true);
            const res = await SandboxxRestAPI.applyDiscountCode({
                cart,
                discountCode,
            });
            onUpdateCartSuccess(res, onSuccess);
        } catch (err) {
            onUpdateCartFailure(err, onFailure);
        }
    }

    async function completeCartSession({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        setIsSubmittingCart(() => true);
        setIsUpdatingCart(() => true);
        try {
            const { json } = await SandboxxRestAPI.completeCartSession({
                cart,
            });
            onCompleteCartSessionSuccess(json, onSuccess);
        } catch (err) {
            onCompleteCartSessionFailure(err, onFailure);
        }
    }

    function onCompleteCartSessionFailure(err, onFailure) {
        setIsSubmittingCart(() => false);
        setIsUpdatingCart(() => false);
        onFailure(err);
    }

    function onCompleteCartSessionSuccess(res, onSuccess) {
        setCompletedOrder(res.cart.data);
        setIsSubmittingCart(() => false);
        setIsUpdatingCart(() => false);
        onSuccess(res);
    }

    async function getCartSession({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        setIsUpdatingCart(() => true);
        try {
            const res = await SandboxxRestAPI.getCartSession();
            onUpdateCartSuccess(res, onSuccess);
        } catch (err) {
            onUpdateCartFailure(err, onFailure);
        }
    }

    function onUpdateCartFailure(err, onFailure, response) {
        setIsUpdatingCart(() => false);
        onFailure(err, response);
    }

    function onUpdateCartSuccess(res, onSuccess) {
        if (res.json?.cart) {
            setCart(() => res.json.cart);
        }
        setIsUpdatingCart(() => false);
        onSuccess(res.json, res.response);
    }

    function resetCartContext() {
        setCart(() => null);
        setCompletedOrder(() => null);
        setIsSubmittingCart(() => false);
        setIsUpdatingCart(() => false);
    }

    async function submitPaymentDetails({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        paymentMethod,
    } = {}) {
        try {
            setIsUpdatingCart(() => true);
            const payload = generateSubmitPaymentDetailsPayload({
                paymentMethod,
            });
            const res = await SandboxxRestAPI.submitPaymentDetails({
                cart,
                ...payload,
            });
            onUpdateCartSuccess(res, onSuccess);
        } catch (err) {
            onUpdateCartFailure(err, onFailure);
        }
    }

    async function startCartSession({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        try {
            const res = await SandboxxRestAPI.startCartSession();
            onUpdateCartSuccess(res, onSuccess);
        } catch (err) {
            onUpdateCartFailure(err, onFailure);
        }
    }

    async function updateCartBookQuantity({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        quantity,
    } = {}) {
        try {
            setIsUpdatingCart(() => true);
            const res = await SandboxxRestAPI.updateCartBookQuantity({
                quantity,
            });
            onUpdateCartSuccess(res, onSuccess);
        } catch (err) {
            onUpdateCartFailure(err, onFailure);
        }
    }

    return {
        applyDiscountCode,
        cart,
        completeCartSession,
        completedOrder,
        getCartSession,
        hasShippingAddress,
        isSubmittingCart,
        isUpdatingCart,
        resetCartContext,
        setCart,
        submitPaymentDetails,
        setIsUpdatingCart,
        startCartSession,
        updateCartBookQuantity,
    };
};
