import React from "react";
import PropTypes from "prop-types";

// Components
import { Text } from "components/Text/Text";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardLettersTitle = ({ activeDraft, hasActiveDraft }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return hasActiveDraft ? (
        <Text type="h2">
            {t("compose.finish_your_letter_to", {
                firstName: activeDraft?.draftRecipient?.firstName,
                ns: "dashboard",
            })}
        </Text>
    ) : (
        <Text type="h2">
            {t("compose.write_a_letter_today", {
                ns: "dashboard",
            })}
        </Text>
    );
};

DashboardLettersTitle.propTypes = {
    activeDraft: PropTypes.shape({
        draftRecipient: PropTypes.shape({
            firstName: PropTypes.string,
        }),
    }),
    hasActiveDraft: PropTypes.bool.isRequired,
};
