// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Enums
import { SquadRole } from "scenes/SupportSquad/enums/SquadRole";

class SupportSquad {
    constructor(squadData) {
        Object.keys(squadData).forEach((key) => {
            this[key] = squadData[key];
        });
        this.isAdmin = squadData.role === SquadRole.ADMIN;
    }

    async init() {
        this.resolvedImage = await this.generateImage(this);
        return this;
    }

    async generateImage(squadData) {
        if (squadData.headerLargeUrl) {
            try {
                const img = await SandboxxRestAPI.getImageCloudfront(
                    squadData.headerLargeUrl
                );
                return img;
            } catch (error) {
                return null;
            }
        } else {
            return null;
        }
    }
}

export async function createSupportSquadInstance(squadData) {
    try {
        const squad = new SupportSquad(squadData);
        await squad.init();
        return squad;
    } catch (err) {
        return null;
    }
}
