import React from "react";
import cx from "classnames";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { ConfirmVerificationCodeInput } from "components/Inputs/ConfirmVerificationCodeInput/ConfirmVerificationCodeInput";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signUpConfirmPhoneStyles from "./sign-up-confirm-phone.module.scss";

// Hooks
import { useSignUpConfirmPhone } from "./hooks/useSignUpConfirmPhone";

// Localization
import { useTranslation } from "localization/localization";

export const SignUpConfirmPhone = ({
    handleChangeVerificationCode,
    handleLoadingToggle,
    loading,
    setConnections,
    setCurrentSection,
    userInfo,
    verificationCode,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    const {
        handleChangeConfirmVerificationCodeInput,
        handleSubmit,
        resendVerificationCode,
        shouldShowErrorState,
    } = useSignUpConfirmPhone({
        handleChangeVerificationCode,
        handleLoadingToggle,
        setConnections,
        setCurrentSection,
        userInfo,
        verificationCode,
    });

    /**
     * End Hooks
     */

    function renderForm() {
        return (
            <form
                className={cx(
                    signInStyles["form--verification-code"],
                    signUpConfirmPhoneStyles.form,
                    "form"
                )}
                data-action="authPhoneVerify"
                onSubmit={handleSubmit}
            >
                <ConfirmVerificationCodeInput
                    className={signUpConfirmPhoneStyles.input}
                    label=""
                    length={6}
                    loading={loading.verify}
                    onChange={handleChangeConfirmVerificationCodeInput}
                    shouldShowErrorState={shouldShowErrorState}
                />
                <div className={signUpConfirmPhoneStyles.buttons}>
                    <ButtonPrimary
                        classes={cx(
                            signInStyles.button,
                            signUpConfirmPhoneStyles.button
                        )}
                        cypressTestId="sign-up-confirm-verify-button"
                        dataAttributes={{ "data-action": "authPhoneVerify" }}
                        isDisabled={verificationCode.length < 6}
                        isLoading={loading.verify}
                        onClick={handleSubmit}
                        type="submit"
                    >
                        <Text>{t("continue", { ns: "common" })}</Text>
                    </ButtonPrimary>
                    <ButtonInvisible
                        classes={signUpConfirmPhoneStyles.button}
                        cypressTestId="sign-up-confirm-resend-code-button"
                        onClick={resendVerificationCode}
                    >
                        <Text>{t("resend_code", { ns: "common" })}</Text>
                    </ButtonInvisible>
                </div>
            </form>
        );
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                signUpConfirmPhoneStyles["sign-up-confirm-phone"]
            )}
        >
            <div className={signInStyles.top}>
                <div
                    className={cx(
                        signInStyles.header,
                        signUpConfirmPhoneStyles.header
                    )}
                >
                    <span
                        className={signInStyles.text}
                        data-cy="sign-up-confirm-header-text"
                    >
                        {t("sign_up_confirm_phone.title", {
                            ns: "onboarding",
                        })}
                    </span>
                    <span className={signInStyles["text-secondary"]}>
                        {t("sign_up_confirm_phone.subtitle", {
                            ns: "onboarding",
                        })}
                    </span>
                </div>
                {renderForm()}
            </div>
        </div>
    );
};
