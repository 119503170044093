import { getURLQueryParamsFromStorage } from "utils/urlUtils";

/**
 * This method checks to see if a utm_campaign value is present in localStorage, and converts it into
 * a numerical campaignId that can be included when tracking an Iterable analytics event.
 *
 * @returns {Number} if there is a campaignId present that can be converted into a valid number
 * @returns {Null} if no campaignId is available, or if the campaignId cannot be converted into a valid number
 */
export function generateIterableCampaignId() {
    const params = getURLQueryParamsFromStorage();
    localStorage.setItem("urlQueryParams", "{}");
    if (params?.utm_campaign) {
        return parseInt(params.utm_campaign) || null;
    } else {
        return null;
    }
}

/**
 * This method packages an Iterable campaignId into an object that can be referenced when tracking
 * and Iterable analytics event
 *
 * @returns {Object} containing campaignId key-value pair if available
 */
export function generateIterableCampaignIdPayload() {
    const campaignId = generateIterableCampaignId();
    return campaignId ? { campaignId } : {};
}

/**
 * This method generates a string that will be used as the primary user identifier in Iterable.
 * That string will either be comprised of (a) the user's email address or (b) a placeholder email
 * address consisting of the user's phone number if they do not have an email address.
 *
 * @param {Object} User
 * @returns {String} containing the user's Iterable identifier
 */

export function generateUserIdentifier(user = {}) {
    const { email, phoneNumber } = user;
    if (email) return email;
    if (!email && phoneNumber) return `${phoneNumber}@placeholder.email`;
    return "";
}

/**
 * Flattens all Iterable embedded messages into a single array, regardless of placementId
 *
 * @param {Object} options
 *  @param {Object} options.res Iterable API response containing embedded message data
 * @returns {Object} with keys for each placementId
 */
export function formatIterableEmbeddedMessages({ json }) {
    return json.placements.reduce((acc, { embeddedMessages, placementId }) => {
        return { ...acc, [placementId]: embeddedMessages };
    }, {});
}

/**
 * Filters through entire Iterable embedded messages response and returns the batch
 * of messages that match the desired placement id
 *
 * @param {Object} options
 *  @param {String} options.id Desired placement id
 *  @param {Object} options.res Iterable API response containing embedded message data
 * @returns {Array} containing embedded messages that match the desired placement id
 */
export function filterIterableEmbeddedMessagesByPlacementId({ id, res }) {
    return res.placements.find(({ placementId }) => placementId === id)
        ?.embeddedMessages;
}

/**
 * Formats Iterable embedded message data so that it conforms to the prop requirements
 * of the PreviewCard component
 *
 * @param {Object} options
 *  @param {Object} options.message
 * @returns {Object} containing formatted message data
 */
export function formatIterableEmbeddedMessageForPreviewCard({ message }) {
    const { elements, metadata } = message;
    return {
        id: metadata.messageId,
        imageSrc: elements?.mediaUrl,
        text: {
            body: elements?.body,
            cta: elements?.buttons && elements?.buttons[0]?.title,
            header: elements?.title,
        },
        urls: {
            card: elements?.buttons && elements?.buttons[0]?.action?.data,
            cta: elements?.buttons && elements?.buttons[0]?.action?.data,
        },
    };
}
