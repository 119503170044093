import React from "react";
import cx from "classnames";

// Components
import { ButtonSignIn } from "components/Buttons/Buttons";
import LoginIcon from "@mui/icons-material/Login";
import { Text } from "components/Text/Text";

// CSS
import signInMainStyles from "../../sign-in-main.module.scss";

// Hooks
import { useRownd } from "@rownd/react";

// Localization
import { useTranslation } from "localization/localization";

export const SignInMainButtons = () => {
    /**
     * Custom Hooks
     */

    const { requestSignIn } = useRownd();
    const { t } = useTranslation();

    /**
     * Constants
     */

    return (
        <div className={signInMainStyles.platformButtons}>
            <ButtonSignIn
                classes={cx(signInMainStyles.button)}
                cypressTestId="sign-in-main-buttons-get-started"
                icon={<LoginIcon />}
                id="sign-in-main-buttons-get-started"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    requestSignIn();
                }}
                text={t("get_started", {
                    ns: "common",
                })}
                type="button"
            >
                <Text>{t("get_started", { ns: "common" })}</Text>
            </ButtonSignIn>
        </div>
    );
};
