// Localization
import { useTranslation } from "localization/localization";

// Validation
import { Validation } from "validation/validation";

export const useSignUpName = ({
    handleUpdateName,
    showNotification,
    userInfo,
}) => {
    const { firstName, lastName } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Constants
     */

    const hasValidFirstName = Validation.isPresent(firstName);
    const hasValidLastName = Validation.isPresent(lastName);
    const hasValidForm = hasValidFirstName && hasValidLastName;

    /**
     * End Hooks & Constants
     */

    function handleSubmit(e) {
        e && e.preventDefault();
        if (hasValidForm) {
            handleUpdateName();
        } else {
            showFailedValidationNotification();
        }
    }

    function showFailedValidationNotification() {
        if (!hasValidFirstName || !hasValidLastName) {
            showNotification({
                text: t("sign_up_name.name_missing_error", {
                    ns: "onboarding",
                }),
                type: "warning",
            });
        }
    }

    return {
        handleSubmit,
        hasValidFirstName,
        hasValidForm,
        hasValidLastName,
    };
};
