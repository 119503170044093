import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Utils
import { isEmptyObject } from "utils/objectUtils";
import { SandboxxRestAPI } from "utils/sandboxx";
import {
    formatRecipientForResolution,
    generateUpdateLetterAddressPayload,
} from "../utils/utils";

// Validation
import { Validation } from "../validation/validation";

// Utils
import { getErrorMessage } from "utils/errorUtils";

export const useLettersSentLetterResolutionModal = ({
    letter,
    setResolutionModalSize,
}) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [exampleAddresses, setExampleAddresses] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recipientStaging, setRecipientStaging] = useState(
        formatRecipientForResolution(letter.recipient)
    );

    /**
     * useMemo
     */

    const originalRecipient = useMemo(
        () => formatRecipientForResolution(letter.recipient),
        [letter]
    );

    const isValidRecipient = useMemo(
        () =>
            !Validation.areRecipientsEqual({
                originalRecipient,
                recipientStaging,
            }) && !Validation.isRecipientEmpty({ recipientStaging }),
        [originalRecipient, recipientStaging]
    );

    /**
     * useEffect
     */

    useEffect(() => {
        fetchExampleAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [letter.zipcode]);

    /**
     * End Hooks
     */

    async function fetchExampleAddresses() {
        try {
            const { json } = await SandboxxRestAPI.getExampleAddresses({
                zipcode: letter.recipient.address.zipcode,
            });
            if (isEmptyObject(json)) {
                setExampleAddresses([]);
                setResolutionModalSize(null);
            }
            if (json?.example_address?.length > 0) {
                setExampleAddresses(json.example_address);
                setResolutionModalSize("xl");
            }
        } catch (error) {
            setExampleAddresses(null);
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setRecipientStaging((prevContactStaging) => ({
            ...prevContactStaging,
            [name]: value,
        }));
    }

    async function handleSubmit() {
        try {
            if (isValidRecipient) {
                setIsSubmitting(true);
                const payload = generateUpdateLetterAddressPayload({
                    recipientStaging,
                });
                const { json } = await SandboxxRestAPI.updateLetterAddress({
                    letterId: letter.id,
                    payload,
                });
                onUpdateAddressSuccess(json);
            }
        } catch (err) {
            onUpdateAddressFailure(err);
        }
    }

    function onUpdateAddressFailure(err) {
        const errorMessage = getErrorMessage(err);
        setIsSubmitting(false);
        setResolutionModalSize(null);
        showNotification({ text: errorMessage, type: "warning" });
    }

    function onUpdateAddressSuccess(res) {
        setIsSubmitting(false);
        history.push("/letters/sent");
        showNotification({
            text: "You have successfully changed the address on your Letter. We will review the change and let you know as soon as the Letter gets printed.",
            type: "success",
        });
    }

    return {
        exampleAddresses,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValidRecipient,
        recipientStaging,
    };
};
