import { useEffect, useState } from "react";

// Utils
import { SandboxxRestAPI } from "utils/sandboxx";

export const useDashboardNews = () => {
    /**
     * useState
     */

    const [isLoading, setIsLoading] = useState(false);
    const [newsItems, setNewsItems] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchNewsItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    async function fetchNewsItems() {
        try {
            setIsLoading(true);
            const { json } = await SandboxxRestAPI.getNewsItems({
                page: 1,
                pageSize: 10,
            });
            setNewsItems(json);
            setIsLoading(false);
        } catch (err) {
            setNewsItems([]);
            setIsLoading(false);
        }
    }

    return {
        isLoading,
        newsItems,
    };
};
