import { useEffect, useState } from "react";

// Enums
import { MusterChecklistModalTypes } from "enums/MusterChecklistModalTypes";

// Utils
import { generateUpdatedDocumentList } from "../utils";
import { SandboxxRestAPI } from "utils/sandboxx";
import { sortDocuments } from "../utils";

export const useMusterChecklist = (
    fetchRecruitDashboard,
    musterDocuments,
    setMusterDocuments
) => {
    /**
     * useState
     */

    const [activeDocument, setActiveDocument] = useState(null);
    const [completeDocuments, setCompleteDocuments] = useState([]);
    const [incompleteDocuments, setIncompleteDocuments] = useState([]);
    const [modalType, setModalType] = useState(MusterChecklistModalTypes.ADD);
    const [shouldShowAddEditDocumentModal, setShouldShowAddEditDocumentModal] =
        useState(false);
    const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
    const [shouldShowSuccessModal, setShouldShowSuccessModal] = useState(false);

    /**
     * useEffect
     */

    useEffect(() => {
        const { complete, incomplete } = sortDocuments(musterDocuments);
        setCompleteDocuments(complete);
        setIncompleteDocuments(incomplete);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [musterDocuments]);

    /**
     * End Hooks
     */

    async function addMusterDocument(categoryId, description, callback) {
        try {
            await SandboxxRestAPI.addMusterDocument({
                categoryId,
                description,
            });
            onAddMusterDocumentSuccess(callback);
        } catch (err) {
            onAddMusterDocumentFailure(err);
        }
    }

    async function deleteMusterDocument(documentId) {
        try {
            await SandboxxRestAPI.deleteMusterDocument({ documentId });
            onDeleteMusterDocumentSuccess();
        } catch (err) {
            onDeleteMusterDocumentFailure(err);
        }
    }

    /**
     * Update a recruits document checklist item
     * @param {string} categoryId The id for the category of documents being updated
     * @param {number} id A unique identifier provided by the backend for each document
     * @param {string} description A title for the document
     * @param {boolean} completed An indicator of whether the document is completed
     * @returns {array} Returns the updated document content
     */
    async function updateMusterDocument(
        categoryId,
        id,
        description,
        completed
    ) {
        try {
            const { json } = await SandboxxRestAPI.updateMusterDocument({
                categoryId,
                id,
                description,
                completed,
            });
            onUpdateMusterDocumentSuccess(json);
        } catch (err) {}
    }

    function onAddMusterDocumentFailure(err) {}

    function onAddMusterDocumentSuccess(callback) {
        callback();
        fetchRecruitDashboard();
        setShouldShowAddEditDocumentModal(false);
    }

    function onDeleteMusterDocumentFailure(err) {}

    function onDeleteMusterDocumentSuccess() {
        fetchRecruitDashboard();
        setShouldShowDeleteModal(false);
    }

    /**
     * Opens/Closes the Documnet Checklist modal and sets the proper state (Add/Edit)
     * @param {boolean} isOpen Is the modal open or closed
     * @param {string} modalType Determines what content should be displayed (Add or Edit)
     * @param {object} document Object containing the document data
     */
    function onToggleAddEditModal(
        isOpen,
        modalType = MusterChecklistModalTypes.ADD,
        document
    ) {
        setActiveDocument(document);
        setShouldShowAddEditDocumentModal(isOpen);
        setModalType(modalType);
    }

    function onToggleDeleteModal(isOpen, document) {
        setActiveDocument(document);
        setShouldShowDeleteModal(isOpen);
    }

    function onUpdateMusterDocumentSuccess(res) {
        fetchRecruitDashboard();
        !shouldShowAddEditDocumentModal &&
            res.completed &&
            setShouldShowSuccessModal(true);
        setShouldShowAddEditDocumentModal(false);
        updateMusterDocumentList(res);
    }

    function updateMusterDocumentList(res) {
        const updatedDocumentList = generateUpdatedDocumentList({
            musterDocuments,
            res,
        });
        setMusterDocuments(updatedDocumentList);
    }

    return {
        activeDocument,
        addMusterDocument,
        completeDocuments,
        deleteMusterDocument,
        incompleteDocuments,
        modalType,
        onToggleAddEditModal,
        onToggleDeleteModal,
        setActiveDocument,
        setShouldShowSuccessModal,
        shouldShowAddEditDocumentModal,
        shouldShowDeleteModal,
        shouldShowSuccessModal,
        updateMusterDocument,
    };
};
