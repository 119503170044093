import { useContext, useEffect } from "react";

// Context
import { CartContext } from "context/cart/cart";

// Localization
import { useTranslation } from "localization/localization";

export const useCartMain = ({ setHeaderText }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { cart, isSubmittingCart, isUpdatingCart } = useContext(CartContext);

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(t("checkout", { ns: "common" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */
    return { cart, isSubmittingCart, isUpdatingCart };
};
