import React, { createContext, useContext, useEffect, useState } from "react";

// Context
import { AuthContext } from "context/auth/auth";

// Platforms
import { SandboxxRestAPI } from "../utils/sandboxx";

export const BundlesContext = createContext({});

export const BundlesProvider = (props) => {
    const { children } = props;

    /**
     * useContext
     **/

    const { isLoggingOut } = useContext(AuthContext);

    /**
     * useState
     **/

    const [bundles, setBundles] = useState(null);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (isLoggingOut) {
            resetBundlesContext();
        }
    }, [isLoggingOut]);

    /**
     * End Hooks
     **/

    async function fetchBundles(recipient) {
        try {
            const { id, zipcode } = recipient || {};
            const { json } = await SandboxxRestAPI.getBundles({
                recipientUserId: id || "",
                zipCode: zipcode || "",
            });
            setBundles(json.bundles);
        } catch (err) {}
    }

    function resetBundlesContext() {
        setBundles(null);
    }

    const bundlesContext = { bundles, fetchBundles, resetBundlesContext };

    return (
        <BundlesContext.Provider value={bundlesContext}>
            {children}
        </BundlesContext.Provider>
    );
};
