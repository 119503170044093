import { useState } from "react";

// Hooks
import { useFetchRetry } from "hooks/fetch/useFetchRetry";

// Utils
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";

export const useAuthAccount = () => {
    /**
     * Fetch Hooks
     */

    const { fetchWithRetry } = useFetchRetry();

    /**
     * useState
     */

    const [account, setAccount] = useState(null);

    /**
     * End Hooks
     */

    async function fetchAccount() {
        try {
            const { json } = await SandboxxRestAPI.getAccount();
            CurrentUser.storeUser(json);
            setAccount(json);
            return json;
        } catch (error) {}
    }

    async function fetchAccountAfterRowndEvent() {
        return fetchWithRetry(
            async () => {
                const { json } = await SandboxxRestAPI.getAccount();
                CurrentUser.storeUser(json);
                setAccount(json);
                return json;
            },
            {
                maxRetries: 3,
                retryDelay: 500,
            }
        );
    }

    function resetAccountState() {
        CurrentUser.clearUser();
        setAccount(null);
    }

    return {
        account,
        fetchAccount,
        fetchAccountAfterRowndEvent,
        resetAccountState,
    };
};
