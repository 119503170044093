import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import Fuse from "fuse.js";
import { Skeleton } from "@material-ui/lab";

// Components
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import styles from "./sign-up-recruiting-station.module.scss";

// Enums
import { NavigationEnum } from "scenes/SignIn/constants/constants";

// Localization
import { useTranslation } from "localization/localization";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const SignUpRecruitingStation = ({
    depsLocations,
    fetchDepsLocations,
    handleGoBackToPersonaSelection,
    setCurrentSection,
    userInfo,
}) => {
    const { personas } = userInfo;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [fuses, setFuses] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedStation, setSelectedStation] = useState(null);
    const [results, setResults] = useState([]);

    /**
     * useMemo
     */

    const hasResults = useMemo(
        () => results.filter(({ locales }) => locales?.length).length !== 0,
        [results]
    );

    /**
     * useEffect
     */

    useEffect(() => {
        const branchPersona = personas[2];
        if (branchPersona) {
            fetchDepsLocations({
                branchId: branchPersona,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (depsLocations) {
            setFuses(
                depsLocations.map(
                    ({ locales }) =>
                        new Fuse(locales, {
                            keys: ["locale", "offices.office"],
                            threshold: 0.2,
                        })
                )
            );
            if (!searchTerm) {
                setResults(depsLocations);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depsLocations]);

    useEffect(() => {
        if (!searchTerm) {
            setResults(depsLocations);
        } else {
            setResults(
                depsLocations.map((location, i) => ({
                    region: location.region,
                    locales: fuses[i]
                        .search(searchTerm)
                        .map(({ item }) => item),
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    /**
     * End Hooks
     */

    async function handleSetDepLocation(dep) {
        setSelectedStation(`${dep.locale} • ${dep.office}`);
        try {
            await SandboxxRestAPI.setDepLocation({
                depsShortCode: dep.depShortCode,
            });
        } catch (err) {}
    }

    function handleSetDepLocationKeyPress(e, info, locale) {
        handleKeyDown(e, () =>
            handleSetDepLocation({
                ...info,
                locale,
            })
        );
    }

    // Send user to ship date screen -- will add as a part of another PR
    function handleSubmit() {
        setCurrentSection(NavigationEnum.SIGN_UP_SHIP_DATE);
    }

    function onSearchInputChange(e) {
        const { value } = e.target;
        setSelectedStation(null);
        setSearchTerm(value);
    }

    function renderDepLocations() {
        return (
            <div className={styles["dep-locations"]}>
                {results.length ? renderResults() : renderSkeletons()}
            </div>
        );
    }

    function renderSkeletons() {
        return (
            <>
                <Skeleton
                    variant="rect"
                    width="20%"
                    height="10px"
                    style={{ marginBottom: "24px", marginTop: "12px" }}
                />
                <Skeleton variant="rect" width="45%" height="21px" />
                <div className={styles.divider}></div>
                <Skeleton variant="rect" width="35%" height="21px" />
                <div className={styles.divider}></div>
                <Skeleton variant="rect" width="55%" height="21px" />
                <div className={styles.divider}></div>
                <Skeleton variant="rect" width="40%" height="21px" />
                <div className={styles.divider}></div>
                <Skeleton variant="rect" width="30%" height="21px" />
                <div className={styles.divider}></div>
                <Skeleton variant="rect" width="45%" height="21px" />
                <div className={styles.divider}></div>
            </>
        );
    }

    function renderResults() {
        if (hasResults) {
            return results
                ?.filter(({ locales }) => locales?.length)
                ?.map(({ locales, region }, i) => (
                    <div key={i}>
                        <Text classes={styles.region}>{region}</Text>
                        <div>
                            {locales.map(({ locale, offices }) =>
                                offices.map((info, i) => {
                                    const { office } = info;
                                    return (
                                        <div
                                            className="dep-modal__locations__location"
                                            data-cy={`dep-modal-locations-${locale}-${office}`}
                                            key={i}
                                            onClick={() =>
                                                handleSetDepLocation({
                                                    ...info,
                                                    locale,
                                                })
                                            }
                                            onKeyDown={(e) =>
                                                handleSetDepLocationKeyPress(
                                                    e,
                                                    info,
                                                    locale
                                                )
                                            }
                                            tabIndex="0"
                                        >
                                            <Text>
                                                {locale} • {office}
                                            </Text>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                ));
        } else {
            return (
                <div className={styles["no-results"]}>
                    <Text type="h4">
                        {t("no_match_found", {
                            ns: "common",
                        })}
                    </Text>
                </div>
            );
        }
    }

    return (
        <div
            className={cx(
                signInStyles.content,
                styles["sign-up-recruiting-station"]
            )}
            data-cy="sign-up-recruiting-station"
        >
            <div className={signInStyles.top}>
                <div className={signInStyles.header}>
                    <Text classes={signInStyles.text}>
                        {t("sign_up_recruiting_station.title", {
                            ns: "onboarding",
                        })}
                    </Text>
                    <Text classes={signInStyles["text-secondary"]}>
                        {t("sign_up_recruiting_station.subtitle", {
                            ns: "onboarding",
                        })}
                    </Text>
                </div>
                <div className={styles["search-field"]}>
                    <input
                        type="text"
                        onChange={onSearchInputChange}
                        placeholder={t("search_for_a_location", {
                            ns: "common",
                        })}
                        value={selectedStation || searchTerm}
                    />
                </div>
            </div>
            {renderDepLocations()}
            <div className={styles.bottom}>
                <ButtonInvisible onClick={handleGoBackToPersonaSelection}>
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
                <ButtonPrimary
                    cypressTestId="sign-up-recruiting-station-next-button"
                    isDisabled={!selectedStation}
                    onClick={handleSubmit}
                >
                    <Text>{t("next", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        </div>
    );
};
