// Components
import { CountDownTimer } from "components/CountDownTimer/CountDownTimer";

// Utils
import { findTimeDifference } from "utils/dateTimeUtils";

export function prettifyTimeUntilCutoff(cutoffTime) {
    const { hours, minutes } = findTimeDifference(cutoffTime);
    const hoursPretty = Math.floor(hours);
    const minutesPretty = Math.floor(minutes);

    if (hours < 0 || (hours === 0 && minutes < 0)) {
        return null;
    } else if (hours === 0 && minutes >= 0) {
        return <CountDownTimer endTime={cutoffTime} />;
    } else {
        return `${hoursPretty}h ${minutesPretty}m`;
    }
}
