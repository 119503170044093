import React from "react";
import PropTypes from "prop-types";

// Components
import { DashboardLettersTimeToQualify } from "./components/DashboardLettersTimeToQualify/DashboardLettersTimeToQualify";
import { DashboardLettersGraduation } from "./components/DashboardLettersGraduation/DashboardLettersGraduation";
import { DashboardLettersCredits } from "./components/DashboardLettersCredits/DashboardLettersCredits";
import { DashboardLettersTitle } from "./components/DashboardLettersTitle/DashboardLettersTitle";
import { DashboardLettersButtons } from "./components/DashboardLettersButtons/DashboardLettersButtons";
import { Panel } from "components/Panel/Panel";

// CSS
import styles from "./dashboard-letters.module.scss";

// Hooks
import { useDashboardLetters } from "./useDashboardLetters";

// Models
import { SentLetter } from "models/letters/SentLetter";

export const DashboardLetters = ({
    activeDraft,
    credits,
    graduation,
    handleGraduationModalToggle,
    handlePurchaseModalToggle,
    letterCutOffTime,
    lettersSent,
}) => {
    /**
     * Custom Hooks
     */

    const { handleComposeClick, hasActiveDraft } = useDashboardLetters({
        activeDraft,
    });

    /**
     * End Hooks
     */

    return (
        <Panel classes={styles.dashboardLetters}>
            <div className={styles.header}>
                <DashboardLettersTitle
                    activeDraft={activeDraft}
                    hasActiveDraft={hasActiveDraft}
                />
                <DashboardLettersGraduation
                    graduation={graduation}
                    handleGraduationModalToggle={handleGraduationModalToggle}
                    lettersSent={lettersSent}
                />
            </div>
            <div className={styles.stats}>
                <DashboardLettersCredits credits={credits} />
                <DashboardLettersTimeToQualify
                    letterCutOffTime={letterCutOffTime}
                />
            </div>
            <DashboardLettersButtons
                credits={credits}
                handleComposeClick={handleComposeClick}
                handlePurchaseModalToggle={handlePurchaseModalToggle}
                hasActiveDraft={hasActiveDraft}
            />
        </Panel>
    );
};

DashboardLetters.propTypes = {
    activeDraft: PropTypes.object,
    credits: PropTypes.number,
    graduation: PropTypes.array,
    handleGraduationModalToggle: PropTypes.func,
    handlePurchaseModalToggle: PropTypes.func,
    letterCutOffTime: PropTypes.object,
    lettersSent: PropTypes.arrayOf(PropTypes.instanceOf(SentLetter)),
};
