import * as Sentry from "@sentry/react";

// Enums
import { PersonaEnum } from "enums/PersonaEnum";

// Utils
import { getEntryPathFromStorage } from "./urlUtils";

export function generateLandingPath(persona = {}) {
    /**
     * Check if they should be re-directed to an approved entry route
     * (i.e., the original route the user was trying to visit before signing in)
     */
    const entryPath = getEntryPathFromStorage();
    localStorage.removeItem("entryPath");
    if (isValidEntryPath({ entryPath })) {
        return { path: entryPath };
    }

    /**
     * Otherwise, direct user to landing path based on their persona. This will default
     * to "/letters" if the user's persona does not have a specified landing path.
     */
    return generateLandingPathForPersona({ persona });
}

/**
 * Generates a URL and optional accompanying data based on a user's
 * persona type
 *
 * @param {Object} param0
 * @param {Object} param0.persona object containing user's persona data
 * @returns {Object} containing `path` and `data` properties
 */
function generateLandingPathForPersona({ persona = {} } = {}) {
    if (persona.id === PersonaEnum.RECRUIT) {
        const { musterInviteCode } =
            JSON.parse(localStorage.getItem("musterInviteCode")) || "";
        if (musterInviteCode) {
            localStorage.setItem("musterInviteCode", "{}");
            return {
                path: "/",
                data: { musterInviteCode },
            };
        } else {
            return {
                path: "/",
            };
        }
    } else if (
        persona.id === PersonaEnum.RECRUITER ||
        persona.id === PersonaEnum.RECRUITER_COMMAND
    ) {
        return {
            path: "/",
        };
    } else {
        return {
            path: "/letters",
        };
    }
}

/**
 * Checks whether the `entryRoute` in localStorage maps to a route that
 * the user should be re-directed to after signing in
 *
 * @param {Object} param0
 * @param {String} param0.route
 * @returns
 */
function isValidEntryPath({ entryPath }) {
    try {
        if (entryPath) {
            let path = entryPath;
            if (entryPath?.endsWith("/")) {
                path = entryPath.slice(0, -1);
            }
            switch (path) {
                case "/book":
                    return true;
                default:
                    return false;
            }
        }
        return false;
    } catch (error) {
        Sentry.captureException(error);
        return false;
    }
}
