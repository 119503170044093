import React, { createContext, useContext, useEffect, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { AuthContext } from "context/auth/auth";
import { NotificationBarContext } from "./notificationBar";

// Platforms
import { firebase } from "platforms/firebase";

// Utils
import { calculateTimeUntil, convertISOToObject } from "utils/dateTimeUtils";
import { getErrorMessage } from "utils/errorUtils";
import { emptyFunction } from "utils/objectUtils";
import { isOnMusterLookupShipDatePage } from "scenes/Muster/utils/utils";

// Constants
export const MusterContext = createContext({});

export const MusterProvider = (props) => {
    const { children } = props;

    /**
     * useContext
     */

    const { isLoggingOut } = useContext(AuthContext);
    const { showNotification } = useContext(NotificationBarContext);

    /**
     * useState
     */

    const [contactList, setContactList] = useState([]);
    const [currentDepLocation, setCurrentDepLocation] = useState({
        depShortCode: "",
        locale: "",
        office: "",
    });
    const [currentShipDate, setCurrentShipDate] = useState({
        day: "",
        month: "",
        year: "",
        iso: "",
    });
    const [daysUntilShip, setDaysUntilShip] = useState(null);
    const [hasContactListLoaded, setHasContactListLoaded] = useState(false);
    const [hasDashboardLoaded, setHasDashboardLoaded] = useState(false);
    const [isLoadingContactList, setIsLoadingContactList] = useState(false);
    const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
    const [isLoadingShipDate, setIsLoadingShipDate] = useState(false);
    const [magicLink, setMagicLink] = useState("");
    const [shouldShowRecruitModal, setShouldShowRecruitModal] = useState(false);
    const [musterArticles, setMusterArticles] = useState([]);
    const [musterDocuments, setMusterDocuments] = useState([]);
    const [recruits, setRecruits] = useState(null);
    const [selectedMusterArticle, setSelectedMusterArticle] = useState({});
    const [shipDates, setShipDates] = useState([]);
    const [shouldShowJoinModal, setShouldShowJoinModal] = useState(false);
    const [shouldShowJoinSuccessModal, setShouldShowJoinSuccessModal] =
        useState(false);
    const [upcomingShipDates, setUpcomingShipDates] = useState(null);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (isLoggingOut) {
            resetMusterContext();
        }
    }, [isLoggingOut]);

    /**
     * End Hooks
     */

    function resetMusterContext() {
        setContactList([]);
        setCurrentShipDate({ day: "", month: "", year: "", iso: "" });
        setDaysUntilShip(null);
        setHasContactListLoaded(false);
        setHasDashboardLoaded(false);
        setIsLoadingContactList(false);
        setIsLoadingDashboard(true);
        setMusterArticles([]);
        setRecruits([]);
        setUpcomingShipDates(null);
    }

    async function fetchMagicLink() {
        try {
            const { json } = await SandboxxRestAPI.getMagicLink();
            onFetchMagicLinkSuccess(json);
        } catch (err) {
            onFetchMagicLinkFailure(err);
        }
    }

    async function fetchRecruitDashboard() {
        try {
            const { json } = await SandboxxRestAPI.getRecruitDashboard();
            onFetchRecruitDashboardSuccess(json);
        } catch (err) {
            onFetchDashboardFailure(err);
        }
    }

    async function fetchRecruiterDashboard() {
        try {
            const { json } = await SandboxxRestAPI.getRecruiterDashboard();
            onFetchRecruiterDashboardSuccess(json);
        } catch (err) {
            onFetchDashboardFailure(err);
        }
    }

    async function fetchRecruiters() {
        try {
            const { json } = await SandboxxRestAPI.getMusterRecruiters();
            onFetchContactListSuccess(json);
        } catch (err) {
            onFetchContactListFailure(err);
        }
    }

    async function fetchRecruits() {
        try {
            const { json } = await SandboxxRestAPI.getMusterRecruits();
            onFetchContactListSuccess(json);
        } catch (err) {
            onFetchContactListFailure(err);
        }
    }

    async function redeemMusterCode(
        verificationCode,
        onJoinMusterSuccess = emptyFunction,
        onJoinMusterFailure = emptyFunction
    ) {
        try {
            const { json } = await SandboxxRestAPI.joinMusterRecruit(
                verificationCode
            );
            onJoinMusterSuccess(json);
        } catch (err) {
            onJoinMusterFailure(err);
        }
    }

    function onFetchContactListFailure(err) {
        setIsLoadingContactList(false);
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onFetchContactListSuccess(res) {
        setIsLoadingContactList(false);
        setHasContactListLoaded(true);
        if (isOnMusterLookupShipDatePage()) {
            const results = res.filter(
                (contact) =>
                    contact.daysUntilShip <= 7 &&
                    contact.daysUntilShip >= 0 &&
                    contact.daysUntilShip != null
            );
            setContactList(results);
        } else {
            setContactList(res);
        }
    }

    function onFetchDashboardFailure(err) {
        setIsLoadingDashboard(false);
        setIsLoadingShipDate(false);
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onFetchMagicLinkFailure(err) {
        const errorMessage = getErrorMessage(err);
        showNotification({
            text: errorMessage,
            type: "warning",
        });
    }

    function onFetchMagicLinkSuccess(res) {
        setMagicLink(res.musterMagicLink);
    }

    async function onFetchRecruitDashboardSuccess(res) {
        const musterArticles = await firebase.getCollection(
            res.collectionPath,
            {
                limitProp: 5,
                orderByProp: "priority",
            }
        );
        setMusterArticles(musterArticles.reverse());
        setMusterDocuments(res.documentCategories);
        setIsLoadingDashboard(false);
        setHasDashboardLoaded(true);
        setCurrentDepLocation(res.depLocation);
        if (res.shipDate) {
            const daysToShip = calculateTimeUntil(res.shipDate, ["days"]);
            const newDate = convertISOToObject(res.shipDate);
            setCurrentShipDate({ ...newDate, iso: res.shipDate });
            setDaysUntilShip(Math.ceil(daysToShip.days));
        }
    }

    async function onFetchRecruiterDashboardSuccess(res) {
        const musterArticles = await firebase.getCollection(
            res.collectionPath,
            {
                limitProp: 5,
                orderByProp: "priority",
            }
        );
        setMusterArticles(musterArticles.reverse());
        setIsLoadingDashboard(false);
        setHasDashboardLoaded(true);
        setRecruits(res.recruits);
        setShipDates(res.shipDates);
        setUpcomingShipDates(res.upcomingShipDates);
    }

    function onSetUserShipDateSuccess(res) {
        setIsLoadingShipDate(false);
        setDaysUntilShip(res.daysUntilShip);
    }

    async function updateShipDate(baseId, shipDate) {
        try {
            setIsLoadingShipDate(true);
            const { day, month, year } = shipDate;
            const formattedShipDate = `${year}-${month}-${day}`;
            const { json } = await SandboxxRestAPI.setUserShipDate({
                baseId: baseId,
                shipDate: formattedShipDate,
            });
            onSetUserShipDateSuccess(json);
        } catch (err) {
            onFetchDashboardFailure(err);
        }
    }

    const musterContext = {
        contactList,
        currentDepLocation,
        currentShipDate,
        daysUntilShip,
        fetchMagicLink,
        fetchRecruiters,
        fetchRecruits,
        fetchRecruitDashboard,
        fetchRecruiterDashboard,
        redeemMusterCode,
        hasContactListLoaded,
        hasDashboardLoaded,
        isLoadingContactList,
        isLoadingDashboard,
        isLoadingShipDate,
        magicLink,
        musterArticles,
        musterDocuments,
        selectedMusterArticle,
        recruits,
        resetMusterContext,
        setContactList,
        setSelectedMusterArticle,
        setShouldShowJoinSuccessModal,
        shouldShowJoinSuccessModal,
        setMusterDocuments,
        setShouldShowJoinModal,
        setShouldShowRecruitModal,
        shipDates,
        shouldShowJoinModal,
        shouldShowRecruitModal,
        upcomingShipDates,
        updateShipDate,
    };

    return (
        <MusterContext.Provider value={musterContext}>
            {children}
        </MusterContext.Provider>
    );
};
