import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Context
import { AuthContext } from "context/auth/auth";
import { NavBarContext } from "context/navbar";

// Interfaces
import IterableInboxManager from "interfaces/inbox/IterableInboxManager";

export const useNavBar = () => {
    /**
     * Router
     **/

    const history = useHistory();

    /**
     * useContext
     **/

    const { isLoggingOut } = useContext(AuthContext);
    const { shouldShowNavBar } = useContext(NavBarContext);

    /**
     * useState
     **/

    const [inboxManager] = useState(IterableInboxManager);
    const [shouldShowLogOutModal, setShouldShowLogOutModal] = useState(false);

    /**
     * useEffect
     **/

    useEffect(() => {
        if (!isLoggingOut) {
            setShouldShowLogOutModal(false);
        }
    }, [isLoggingOut]);

    /**
     * Constants
     **/

    const hasUnreadInboxMessages = !!inboxManager.unreadMessageCount;

    /**
     * End Hooks
     **/

    return {
        hasUnreadInboxMessages,
        history,
        setShouldShowLogOutModal,
        shouldShowLogOutModal,
        shouldShowNavBar,
    };
};
