import React from "react";

// Components
import { ButtonPrimary, ButtonInvisible } from "components/Buttons/Buttons";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Text } from "components/Text/Text";

// CSS
import styles from "../profile-delete-account-modal.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const ProfileDeleteAccountModalConfirm = ({
    handleConfirmationToggle,
    handleDeleteAccount,
    hasConfirmedDeleteIntent,
    loading,
    onCloseModal,
    profileInfo,
}) => {
    const { deleteInfo } = profileInfo || {};
    const {
        connections = 0,
        contacts = 0,
        letters = 0,
        tokens = 0,
    } = deleteInfo || {};

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <div className={styles.confirm}>
            <div className={styles.body}>
                <Text classes={styles["warning-text"]} isBold={true} type="h3">
                    {t("delete_account.confirm_title", { ns: "profile" })}
                </Text>
                <p className={styles["paragraph-info"]}>
                    {t("delete_account.confirm_subtitle", { ns: "profile" })}
                    <br></br>
                    <br></br>
                    {t("delete_account.confirm_list_description", {
                        ns: "profile",
                    })}
                </p>
                <div className={styles["deleted-items"]}>
                    <span>
                        {t("sent_letter", { count: letters, ns: "common" })}
                    </span>
                    <span>
                        {t("letter_ip", { count: tokens, ns: "common" })}
                    </span>
                    <span>
                        {t("contact", { count: contacts, ns: "common" })}
                    </span>
                    <span>
                        {t("connection", {
                            count: connections,
                            ns: "common",
                        })}
                    </span>
                </div>
                <div className={styles["checkbox-container"]}>
                    <Checkbox
                        cyId="profile-delete-account-modal-confirm-checkbox"
                        checked={hasConfirmedDeleteIntent}
                        id="confirm-checkbox"
                        onChange={handleConfirmationToggle}
                        shouldDisplayLabelInline={true}
                        text={t("delete_account.confirm_checkbox_label", {
                            ns: "profile",
                        })}
                    />
                </div>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        classes={styles.button}
                        color="warning"
                        cypressTestId="profile-delete-account-modal-confirm-delete-button"
                        isDisabled={!hasConfirmedDeleteIntent}
                        isLoading={loading.confirm}
                        onClick={handleDeleteAccount}
                        text={t("delete_account.delete_my_account", {
                            ns: "profile",
                        })}
                    />
                    <ButtonInvisible
                        classes={styles.button}
                        text={t("cancel", { ns: "common" })}
                        onClick={onCloseModal}
                    />
                </div>
            </div>
        </div>
    );
};
