import React from "react";
import cx from "classnames";

// Components
import { SignInMainButtons } from "./components/SignInMainButtons/SignInMainButtons";

// CSS
import signInStyles from "../../../sign-in.module.scss";
import signInMainStyles from "./sign-in-main.module.scss";

// Localization
import { useTranslation } from "localization/localization";

export const SignInMain = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    function renderPolicies() {
        return (
            <div className={signInMainStyles.policies}>
                <p className={signInMainStyles.policy}>
                    {t("sign_in_main.google_recaptcha_policy", {
                        ns: "onboarding",
                    })}{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://policies.google.com/privacy"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t("privacy_policy", { ns: "common" })}
                    </a>{" "}
                    and{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://policies.google.com/terms"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t("terms_of_service", { ns: "common" })}
                    </a>{" "}
                    apply.
                </p>
                <p className={signInMainStyles.policy}>
                    By continuing you agree to Sandboxx’s{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://www.sandboxx.us/terms/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {t("terms_and_conditions", { ns: "common" })}
                    </a>{" "}
                    and{" "}
                    <a
                        className={signInMainStyles.link}
                        href="https://www.sandboxx.us/privacy/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {t("privacy_policy", { ns: "common" })}
                    </a>
                </p>
            </div>
        );
    }

    return (
        <div className={cx(signInStyles.content, signInMainStyles.signInMain)}>
            <div className={signInStyles.top}>
                <div
                    className={cx(signInStyles.header, signInMainStyles.header)}
                >
                    <span
                        className={cx(signInStyles.text, signInMainStyles.text)}
                    >
                        {t("welcome_to_sandboxx", { ns: "common" })}
                    </span>
                </div>
                <SignInMainButtons />
                {renderPolicies()}
            </div>
        </div>
    );
};
